import { CATEGORIES_LIST_PATH, CONTENTS_LIST_PATH, CULTURAL_HERITAGES_LIST_PATH, MACROAREA_LIST_PATH, PLACE_LIST_PATH } from 'app/common/utils/routing-paths.utils';
import { PERMISSIONS_LIST_PATH, ROLES_LIST_PATH, USER_LIST_PATH } from 'app/pages/settings/settings.module';
import { RouteInfo } from './vertical-menu.metadata';

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
	{
		permissionId: "domainentities.read",
        path: CULTURAL_HERITAGES_LIST_PATH, title: 'menu.culturalHeritage', icon: 'fas fa-monument', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
	{
		permissionId: "domainentities.read",
        path: PLACE_LIST_PATH, title: 'menu.places', icon: 'fas fa-landmark', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
	{
		permissionId: "contents.read",
        path: '', title: 'menu.contents', icon: 'fas fa-folder-open', class: 'dropdown nav-item has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
			{
				permissionId: "contents.read",
				path: CONTENTS_LIST_PATH + "/descriptive", title: 'descrizioni', icon: 'fas fa-file-signature', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
			},
			{
				permissionId: "contents.read",
				path: CONTENTS_LIST_PATH + "/media", title: 'file', icon: 'far fa-file-image', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
			},
			{
				permissionId: "contents.read",
				path: CONTENTS_LIST_PATH + "/web", title: 'riferimenti esterni', icon: 'fas fa-link', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
			}
		]
    },
	{
        path: '', title: 'menu.categories', icon: 'ft-box', class: 'dropdown nav-item has-sub', isExternalLink: false,
		submenu: [
			{
				permissionId: "categories.read",
				path: CATEGORIES_LIST_PATH,
				title: "menu.categories.categoryList",
				icon: 'ft-arrow-right', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu:[]
			},
			{
				permissionId: "macroareas.read",
				path: MACROAREA_LIST_PATH,
				title: "menu.categories.macroareaList",
				icon: 'ft-arrow-right', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu:[]
			}
		]
    },
	{
        path: '', title: 'menu.settings', icon: 'fas fa-users-cog', class: 'dropdown nav-item has-sub', isExternalLink: false,
		submenu: [
			{
				permissionId: "permissions.read",
				path: PERMISSIONS_LIST_PATH,
				title: "menu.settings.permissions",
				icon: 'ft-arrow-right', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu:[]
			},
			{
				permissionId: "roles.read",
				path: ROLES_LIST_PATH,
				title: "menu.settings.roles",
				icon: 'ft-arrow-right', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu:[]
			},
			{
				permissionId: "users.read",
				path: USER_LIST_PATH,
				title: "menu.settings.users",
				icon: 'ft-arrow-right', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu:[]
			}
		]
    },
	{
		permissionId: "pippo", path: '', title: 'pagine di esempio', icon: 'icon-users', class: 'dropdown nav-item has-sub', isExternalLink: false,
		submenu: [
			{
				path: '/samples/form', title: 'Form', icon: 'ft-edit', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
			},
			{
				path: '/samples/data-table', title: 'Data tables', icon: 'ft-layout', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
			},
			{
				path: '/samples/list-table', title: 'List tables', icon: 'ft-grid', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
			},
			{
				path: '/samples/dialogs', title: 'Dialogs', icon: 'ft-copy', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
			}
		]
	}
    
];
