export enum LocationReferenceType {
	beacon = "beacon",
	cartesian = "cartesian",
	geo = "geo",
	qrcode = "qrcode"
}
export class LocationReference {
	public id: any;
	public type: LocationReferenceType;
}
export class BeaconReference extends LocationReference {
	public uuid: string;
	public major: number;
	public minor: number
	constructor() {
		super();
		this.type = LocationReferenceType.beacon;
	}
}
export class QRCodeReference extends LocationReference {
	public qrCode: string;
	constructor() {
		super();
		this.type = LocationReferenceType.qrcode;
	}
}
export class Geometry {
	coordinates: number[] | number[][];
}
export class GeoSpatialReference extends LocationReference {
	public centerPoint: {type: "Point", coordinates: number[]};

	public polygon: {type: "Polygon", coordinates: number[][][]};
	constructor() {
		super();
		this.type = LocationReferenceType.geo;
	}
}
export class CartesianReference extends LocationReference {
	public referenceModel: any;
	constructor() {
		super();
		this.type = LocationReferenceType.cartesian;
	}
}

export class DigitalModel {
	id: any;
	digitalModelId: any;
}

export class BaseLocalizableEntity {
	public id: any;
	public entityId: string;
}

export class BaseSpace  extends BaseLocalizableEntity {
	public digitalModel: DigitalModel;
}

export class LocalizableEntity extends BaseLocalizableEntity {
	public locationReferences: LocationReference[] = [];
}

export class Space extends LocalizableEntity {
	public containedSpaces: Space[] = [];
	public hostedEntities: LocalizableEntity[] = [];
	public digitalModel: DigitalModel;
}



export class OSSearchCriteria {
	distance?: number;
	entityId?: any;
	lat?: number;
	lng?: number;
	major?: number;
	minor?: number;
	qrCode?: number;
	x?: number;
	y?: number;
}