import { Injectable } from "@angular/core";
import { SearchResult } from "app/common/models/models";
import { HttpService } from "./http.service";
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { BaseProvider } from "app/common/services/common-provider.service";
import { CultureService } from "app/common/services/culture.service";
import { environment } from "environments/environment";
import { DomainEntity, DomainEntitySearchCriteria, LocalizedAttribute, SimpleAttribute } from "app/models/domainentities.model";
import { LinkedContentProvider } from "./LinkedContentsBaseProvider"
import { LinkedContent, LinkedContentsSet } from "app/models/contents.model";

@Injectable()
export class DomainEntityProvider extends BaseProvider implements LinkedContentProvider {
	constructor(private _httpService: HttpService, private _cultureService: CultureService) {
		super();
	}
	
	private _initEntity(src: DomainEntity): DomainEntity {
		let toReturn: DomainEntity = new DomainEntity();
		
		if (toReturn) {
			Object.assign(toReturn,src);
			toReturn.defineLanguage(this._cultureService.getCurrentCulture());
			if (src.attributes) {
				let newAttrs = [];
				src.attributes.forEach((attribute) => {
					let attr: LocalizedAttribute | SimpleAttribute;
					if (attribute.type == "localized") {
						attr = new LocalizedAttribute();
						Object.assign(attr,attribute);
						attr.defineLanguage(this._cultureService.getCurrentCulture());
					}
					else {
						attr = new SimpleAttribute();
						Object.assign(attr,attribute);
					}
					newAttrs.push(attr);
				});
				src.attributes = newAttrs;
			}
			
			return toReturn;
		}
		return null;
	}

	public search = (searchCriteria: DomainEntitySearchCriteria): Observable<SearchResult<DomainEntity>> => {
		let url = environment.services.domainEntityServiceBasePath + "?"+this.serializePagination(searchCriteria);
		
		url = url + "&entityType="+searchCriteria.entityType;
		
		
		return this._httpService.get<SearchResult<DomainEntity>>(url).pipe(
			map((result: SearchResult<DomainEntity>) => {
				let toReturn = new SearchResult<DomainEntity>();
				toReturn.totalCount = result.totalCount;
				toReturn.items = [];
				result.items.forEach((item) => {
					let bc: DomainEntity = this._initEntity(item);
					if (bc) {
						toReturn.items.push(bc);
					}
				})
				return toReturn;
			})
		);
    }

	public findAll = (test?: string): Observable<DomainEntity[]> => {
		let url = environment.services.domainEntityServiceBasePath + "/autocomplete"
		
		return this._httpService.get<DomainEntity[]>(url).pipe(
			map((result: DomainEntity[]) => {
				let toReturn: DomainEntity[] = [];
				result.forEach((item) => {
					let bc: DomainEntity = this._initEntity(item);
					if (bc) {
						toReturn.push(bc);
					}
				})
				return toReturn;
			})
		);
    }

	public getEntity = (id: any): Observable<DomainEntity> => {
		let url = environment.services.domainEntityServiceBasePath+"/"+id;
		return this._httpService.get<DomainEntity>(url).pipe(
			map((result: DomainEntity) => {
				return this._initEntity(result);
			}
		))
	}

	public saveOrUpdate = (entity: DomainEntity): Observable<DomainEntity> => {
		if (entity.id)
			return this._httpService.put<DomainEntity>(environment.services.domainEntityServiceBasePath+"/"+entity.id, entity);
		return this._httpService.post<DomainEntity>(environment.services.domainEntityServiceBasePath, entity);

	}
	public delete = (entity: DomainEntity): Observable<DomainEntity> => {
		return this._httpService.delete<DomainEntity>(environment.services.domainEntityServiceBasePath+"/"+entity.id);
	}

	public getLinkedContentsSets = (entityId): Observable<LinkedContentsSet[]> => {
		let url = environment.services.domainEntityServiceBasePath+"/"+entityId+"/linkedcontentssets";
		return this._httpService.get<LinkedContentsSet[]>(url);
	}
	public saveLinkedContentsSets = (entityId, set: LinkedContentsSet): Observable<LinkedContentsSet> => {
		let toSave: LinkedContentsSet = {
			id: set.id,
			tag: set.tag
		}
		if (set.id) return this._updateLinkedContentsSets(entityId, toSave);
		return this._createLinkedContentsSets(entityId, toSave);	
	}
	private _createLinkedContentsSets = (entityId, set: LinkedContentsSet): Observable<LinkedContentsSet> => {
		let url = environment.services.domainEntityServiceBasePath+"/"+entityId+"/linkedcontentssets";
		return this._httpService.post<LinkedContentsSet>(url, set);
	}
	private _updateLinkedContentsSets = (entityId, set: LinkedContentsSet): Observable<LinkedContentsSet> => {
		let url = environment.services.domainEntityServiceBasePath+"/"+entityId+"/linkedcontentssets/"+set.id;
		return this._httpService.put<LinkedContentsSet>(url, set);
	}
	public deleteLinkedContentsSets = (entityId, set: LinkedContentsSet): Observable<LinkedContentsSet> => {
		let url = environment.services.domainEntityServiceBasePath+"/"+entityId+"/linkedcontentssets/"+set.id;
		return this._httpService.delete<LinkedContentsSet>(url);
	}


	public getLinkedContents = (entityId, setId): Observable<LinkedContent[]> => {
		let url = environment.services.domainEntityServiceBasePath+"/"+entityId+"/linkedcontentssets/"+setId+"/linkedcontents";
		return this._httpService.get<LinkedContent[]>(url);
	}
	public saveLinkedContent = (entityId, setId, linkedContent: LinkedContent): Observable<LinkedContent> => {
		console.log("saveLinkedContent", entityId, setId, linkedContent);
		let toSave: LinkedContent = {
			content: linkedContent.content,
			id: linkedContent.id,
			properties: linkedContent.properties
		}
		if (linkedContent.id) return this._updateLinkedContent(entityId, setId, toSave);
		return this._createLinkedContent(entityId, setId, toSave);	
	}
	private _createLinkedContent = (entityId, setId, linkedContent: LinkedContent): Observable<LinkedContent> => {
		let url = environment.services.domainEntityServiceBasePath+"/"+entityId+"/linkedcontentssets/"+setId+"/linkedcontents";
		return this._httpService.post<LinkedContent>(url, linkedContent);
	}
	private _updateLinkedContent = (entityId, setId, linkedContent: LinkedContent): Observable<LinkedContent> => {
		let url = environment.services.domainEntityServiceBasePath+"/"+entityId+"/linkedcontentssets/"+setId+"/linkedcontents/"+linkedContent.id;
		return this._httpService.put<LinkedContent>(url, linkedContent);
	}
	public deleteLinkedContent = (entityId, setId, linkedContent: LinkedContent): Observable<any> => {
		let url = environment.services.domainEntityServiceBasePath+"/"+entityId+"/linkedcontentssets/"+setId+"/linkedcontents/"+linkedContent.id;
		return this._httpService.delete<any>(url);
	}
}