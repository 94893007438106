import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActionCommand } from "app/common/models/models";
import { iconEdit, iconSave, iconTrash } from "app/common/utils/icons.utils";
import { BeaconReference, LocationReferenceType, QRCodeReference } from "app/models/object-space.model";

@Component({
	selector: 'location-reference-preview',
	templateUrl: './qrcode-reference-preview.component.html',
})
export abstract class LocationReferencePreviewComponent implements OnInit {
	@Input() reference: QRCodeReference | BeaconReference;
	@Input() readOnly: boolean = true;
	@Output() onDeleteRequest: EventEmitter<QRCodeReference | BeaconReference> = new EventEmitter();
	@Output() onSaveRequest: EventEmitter<QRCodeReference | BeaconReference> = new EventEmitter();
	actions: ActionCommand[] = []
	form: FormGroup = null;

	constructor(protected _fb: FormBuilder) {

	}

	ngOnInit() {
		this.initForm();
		this.form.patchValue(this.reference);
		this.form.valueChanges.subscribe((value) => {
			this.reference = value
		});
		this.createActionsForReference();
	}

	invokeAction = ($event, action: ActionCommand): void => {
		action.funcToInvoke(this.form.value);
		$event.preventDefault();
	}

	
	createActionsForReference = () => {
		let button = new ActionCommand();
        button.funcToInvoke = (element) => {
			this.onDeleteRequest.emit(element);
		};
        button.label = "common.deleteButtonLabel";
        button.icon = iconTrash;
		// button.enablePermission="localizableentities.write";
        this.actions.push(button);
		
		button = new ActionCommand();
        button.funcToInvoke = (element) => {
			console.log("element", element);
			
			if (this.form.isValid) {
				this.onSaveRequest.emit(element);
			}
		};
        button.label = "common.updateButtonLabel";
        button.icon = iconSave;
		// button.enablePermission="localizableentities.write";		
        this.actions.push(button);

	}
	protected initForm = () => {}
}