import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'app/common/services/toasts.service';
import { iconCancel, iconMoreUsers, iconSave, iconShare, iconUserAdd } from 'app/common/utils/icons.utils';
import { SharingPermission, SharingResponse } from 'app/models/sharing.model';
import { AccessManagement } from 'app/services/access-management.service';
import { SharingService } from 'app/services/sharing.service';

export class ModalSharingContentData {
	public constructor(public contentId: any, public entityType: "contents" | "domainentities", public name: string) {}
}

@Component({
    selector: 'sharing-modal',
    templateUrl: 'sharing-modal.component.html',
    changeDetection: ChangeDetectionStrategy.Default
})

export class SharingModalComponent implements OnInit {
	iconSave = iconSave;
	iconCancel = iconCancel;
	iconShare = iconShare;
	iconUserAdd = iconUserAdd;
	iconMoreUsers = iconMoreUsers;
	sharingNumbers: number = 0;
	updateEnabled: boolean = false;
	activeId : "newPermission" | "permissionsList" = "newPermission";
    public data: ModalSharingContentData;
	private request: any;
	valid: boolean = false;
    constructor(public activeModal: NgbActiveModal, private _sharingService: SharingService, private _toastService: ToastService, private _accessManagement: AccessManagement) {

    }

    ngOnInit(): void {
		let myId = this._accessManagement.getLoggedUserId();

		this._sharingService.getSharingInfo(this.data.contentId,this.data.entityType).subscribe((r) => {
			this.sharingNumbers = r.length;
			r.forEach((info) => {
				if (info.identityServiceId == myId && (info.permissions.lastIndexOf(SharingPermission.ADMIN) >= 0 || info.owner))
				this.updateEnabled = true;
			})
			if (!this.updateEnabled)
				this.activeId = 'permissionsList';
		});
    }

	public sharingRequest = ($event): void => {
		this.request = $event;
	}
	onValidityChange = ($event): void => {
		this.valid = $event;
	}

    public close = (): void => {
		this.activeModal.close(false);
    }
    public ok = (): void => {
		if (this.valid) {
			this._sharingService.updateSharingStatus(this.data.contentId, this.data.entityType, this.request).subscribe((result: SharingResponse) => {
				if (result && result.discardedEmails && result.discardedEmails.length > 0) {
					this._toastService.showDanger("Non è stato possibile condividere con " + result.discardedEmails.join());
				}
				else {
					this._toastService.showSuccess("Condivisione effettuata");
				}
			})
		}
        this.activeModal.close(true);
    }
}