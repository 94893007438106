import { Component, forwardRef, Optional, Host, SkipSelf, Inject, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlContainer, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { getLocaleNumberSymbol, NumberSymbol } from '@angular/common';
import { EnumUtils } from 'app/common/utils/enum.utils';
import { ContentType, MediaContent, MediaContentType } from 'app/models/contents.model';
import { SelectListitem } from 'app/common/models/models';
import { CultureInfo, CultureService, CulturesKey } from 'app/common/services/culture.service';
import { FormFieldDescription } from 'app/common/models/forms';
import { CommonValidators } from '../validators/common.validator';
import { BaseFormContent } from './base-form-content';

@Component({
    selector: 'form-media-content',
    templateUrl: './form-media-content.component.html'
})

export class FormMediaContent extends BaseFormContent implements OnInit {
	mediaContentTypesDef: SelectListitem[] = [];
	possibileMediaTypes = [];
	mediaFileExtension = {};
	globalMediaId;
	constructor(private _fb: FormBuilder, private _translateService: TranslateService, private _cultureService: CultureService) {
		super();
		this.mediaFileExtension[`${MediaContentType.image}`] = ['image/png', 'image/jpeg', 'image/gif'];
		this.mediaFileExtension[`${MediaContentType.video}`] = ['video/mp4'];
		this.mediaFileExtension[`${MediaContentType.documentation}`] = ['application/pdf', 'text/plain'];
		this.mediaFileExtension[`${MediaContentType.ar}`] = [];

	}
	// getLocalizedImageValidator = (v) => {
	// 	if (this.form && this.form.value && this.form.value.useSameMediaId){
	// 		return [];
	// 	}
	// 	return Validators.required(v);
	// }
	// getCommonImageValidator = (v) => {
	// 	if (this.form && this.form.value && this.form.value.useSameMediaId){
	// 		return Validators.required(v);
	// 	}
	// 	return [];
	// }

	// public get mediaFileEnabled () {
	// 	if (this.form && this.form.value && this.form.value.useSameMediaId){
	// 		return false ;
	// 	}
	// 	return true;
	// }

	mediaContentFieldsDescriptionFile: FormFieldDescription[] = [
		{
			defaultValue: null,
			fieldName: "name",
			label: "contents.editMediaInputFieldNameLabel",
			fieldType: 'inputstring',
			validators: [Validators.required]
		},
		{
			defaultValue: null,
			fieldName: "text",
			label: "contents.editMediaInputFieldDescriptionLabel",
			fieldType: 'html',
			validators: []
		},
		{
			defaultValue: null,
			fieldName: "mediaId",
			label: "contents.editMediaInputFieldMediaIdLabel",
			fieldType: 'file',
			validators: [Validators.required],
			hidden: false
		}
	];
	mediaContentFieldsDescriptionNoFile: FormFieldDescription[] = [
		{
			defaultValue: null,
			fieldName: "name",
			label: "contents.editMediaInputFieldNameLabel",
			fieldType: 'inputstring',
			validators: [Validators.required]
		},
		{
			defaultValue: null,
			fieldName: "text",
			label: "contents.editMediaInputFieldDescriptionLabel",
			fieldType: 'html',
			validators: []
		},
		{
			defaultValue: null,
			fieldName: "mediaId",
			label: "contents.editMediaInputFieldMediaIdLabel",
			fieldType: 'file',
			validators: [],
			hidden: true
		}
	];
	private _activeMediaContentDescription;
	get mediaContentFieldsDescription() {
		return this._activeMediaContentDescription;
	}


	public init(): FormGroup {
		EnumUtils.toSelectListitems(MediaContentType, "MediaContentType", this._translateService).subscribe((result) => {
			this.mediaContentTypesDef = result;
		});
		let form: FormGroup;
		if (this.initialValue) {
			if ((<MediaContent>this.initialValue).useSameMediaId) 
				this._activeMediaContentDescription = this.mediaContentFieldsDescriptionNoFile;
			else
				this._activeMediaContentDescription = this.mediaContentFieldsDescriptionFile;
			form = this._getFormOfContent(this.initialValue);
			this.possibileMediaTypes = (this.mediaFileExtension[(<MediaContent>this.initialValue).mediaType])? this.mediaFileExtension[(<MediaContent>this.initialValue).mediaType]: [];
			form.patchValue(this.initialValue);
			form.updateValueAndValidity();
		}
		else {
			this._activeMediaContentDescription = this.mediaContentFieldsDescriptionNoFile;
			form = this._fb.group({
				id: [null],
				type: [ContentType.media],
				useSameMediaId: [true],
				mediaId: [null, Validators.required],
				published: [false],
				mediaType: [null, Validators.required],
				mediaContentLocalizations: this._fb.array([], [
					CommonValidators.localizedMinItemsValidator(1),
					CommonValidators.requiredCultures(this._cultureService.getCultureInfo([CulturesKey.IT]))
				])
			});
		}
		this._initReactions(form);
		return form;
	}

	private _initReactions(form: FormGroup) {
		if (this.initialValue)
			this.globalMediaId = this.initialValue.mediaId;

		form.get("mediaType").valueChanges.subscribe((mediaType) => {
			//console.log("Selected " + mediaType);
			this.possibileMediaTypes = (this.mediaFileExtension[mediaType])? this.mediaFileExtension[mediaType]: [];
			//console.log("this.possibileMediaTypes",this.possibileMediaTypes);
			
		})
		form.get("mediaId").valueChanges.subscribe((mediaId) => {
			//console.log("mediaId: " + mediaId);
			let value = form.value;
			if (value.useSameMediaId) {
				this.globalMediaId = mediaId;
				(<FormArray>form.get("mediaContentLocalizations")).controls.forEach(c => {
					if (c.get("mediaId").value != mediaId) {
						//console.log("setting for media content " + c.get("mediaId").value + ", " + this.globalMediaId);
						c.get("mediaId").setValue(this.globalMediaId);
					}
				})

			}
		});
		 (<FormArray>form.get("mediaContentLocalizations")).valueChanges.subscribe((mediaContentLocalizations) => {
			let value = form.value;
			//console.log("mediaContentLocalizations: ", value);
			if (value.useSameMediaId) {
				(<FormArray>form.get("mediaContentLocalizations")).controls.forEach(c => {
					if (c.get("mediaId").value != this.globalMediaId)
						c.get("mediaId").setValue(this.globalMediaId);
				})
			}
		});
		form.get("useSameMediaId").valueChanges.subscribe((useSameMediaId) => {
			//console.log("useSameMediaId " + useSameMediaId);
			if (useSameMediaId) {
				form.get("mediaId").enable({emitEvent: true})
				form.get("mediaId").setValidators([Validators.required]);
				(<FormArray>form.get("mediaContentLocalizations")).controls.forEach(c => {
					c.get("mediaId").setValue(form.value.mediaId);
				})
				this._activeMediaContentDescription = this.mediaContentFieldsDescriptionNoFile;
			}
			else {
				//console.log("Reset value.....");
				
				form.get("mediaId").disable({emitEvent: false});
				
				this._activeMediaContentDescription = this.mediaContentFieldsDescriptionFile;
			}
		})

		// form.valueChanges.subscribe((value) => {
		// 	//console.log("modifige", value);
			
		// })
	}

	private _getFormOfContent(content: MediaContent) {
		let form;
		if (content) {
			let localizedFields: any[] = [];
			let requiredCultures: CultureInfo[] = this._cultureService.getCultureInfo([CulturesKey.IT]);
		
			content.mediaContentLocalizations.forEach((name) => {
				let dataDef: any = {
					language: [null, Validators.required]
				};
				this.mediaContentFieldsDescription.forEach((field) => {
					dataDef["" + field.fieldName] = [field.defaultValue]
					field.validators.forEach((validator) => {
						dataDef["" + field.fieldName].push(validator);
					})
				});
				localizedFields.push(this._fb.group(dataDef));
			})
			
			form = this._fb.group({
				id: [null],
				type: [ContentType.media],
				useSameMediaId: [(<MediaContent>this.initialValue).useSameMediaId],
				published: [false],
				mediaId: [{value: null, disabled: !(<MediaContent>this.initialValue).useSameMediaId}, Validators.required],
				mediaType: [null, Validators.required],
				mediaContentLocalizations: this._fb.array(localizedFields, [CommonValidators.localizedMinItemsValidator(1), CommonValidators.requiredCultures(requiredCultures)])
			});
		}
		return form;
	}
}