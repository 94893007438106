import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseDataTablePage } from 'app/common/components/pages/base-data-table.page';
import { DataTableAction, DataTableColumn, IDataTableManager, RemoteDataTableManager } from 'app/common/components/table/types';
import { DataTableUtils } from 'app/common/utils/data-table.utils';
import { BaseContent, ContentsSearchCriteria, ContentType } from 'app/models/contents.model';
import { ContentsProvider } from 'app/services/ContentsProvider.service';
import { ModalService } from 'app/common/services/modal.service';
import { iconEdit, iconTrash, iconShare, iconAdd} from 'app/common/utils/icons.utils';
import { SharingService } from 'app/services/sharing.service';
import { ModalOptions, SearchResult } from 'app/common/models/models';
import { SharingPermission } from 'app/models/sharing.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EDIT_CONTENTS_PATH } from 'app/common/utils/routing-paths.utils';


@Component({
    selector: 'contents-list',
    templateUrl: './contents-list.page.html'
})
export class ContentsList extends BaseDataTablePage<BaseContent, Filters> {
    public tableColumns: DataTableColumn[] = [];
    public tableActions: DataTableAction[] = [];
	public iconAdd= iconAdd;
	public fileName = "assets/img/icons/file.png";
	public addMenuDef = [
		{
			label: "enums.ContentType.descriptive",
			value: ContentType.descriptive
		},
		{
			label: "enums.ContentType.media",
			value: ContentType.media
		},
		{
			label: "enums.ContentType.web",
			value: ContentType.web
		}
	]
	protected contentType: ContentType;

    constructor(_activatedRoute: ActivatedRoute, private _router: Router, private _contentssProvider: ContentsProvider, private _modalService: ModalService, private _sharingService: SharingService) {
        super(_activatedRoute, true);
		this.createColumms();
		this.createActions();
    }

	get title() {
		return "contents.listContentsPageTitle";
	}
	get listContentsPageCardHeaderTitle() {
		return "contents.listContentsPageCardHeaderTitle";
	}
    
	private searchContents =  (searchCriteria: ContentsSearchCriteria): Observable<SearchResult<BaseContent>> => {
		
		return this._contentssProvider.search(searchCriteria).pipe(
			map((result: SearchResult<BaseContent>) => {
				let toReturn = new SearchResult<BaseContent>();
				toReturn.totalCount = result.totalCount;
				toReturn.items = result.items.map((value) => {
					(<any>value).icon = this.fileName;
					return value;
				});
				return toReturn;
			})
		);
    }

	protected getDataTableManager = (searchCriteria: ContentsSearchCriteria): IDataTableManager<BaseContent> => {
		if (!searchCriteria)
			searchCriteria = new ContentsSearchCriteria();
		if (this.filters)
			searchCriteria.contentTypes = this.filters.contentTypes;
        return new RemoteDataTableManager(this.searchContents, this.setSearchCriteria, searchCriteria)
    }

    private createColumms = (): void => {
		let iconCoumn = DataTableUtils.createDataTableIconImageColumn("icon", "", false);
        this.tableColumns.push(iconCoumn);

        let nameColumn = DataTableUtils.createStringColumn("name", "contents.contentNameColumnLabel", false);
        this.tableColumns.push(nameColumn);
		// let typeColumn = DataTableUtils.createEnumColumn("type", "contents.contentTypeColumnLabel", "ContentType", false);
        // this.tableColumns.push(typeColumn);
		let published = DataTableUtils.createBooleanColumn("published", "contents.contentPublishedColumnLabel", true);
		this.tableColumns.push(published);
		let creationDate = DataTableUtils.createDateTimeColumn("createdDate", "data creazione", true);
		this.tableColumns.push(creationDate);
		let lastModifiedDate = DataTableUtils.createDateTimeColumn("lastModifiedDate", "ultima modifica", true);
		this.tableColumns.push(lastModifiedDate);
    }

    private createActions = (): void => {
        let button = new DataTableAction();
        button.funcToInvoke = this.delete;
        button.label = "common.deleteButtonLabel";
        button.icon = iconTrash;
		button.enablePermission="contents.write";
        this.tableActions.push(button);
		button.enableFunc = (row: BaseContent) => {
			return (row.permissions.lastIndexOf(SharingPermission.DELETE) >= 0)
		}

		button = new DataTableAction();
        button.funcToInvoke = this.edit;
        button.label = "common.updateButtonLabel";
        button.icon = iconEdit;
		button.enablePermission="contents.write";
        this.tableActions.push(button);
		button.enableFunc = (row: BaseContent) => {
			return (row.permissions.lastIndexOf(SharingPermission.WRITE) >= 0)
		}
		button = new DataTableAction();
        button.funcToInvoke = this.share;
        button.label = "common.shareManagement";
		button.enablePermission="contents.write";
        button.icon = iconShare;
		this.tableActions.push(button);
		
    }

	public share = (row: BaseContent): void => {
        console.log("share content...");
		let options = new ModalOptions();
		options.size = "xl";
		options.callback = (data) => {
			console.log("Ricevuto il dato..... " , data);
			
		}
		this._modalService.showSharingRequest(row.id,"contents", row.name, options);
    }

    public edit = (row: BaseContent): void => {
        const queryParams = {};
        queryParams['returnUrl'] = this.getReturnUrl();
        console.log(queryParams['returnUrl']);
        this._router.navigate([EDIT_CONTENTS_PATH, row.id], { queryParams: queryParams });
    }
	public delete = (row: BaseContent): void => {
		console.log("ciao");
		
		let callback = (data) => {
			if (data) {
				this._contentssProvider.deleteContent(row).subscribe((result) => {
					this.dataTableManager.startSearch();
				})
			}
		}
		this._modalService.showConfirm("common.dialogs.alertRemoveItemTitle", "common.dialogs.alertRemoveItemMessage", {
			callback: callback,
			size: "md"
		});
    }

	public addEntity = (mediaType: ContentType) => {
		const queryParams = {};
        queryParams['contentType'] = mediaType;
        queryParams['returnUrl'] = this.getReturnUrl();
        this._router.navigate([EDIT_CONTENTS_PATH], { queryParams: queryParams });

	}

    protected setSearchCriteria = (criteria: ContentsSearchCriteria): void => {
		console.log("setSearchCriteria: ", criteria);
		
    }

    public search = (): void => {
        this.dataTableManager.startSearch();
    }
}


export class Filters {
    public published: string = "";
	public contentTypes: string = "";
}