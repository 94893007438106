import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ActionCommand } from "app/common/models/models";
import { iconEdit } from "app/common/utils/icons.utils";
import { ContentType, DescriptiveContent, MediaContent, WebContent } from "app/models/contents.model";
import { DomainEntity } from "app/models/domainentities.model";
import { DomainEntityProvider } from "app/services/DomainEntityProvider.service";
import { FileProvider } from 'app/services/FileProvider.service';
import { ObjectSpaceManagementProvider } from "app/services/object-space-management.service";
import { LinkedContetsTagTypeForDomainEntity } from "app/models/contents.model";
import { ContentsProvider } from "app/services/ContentsProvider.service";
import { BaseLocalizableEntity } from "app/models/object-space.model";

@Component({
    selector: 'domain-entity-preview',
    templateUrl: './domain-entity-preview.component.html',
	styles: [`
		.my-content-card {
			display: flex;
			align-items: flex-start;
			flex-direction: row;
			justify-content: flex-start;
			align-content: flex-start;
		}
		.heigth-20 {
			height: 20% !important;
		}
		.heigth-80 {
			height: 80% !important;
		}
  `]
})

export class DomainEntityPreview implements OnInit{
	@Input() entityId;
	@Input() localizableEntity: BaseLocalizableEntity;
	@Input() showLogo: boolean = true;
	@Input() public actions: ActionCommand[] = []
	
	domainEntity: DomainEntity;
	imagePreview = "assets/img/icons/wait-cloud.gif"
	loading: boolean = true;
	constructor(private _domainEntityProvider: DomainEntityProvider, private _contentProvider: ContentsProvider, private _fileProvider: FileProvider) {
	}

	public ngOnInit(){
		this.loading = true;
		if (this.showLogo) {
			this._domainEntityProvider.getLinkedContentsSets(this.entityId).subscribe((contentsSet)=> {
				if (contentsSet) {
					contentsSet.forEach((set) => {
						if (set.tag == LinkedContetsTagTypeForDomainEntity.LOGO) {
							this._domainEntityProvider.getLinkedContents(this.entityId,set.id).subscribe((logo) => {
								if (logo && logo.length>0) {
									this._contentProvider.getContent(logo[0].content.id).subscribe((contentValue: any) => {
										if (contentValue) {
											if (contentValue.type == ContentType.media) {
												let media: MediaContent = (<MediaContent>contentValue);
												let mediaId;
												if (media.useSameMediaId) {
													mediaId = media.mediaId
												}
												else {
													mediaId = media.mediaContentLocalizations[0].mediaId;
												}
								
												if (mediaId) {
													this._fileProvider.getFileInfo(mediaId).subscribe(result => {
														this.imagePreview = result.imageUrl;
													});
												}
											}
											else if (contentValue.type == ContentType.web) {
												this.imagePreview = "assets/img/icons/link.jpg"
											}
											else {
												this.imagePreview = "assets/img/icons/descriptive-content.png"
											}
										}
										this.loading = false;
									}, (err) => {this.loading = false;})
								}
								
							})
		
						}
					})
				}
			})
		}
		
		
		this._domainEntityProvider.getEntity(this.entityId).subscribe((domainEntity) => {
			this.domainEntity = domainEntity;			
		})
		
	}

	invokeAction = ($event, action: ActionCommand, item): void => {
		let p: any = {
			entityId: this.entityId,
			localizableEntity: this.localizableEntity
		}
		p.content= item;
		if (action.enableFunc(item))
			action.funcToInvoke(p)
		$event.preventDefault();
	}
}