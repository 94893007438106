import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { HttpService } from "./http.service";

export class MediaFileDescription {
	id;
	name: string;
	type: string;
	mediaUrl: any;
	get imageUrl() {
		if (this.type) {
			if (this.type.includes("image"))
				return this.mediaUrl;
			if (this.type.includes("video"))
				return "assets/img/icons/video.jpg";
			if (this.type.includes("json"))
				return "assets/img/icons/ar.png";
			if (this.type.includes("xml"))
				return "assets/img/icons/ar.png";
		}
		return "assets/img/icons/file.png"
	}
}

@Injectable()
export class FileProvider {
	constructor(private _httpService: HttpService) {
	}

	public getFileInfo = (id: any): Observable<MediaFileDescription> => {
		let url = environment.services.mediaContentsBasePath+"/"+id;
		return new Observable((observer) => {
			this._httpService.get<MediaFileDescription>(url, "arraybuffer",  'response').subscribe((response: any) => {
				let fileResult = new MediaFileDescription();
				fileResult.id = id;
				let contentDisposition = "" + response.headers.get("Content-Disposition");
				try {
					fileResult.name = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim().split("\"")[1];					
				} catch (error) {
					fileResult.name = "file-"+id;
				}
				fileResult.type = response.headers.get("Content-Type");

				var blob = new Blob([response.body], {
					type: fileResult.type
				})
				var reader = new FileReader();
				reader.onload = (event) => {
					fileResult.mediaUrl =   event.target.result;
					observer.next(fileResult);
				};
				reader.readAsDataURL(blob);
			})
		})
	}
}