<div class="full-height full-width" *ngIf="reference">
	<div class="heigth-20">
		<div class="row">
			<div class="col">
				<div class="card-title truncated-text">
					
				</div>
			</div>
			<div class="col text-right">
				<button
					*ngFor="let action of actions"
					[enabled-by-permission]="action.enablePermission"
					(click)="invokeAction($event, action)"
					[disabled]="!action.enableFunc(reference)"
					class="btn-clear {{ (!action.enableFunc(reference))?'element-disabled':'text-primary'}} cursor-pointer">
					<i class="{{action.icon}}" placement="top"></i>
				</button>
			</div>
		</div>
	</div>
	<div class="heigth-80 my-content-card ">
		<div class="half-width full-height">
			<qrcode [qrdata]="reference.qrCode" [errorCorrectionLevel]="'M'"  [width]="150"></qrcode>
		</div>
		<div class="row">
			<div class="col-12">
				<form [formGroup]="form" *ngIf="form">
					<div class="row">
						<div class="col-12">
							<input-string formControlName="qrCode" label="Codice testuale" [rows]="3">
							</input-string>
						</div>
					</div>
					
				</form>
			</div>
		</div>
	</div>
</div>
