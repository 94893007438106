import { SearchCriteria } from "app/common/models/models";
import { CultureInfo } from "app/common/services/culture.service";
import { BaseLocalizedEntity, LocalizedDescription } from "../common/models/localization";

export class Permission extends BaseLocalizedEntity {
	constructor() {
		super();
		this.mappingLocalizedField.push({fieldSrc:"localizedDescriptions", fieldDest: "description", localizedFieldName: "text"});

	}
	id: any;
	deleted: boolean = false;
	get available(): boolean {return !this.deleted;}
	name: string = "";
	localizedDescriptions: LocalizedDescription[] = [];
	description: string;
}

export class Role extends BaseLocalizedEntity {
	constructor() {
		super();
		this.mappingLocalizedField.push({fieldSrc:"localizedDescriptions", fieldDest: "description", localizedFieldName: "text"});

	}
	id: any;
	name: string = "";
	localizedDescriptions: LocalizedDescription[] = [];
	permissions: Permission[] = [];
}

export class GrantedRoles {
	id: any;
	legalEntityId: any;
	roles: Role[] = [];
}

export class PermissionSearchCriteria extends SearchCriteria {
	text: string = null;
	deleted: boolean = false;
}

export class RoleSearchCriteria extends SearchCriteria {
	text: string = null;
}