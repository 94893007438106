<base-page [title]="'Tables' | translate" [description]="'Componenti Tabelle' | translate">
    <ng-container page-body>
        <div class="card">
            <div class="card-header">
                Paginated Tables
            </div>
            <div class="card-content">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <data-table [columns]="tableColumns" [manager]="dataTableManager" [actions]="tableActions">
                            </data-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</base-page>