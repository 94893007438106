<base-page [title]="'userMngt.permissions.listPageTitle' | translate">
    <ng-container page-body>
        <div class="card">
			<div class="card-header">
				<div class="row">
					<div class="col-6">
						<span class="badge badge-success">{{numberOfAvailableItems}}</span> {{'userMngt.permissions.listPageCardHeaderTitle' | translate}}
					</div>
					<div class="col-6 text-right">
						<button type="button" (click)="addEntity()" class="btn btn-primary" [enabled-by-permission]="'permissions.write'"><i class="{{iconAdd}}"></i> {{'userMngt.permissions.addNewButtonLabel' | translate}}</button>
					</div>
				</div>
            </div>
            <div class="card-content">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <data-table [columns]="tableColumns" [manager]="dataTableManager" [actions]="tableActions">
                            </data-table>
                        </div>
                    </div>
                </div>
				
            </div>
        </div>
    </ng-container>
</base-page>