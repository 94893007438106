import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbDatepickerModule } from "@ng-bootstrap/ng-bootstrap";
import { DecimalDirective } from "./decimal.directive";
import { DisableFormControlDirective } from "./disable-form-control.directive";
import { GoBackDirective } from "./go-back.directive";
import { IntDirective } from "./int.directive";
import { NumberDirective } from "./number.directive";
import { SecureClickDirective } from "./secure-click.directive";
import { HasUserPermission } from "./userInPermission.directive";

@NgModule({
    declarations: [
        DecimalDirective,
        DisableFormControlDirective,
        GoBackDirective,
        IntDirective,
        SecureClickDirective,
		HasUserPermission
    ],
    imports:
        [
            FormsModule,
            ReactiveFormsModule,
        ],
    providers: [
    ],
    exports: [
        DecimalDirective,
        DisableFormControlDirective,
        GoBackDirective,
        IntDirective,
        SecureClickDirective,
		HasUserPermission
    ]
})
export class DirectiveModule { }
