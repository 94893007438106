import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseDataTablePage } from 'app/common/components/pages/base-data-table.page';
import { DataTableAction, DataTableColumn, IDataTableManager, RemoteDataTableManager } from 'app/common/components/table/types';
import { DataTableUtils } from 'app/common/utils/data-table.utils';
import { iconEdit, iconTrash } from 'app/common/utils/icons.utils';
import { CategoriesSearchCriteria, Category } from 'app/models/categories.model';
import { CategoriesProvider } from 'app/services/CategoriesProvider.service';
import { iconAdd } from "app/common/utils/icons.utils"
import { ModalService } from 'app/common/services/modal.service';
import { Observable } from 'rxjs';
import { SearchResult } from 'app/common/models/models';
import { map } from 'rxjs/operators';
import { EDIT_CATEGORY_PATH } from 'app/common/utils/routing-paths.utils';



@Component({
    selector: 'categories-list',
    templateUrl: './categories-list.page.html'
})
export class CategoriesList extends BaseDataTablePage<Category, Filters> {
    public tableColumns: DataTableColumn[] = [];
    public tableActions: DataTableAction[] = [];
    public filters: Filters = new Filters();
	public iconAdd= iconAdd;
    constructor(_translateService: TranslateService, _activatedRoute: ActivatedRoute, private _router: Router, private _categoriesProvider: CategoriesProvider, private _modalService: ModalService) {
        super(_activatedRoute, true);
        this.createColumms();
        this.createActions();
    }
    protected getDataTableManager = (searchCriteria: CategoriesSearchCriteria): IDataTableManager<Category> => {
        return new RemoteDataTableManager(this._categoriesProvider.searchCategories, this.setSearchCriteria, searchCriteria)
    }

    private createColumms = (): void => {
        let idColumn = DataTableUtils.createIntColumn("id", "ID", true);
        this.tableColumns.push(idColumn);

        let nameColumn = DataTableUtils.createStringColumn("name", "categories.categoryNameColumnLabel", true);
        this.tableColumns.push(nameColumn);

		let categoriesColumn = DataTableUtils.createArrayColumn("entityTypes", "categories.linkedEntityTypesColumnLabel", null, false);
		this.tableColumns.push(categoriesColumn);
    }

    private createActions = (): void => {
        let button = new DataTableAction();
        button.funcToInvoke = this.delete;
        button.label = "common.deleteButtonLabel";
		button.enablePermission = "categories.write"
        button.icon = iconTrash;
        this.tableActions.push(button)

		button = new DataTableAction();
        button.funcToInvoke = this.edit;
        button.label = "common.updateButtonLabel";
		button.enablePermission = "categories.write"
        button.icon = iconEdit;
        this.tableActions.push(button)
    }

    public edit = (row: Category): void => {
        const queryParams = {};
        queryParams['returnUrl'] = this.getReturnUrl();
        console.log(queryParams['returnUrl']);
        this._router.navigate([EDIT_CATEGORY_PATH, row.id], { queryParams: queryParams });
    }
	public delete = (row: Category): void => {
		let callback = (data) => {
			if (data) {
				this._categoriesProvider.deleteCategory(row).subscribe((result) => {
					this.dataTableManager.startSearch();
				})
			}
		}
		this._modalService.showConfirm("common.dialogs.alertRemoveItemTitle", "common.dialogs.alertRemoveItemMessage", {
			callback: callback,
			size: "md"
		});
    }

	public addEntity = () => {
		const queryParams = {};
        queryParams['returnUrl'] = this.getReturnUrl();
        console.log(queryParams['returnUrl']);
        this._router.navigate([EDIT_CATEGORY_PATH], { queryParams: queryParams });

	}

    protected setSearchCriteria = (criteria: CategoriesSearchCriteria): void => {
		if (criteria.field == "name")
        	criteria.field = "localizedNames.text";
    }

    public search = (): void => {
        this.dataTableManager.startSearch();
    }
}


class Filters {
    public name: string = null;
}