import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseDataTablePage } from 'app/common/components/pages/base-data-table.page';
import { DataTableAction, DataTableColumn, IDataTableManager, RemoteDataTableManager } from 'app/common/components/table/types';
import { DataTableUtils } from 'app/common/utils/data-table.utils';
import { iconEdit, iconTrash } from 'app/common/utils/icons.utils';
import { iconAdd } from "app/common/utils/icons.utils"
import { ModalService } from 'app/common/services/modal.service';
import { EDIT_USER_ROLES_PATH } from "./settings.module"
import { RolesAndPermissionsProvider } from 'app/services/RolesAndPermissionsProvider.service';
import { UsersProvider } from 'app/services/UserProvider.service';
import { UserSearchCriteria, UserView } from 'app/models/user.model';

@Component({
	selector: 'users-list',
	templateUrl: './users-list.page.html'
})
export class UserList extends BaseDataTablePage<UserView, Filters> {
	public tableColumns: DataTableColumn[] = [];
	public tableActions: DataTableAction[] = [];
	public filters: Filters = new Filters();
	public iconAdd = iconAdd;
	constructor(_translateService: TranslateService, _activatedRoute: ActivatedRoute, private _router: Router, private _entityProvider: UsersProvider, private _modalService: ModalService, private _rolesProvider: RolesAndPermissionsProvider) {
		super(_activatedRoute, true);
		this.createColumms();
		this.createActions();
	}

	protected getDataTableManager = (searchCriteria: UserSearchCriteria): IDataTableManager<UserView> => {
		return new RemoteDataTableManager(this._entityProvider.searchUsers, this.setSearchCriteria, searchCriteria)
	}

	private createColumms = (): void => {
		let avatar = DataTableUtils.createUserAvatarColumn("email","Utente", "givenName", "surname", true);
		this.tableColumns.push(avatar);

		this.tableColumns.push(DataTableUtils.createArrayColumn("roles", "Ruoli", null,false));
	}

	private createActions = (): void => {
		let button = new DataTableAction();
		button.funcToInvoke = (r:UserView) => {};
		button.label = "common.deleteButtonLabel";
		button.enablePermission = "users.write";
		button.icon = iconTrash;
		button.enableFunc = (r:UserView) => {return false};
		this.tableActions.push(button)

		button = new DataTableAction();
		button.funcToInvoke = this.edit;
		button.label = "common.updateButtonLabel";
		button.enablePermission = "users.write";
		button.icon = iconEdit;
		this.tableActions.push(button)
	}

	public edit = (row: UserView): void => {
		const queryParams = {};
		queryParams['returnUrl'] = this.getReturnUrl();
		console.log(queryParams['returnUrl']);
		this._router.navigate([EDIT_USER_ROLES_PATH, row.id], { queryParams: queryParams });
	}


	protected setSearchCriteria = (criteria: UserSearchCriteria): void => {
	}

	public search = (): void => {
		this.dataTableManager.startSearch();
	}
}


class Filters {
	public givenName: string = "";
	public surname: string = "";
	public email: string = "";
}