import { Component, Inject, OnInit, Optional, SkipSelf } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SearchCriteria } from 'app/common/models/models';
import { BaseComponent } from '../base.component';
import { IDataTableManager } from '../table/types';

@Component({
    template: '',
    providers: [

    ]
})

export abstract class BaseDataTablePage<TData, TFilter = any> extends BaseComponent implements OnInit {
    public dataTableManager: IDataTableManager<any> = null;
    public filters: TFilter = null;
    constructor(protected activatedRoute: ActivatedRoute, @Inject(true) protected autoSearch: boolean) {
        super();
    }
    ngOnInit(): void {
        this.on(this.activatedRoute.queryParams.subscribe(queryParams => {
            if (this.dataTableManager) {
                this.dataTableManager.destroy();
            }
            //Leggo i parametri di rcerca dalla return Url
            let searchCriteria = <SearchCriteria>(queryParams["searchCriteria"] ? JSON.parse(queryParams["searchCriteria"]) : null);
            this.dataTableManager = this.getDataTableManager(searchCriteria);
			
            //ora setto i parametri sui filtri
            if (searchCriteria && this.filters) {
                for (var prop in this.filters) {
                    this.filters[prop] = (<any>searchCriteria)[prop];
                }
            }
            if (this.autoSearch) {
                this.dataTableManager.startReload();
            }
        }));
        this.initialize();
    }

	public get numberOfAvailableItems(): number {
		if (this.dataTableManager) return this.dataTableManager.numberOfAvailableItems();
	}

    protected initialize = (): void => {

    }
    protected getDataTableManager = (searchCriteria: SearchCriteria): IDataTableManager<TData> => {
        return null;
    }

    protected getReturnUrl = (baseUrl: string = null): string => {
        const ret = baseUrl || window.location.pathname;
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.delete("searchCriteria");
        urlParams.append("searchCriteria", JSON.stringify(this.dataTableManager.searchCriteria));
        return ret + '?' + urlParams.toString();
    }

    protected setSearchCriteria = (searchCriteria: SearchCriteria): void => {
        if (this.filters) {
            for (var prop in this.filters) {
                (<any>searchCriteria)[prop] = this.filters[prop];
            }
        }
    }
}