import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ActionCommand } from "app/common/models/models";
import { iconEdit } from "app/common/utils/icons.utils";
import { ContentType, DescriptiveContent, MediaContent, WebContent } from "app/models/contents.model";
import { ContentsProvider } from "app/services/ContentsProvider.service";
import { FileProvider } from 'app/services/FileProvider.service';


@Component({
    selector: 'content-preview',
    templateUrl: './form-content-preview.component.html',
	styleUrls: ['./form-content-preview.component.scss']
})

export class FormContentPreview implements OnInit{
	@Input() contentView;
	@Input() contentId;
	@Input() public actions: ActionCommand[] = []
	
	contentValue: MediaContent | DescriptiveContent | WebContent;
	imagePreview;
	loading: boolean = true;
	constructor(private _router: Router, private _fileProvider: FileProvider, private _contentProvider: ContentsProvider) {
	}

	public ngOnInit(){
		this.loading = true;
		this._contentProvider.getContent(this.contentId).subscribe((contentValue: any) => {
			this.contentValue = contentValue;
			if (this.contentValue) {
				if (this.contentValue.type == ContentType.media) {
					let media: MediaContent = (<MediaContent>this.contentValue);
					let mediaId;
					if (media.useSameMediaId) {
						mediaId = media.mediaId
					}
					else {
						mediaId = media.mediaContentLocalizations[0].mediaId;
					}
	
					if (mediaId) {
						this._fileProvider.getFileInfo(mediaId).subscribe(result => {
							this.imagePreview = result.imageUrl;
						});
					}
				}
				else if (this.contentValue.type == ContentType.web) {
					this.imagePreview = "assets/img/icons/link.jpg"
				}
				else {
					this.imagePreview = "assets/img/icons/descriptive-content.png"
				}
			}
			this.loading = false;
		}, (err) => {this.loading = false;})
	}

	invokeAction = ($event, action: ActionCommand, item): void => {
		let p: any = new Object();
		Object.assign(p, this.contentView);
		p.content= item;
		if (action.enableFunc(item))
			action.funcToInvoke(p)
		$event.preventDefault();
	}
}