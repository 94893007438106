import { Injectable } from "@angular/core";
import { SearchResult } from "app/common/models/models";
import { BaseProvider } from "app/common/services/common-provider.service";
import { CultureService } from "app/common/services/culture.service";
import { CategoriesSearchCriteria, MacroareaSearchCriteria, Category, Macroarea, MacroareaView } from "app/models/categories.model";
import { DomainEntityType } from "app/models/domainentities.model";
import { environment } from "environments/environment";
import { Observable} from "rxjs";
import { map } from "rxjs/operators";
import { HttpService } from "./http.service";

@Injectable()
export class CategoriesProvider extends BaseProvider{

	constructor(private _httpService: HttpService, private _cultureService: CultureService) {
		super();
	}

	/**
	 * Categories methods
	 */

	private _initCategory(src: Category): Category {
		let toReturn =  new Category();
		Object.assign(toReturn,src);
		toReturn.defineLanguage(this._cultureService.getCurrentCulture());
		return toReturn;
	}

	public getCategory = (id: any): Observable<Category> => {
		let url = environment.services.categoryServiceBasePath+"/"+id;
		return this._httpService.get<Category>(url).pipe(
			map((result: Category) => {
				return this._initCategory(result);
			}
		))
	}

	public searchCategories = (searchCriteria: CategoriesSearchCriteria): Observable<SearchResult<Category>> => {
		let url = environment.services.categoryServiceBasePath+"?"+this.serializePagination(searchCriteria);
		return this._httpService.get<SearchResult<Category>>(url).pipe(
			map((result: SearchResult<Category>) => {
				let toReturn = new SearchResult<Category>();
				toReturn.totalCount = result.totalCount;
				toReturn.items = [];
				result.items.forEach((item) => {
					toReturn.items.push(this._initCategory(item));
				})
				return toReturn;
			})
		);
	}

    public getAllCategoriesAutocomplete = (text?: string, entityTypes?: DomainEntityType[]): Observable<Category[]> => {
		let url = environment.services.categoryServiceBasePath+"/autocomplete" + ((text)? "?text="+text: "");
		if (entityTypes && entityTypes.length > 0) {
			if (!text) url = url + "?";
			entityTypes.forEach(e => {
				url = url + "entityType="+e;
			})
		}
		return this._httpService.get<Category[]>(url).pipe(
			map((result: Category[]) => {
				let toReturn: Category[] = [];
				result.forEach((item) => {
					toReturn.push(this._initCategory(item));
				})
				return toReturn;
			})
		);
    }


	public saveOrUpdateCategory = (category: Category): Observable<Category> => {
		if (category.id)
			return this._httpService.put<Category>(environment.services.categoryServiceBasePath+"/"+category.id, category);
		return this._httpService.post<Category>(environment.services.categoryServiceBasePath, category);

	}
	public deleteCategory = (category: Category): Observable<Category> => {
		return this._httpService.delete<Category>(environment.services.categoryServiceBasePath+"/"+category.id);
	}

	/**
	 * Macroarea methods
	 */

	private _initMacroarea = (src: Macroarea): Macroarea => {
		let toReturn =  new Macroarea();
		let categories = [];
		src.categories.forEach((category) => {
			categories.push(this._initCategory(category));
		})
		src.categories = categories;
		Object.assign(toReturn,src);
		toReturn.defineLanguage(this._cultureService.getCurrentCulture());
		return toReturn;
	}
	private _initMacroareaView = (src: MacroareaView): MacroareaView => {
		let toReturn =  new MacroareaView();
		Object.assign(toReturn,src);
		toReturn.defineLanguage(this._cultureService.getCurrentCulture());
		return toReturn;
	}
	

	public getMacroarea = (id: any): Observable<Macroarea> => {
		let url = environment.services.macroareaServiceBasePath+"/"+id;
		return this._httpService.get<Macroarea>(url).pipe(
			map((result: Macroarea) => {
				return this._initMacroarea(result);
			}
		))
	}

	public searchMacroarea = (searchCriteria: MacroareaSearchCriteria): Observable<SearchResult<MacroareaView>> => {
		let url = environment.services.macroareaServiceBasePath+"?"+this.serializePagination(searchCriteria);
		
		if (searchCriteria.text)
			url += "&text="+searchCriteria.text;
		if (searchCriteria.categoryIds && searchCriteria.categoryIds.length>0)
			url += "&categoryIds="+searchCriteria.categoryIds.join();
		return this._httpService.get<SearchResult<MacroareaView>>(url).pipe(
			map((result: SearchResult<MacroareaView>) => {
				let toReturn = new SearchResult<MacroareaView>();
				toReturn.totalCount = result.totalCount;
				toReturn.items = [];
				result.items.forEach((item) => {
					toReturn.items.push(this._initMacroareaView(item));
				})
				return toReturn;
			})
		);
	}


	public saveOrUpdateMacroarea = (macroarea: Macroarea | MacroareaView): Observable<Macroarea | MacroareaView> => {
		if (macroarea.id)
			return this._httpService.put<Macroarea>(environment.services.macroareaServiceBasePath+"/"+macroarea.id, macroarea);
		return this._httpService.post<Macroarea>(environment.services.macroareaServiceBasePath, macroarea);

	}
	public deleteMacroarea = (macroarea: Macroarea | MacroareaView): Observable<Macroarea | MacroareaView> => {
		return this._httpService.delete<Macroarea>(environment.services.macroareaServiceBasePath+"/"+macroarea.id);
	}
}