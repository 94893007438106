

export enum SharingPermission {
	READ = "READ",
	WRITE = "WRITE",
	DELETE = "DELETE",
	ADMIN = "ADMIN"
}

export class SharingResponse {
	confirmedEmails: string[] = [];
	discardedEmails: string[] = [];
}
export class SharingInfo {
	identityServiceId?:	string;
	email: string;
	givenName?: string;
	surname?: string;
	permissions: SharingPermission[] = [];
	owner?: boolean;
}
