import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AuthGuard } from "app/apex/shared/auth/auth-guard.service";
import { ComponentsModule } from "app/common/components/components.module";
import { DirectiveModule } from "app/common/directives/directives.module";
import { CATEGORIES_LIST_PATH, EDIT_CATEGORY_PATH, EDIT_MACROAREA_PATH, MACROAREA_LIST_PATH } from "app/common/utils/routing-paths.utils";
import { CategoriesList } from "./categories-list.page";
import { EditCategory } from "./edit-category/edit-category.page";
import { EditMacroarea } from "./edit-macroarea/edit-macroarea.page";
import { MacroareaList } from "./macroarea-list.page";

export const CategoriesRoutes: Routes = [
	{ path: CATEGORIES_LIST_PATH, component: CategoriesList, canActivate: [AuthGuard] },
	{ path: EDIT_CATEGORY_PATH, component: EditCategory, canActivate: [AuthGuard] },
	{ path: EDIT_CATEGORY_PATH+"/:id", component: EditCategory, canActivate: [AuthGuard] },
	{ path: MACROAREA_LIST_PATH, component: MacroareaList, canActivate: [AuthGuard] },
	{ path: EDIT_MACROAREA_PATH, component: EditMacroarea, canActivate: [AuthGuard] },
	{ path: EDIT_MACROAREA_PATH+"/:id", component: EditMacroarea, canActivate: [AuthGuard] }
];

@NgModule({
	imports: [
		CommonModule,
		ComponentsModule,
		RouterModule.forChild(CategoriesRoutes),
		NgbModule,
		DirectiveModule
	],
	exports: [],
	declarations: [
		CategoriesList,
		EditCategory,
		MacroareaList,
		EditMacroarea
	],
	providers: [],
})
export class CategoriesModule { }
