import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectListitem } from 'app/common/models/models';
import { CategoriesProvider } from 'app/services/CategoriesProvider.service';
import { iconSave, iconCancel} from "app/common/utils/icons.utils"
import { CultureInfo, CultureService, CulturesKey } from 'app/common/services/culture.service';
import { CommonValidators } from 'app/common/components/form/validators/common.validator';
import { FormFieldDescription } from 'app/common/models/forms';
import { DomainEntityType } from 'app/models/domainentities.model';
import { CATEGORIES_LIST_PATH } from 'app/common/utils/routing-paths.utils';

@Component({
    selector: 'edit-category-page',
    templateUrl: './edit-category.page.html'
})
export class EditCategory implements OnInit{
	public actionTitle: "categories.newCategoryPageTitle" | any;
	public actionDescription;
    public form: FormGroup = null;
    public stringValue = null;
    public selectItems: SelectListitem[] = [];
	public iconSave = iconSave;
	public iconCancel = iconCancel;
	namesDescription: FormFieldDescription[] = [
		{
			defaultValue: null,
			fieldName: "text",
			label: "categories.editCategoryInputFieldNamesLabel",
			fieldType: 'inputstring',
			validators: [Validators.required]
		}
	];
    constructor(private _fb: FormBuilder, private _categoriesProvider: CategoriesProvider, private _activatedRoute: ActivatedRoute, private _router: Router, private _cultureService: CultureService) {
        
    }

	ngOnInit() {
		let requiredCultures: CultureInfo[] = this._cultureService.getCultureInfo([CulturesKey.IT]);
		this.selectItems.push(new SelectListitem(DomainEntityType.CULTURAL_HERITAGE, "Beni culturali"));
		this.selectItems.push(new SelectListitem(DomainEntityType.PLACE, "Luoghi o aree"));
		
        this._activatedRoute.params.subscribe(params => {
			let id = params['id'];
			if (id != undefined || id != null) {
				
				this._categoriesProvider.getCategory(id).subscribe((result) => {
				
					let localizedFields: any[] = [];
					
					if (result) {
						result.localizedNames.forEach((name) => {
							let dataDef: any = {
								language: [null, Validators.required]
							};
							this.namesDescription.forEach((field) => {
								dataDef[""+field.fieldName] = [field.defaultValue]
								field.validators.forEach((validator) => {
									dataDef[""+field.fieldName].push(validator);
								})
							});
							localizedFields.push(this._fb.group(dataDef));
						})
					}
					let form = this._fb.group({
						id: [null],
						entityTypes: [[]],
						localizedNames: this._fb.array(localizedFields, [CommonValidators.localizedMinItemsValidator(1), CommonValidators.requiredCultures(requiredCultures)])
					});
					form.patchValue(result);
					this.actionTitle = result.name;
					this.actionDescription = "categories.updateCategoryPageTitle";
					form.updateValueAndValidity();
					this.form = form;
				
				})
			}
			else {
				this.actionTitle = "categories.newCategoryPageTitle";
				this.form = this._fb.group({
					id: [null],
					entityTypes: [[]],
					localizedNames: this._fb.array([], [
						CommonValidators.localizedMinItemsValidator(1),
						CommonValidators.requiredCultures(requiredCultures)
					])
				});
			}
		 });
	}


	cancel() {
		this._activatedRoute.queryParams.subscribe(qParam => {
			if (qParam.returnUrl)
				this._router.navigateByUrl(qParam.returnUrl);
			else
				this._router.navigate([CATEGORIES_LIST_PATH])
		})
	}
    public save() {
        if (this.form.isValid()) {
			this._categoriesProvider.saveOrUpdateCategory(this.form.value).subscribe((category) => {
				this._activatedRoute.queryParams.subscribe(qParam => {
					if (qParam.returnUrl)
						this._router.navigateByUrl(qParam.returnUrl)
					else
						this._router.navigate([CATEGORIES_LIST_PATH])

				})
			})
        }
    }
}
