import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseDataTablePage } from 'app/common/components/pages/base-data-table.page';
import { DataTableAction, DataTableColumn, IDataTableManager, RemoteDataTableManager } from 'app/common/components/table/types';
import { DataTableUtils } from 'app/common/utils/data-table.utils';
import { ModalService } from 'app/common/services/modal.service';
import { iconEdit, iconTrash, iconShare, iconAdd} from 'app/common/utils/icons.utils';
import { SharingService } from 'app/services/sharing.service';
import { ModalOptions, SearchResult } from 'app/common/models/models';
import { SharingPermission } from 'app/models/sharing.model';
import { DomainEntity, DomainEntitySearchCriteria, DomainEntityType } from 'app/models/domainentities.model';
import { DomainEntityProvider } from 'app/services/DomainEntityProvider.service';
import { Observable } from 'rxjs';
import { EDIT_DOMAIN_ENTITIES_PATH } from 'app/common/utils/routing-paths.utils';


@Component({
    selector: 'entities-list',
    templateUrl: './entities-list.page.html'
})
export class EntitiesList extends BaseDataTablePage<DomainEntity, Filters> {
    public tableColumns: DataTableColumn[] = [];
    public tableActions: DataTableAction[] = [];
    public filters: Filters = new Filters();
	public iconAdd= iconAdd;

	private searchEntity = (searchCriteria: DomainEntitySearchCriteria): Observable<SearchResult<DomainEntity>> => {
		searchCriteria.entityType = this.entityType;
		return this._entitiesProvider.search(searchCriteria);
	}

	constructor(private entityType: DomainEntityType, _activatedRoute: ActivatedRoute, private _router: Router, private _entitiesProvider: DomainEntityProvider, private _modalService: ModalService, private _sharingService: SharingService) {
        super(_activatedRoute, true);
		this.createColumms();
		this.createActions();
    }

    protected getDataTableManager = (searchCriteria: DomainEntitySearchCriteria): IDataTableManager<DomainEntity> => {
		return new RemoteDataTableManager(this.searchEntity, this.setSearchCriteria, searchCriteria)
    }

	pageTitle() {

	}
	cardHeaderTitle() {
	}
	newButtonLabel() {
	}
    private createColumms = (): void => {
        let nameColumn = DataTableUtils.createStringColumn("name", "domainEntity.nameColumnLabel", false);
        this.tableColumns.push(nameColumn);
		let published = DataTableUtils.createBooleanColumn("published", "domainEntity.publishedColumnLabel", true);
		this.tableColumns.push(published);
		let creationDate = DataTableUtils.createDateTimeColumn("createdDate", "domainEntity.creationDate", true);
		this.tableColumns.push(creationDate);
		let lastModifiedDate = DataTableUtils.createDateTimeColumn("lastModifiedDate", "domainEntity.lastModifiedDate", true);
		this.tableColumns.push(lastModifiedDate);
    }

    private createActions = (): void => {
        let button = new DataTableAction();
        button.funcToInvoke = this.delete;
        button.label = "common.deleteButtonLabel";
        button.icon = iconTrash;
		button.enablePermission="domainentities.write";
        this.tableActions.push(button);
		button.enableFunc = (row: DomainEntity) => {
			return (row.permissions.lastIndexOf(SharingPermission.DELETE) >= 0)
		}

		button = new DataTableAction();
        button.funcToInvoke = this.edit;
        button.label = "common.updateButtonLabel";
        button.icon = iconEdit;
		button.enablePermission="domainentities.write";
        this.tableActions.push(button);
		button.enableFunc = (row: DomainEntity) => {
			return (row.permissions.lastIndexOf(SharingPermission.WRITE) >= 0)
		}
		button = new DataTableAction();
        button.funcToInvoke = this.share;
        button.label = "common.shareManagement";
		button.enablePermission="domainentities.write";
        button.icon = iconShare;
		this.tableActions.push(button);
		
    }

	public share = (row: DomainEntity): void => {
		let options = new ModalOptions();
		options.size = "xl";
		options.callback = (data) => {
		}
		this._modalService.showSharingRequest(row.id, "domainentities", row.name, options);
		
    }

    public edit = (row: DomainEntity): void => {
        const queryParams = {};
        queryParams['returnUrl'] = this.getReturnUrl();
        console.log(queryParams['returnUrl']);
        this._router.navigate([EDIT_DOMAIN_ENTITIES_PATH, this.entityType, "edit", row.id], { queryParams: queryParams });
    }
	public delete = (row: DomainEntity): void => {
		let callback = (data) => {
			if (data) {
				this._entitiesProvider.delete(row).subscribe((result) => {
					this.dataTableManager.startSearch();
				})
			}
		}
		this._modalService.showConfirm("common.dialogs.alertRemoveItemTitle", "common.dialogs.alertRemoveItemMessage", {
			callback: callback,
			size: "md"
		});
    }

	public addEntity = () => {
		const queryParams = {};
        queryParams['returnUrl'] = this.getReturnUrl();
        this._router.navigate([EDIT_DOMAIN_ENTITIES_PATH, this.entityType, "edit"], { queryParams: queryParams });

	}

    protected setSearchCriteria = (criteria: DomainEntitySearchCriteria): void => {
		// if (criteria.field == "name")
        // 	criteria.field = "localizedNames.name";
		criteria.entityType = this.entityType;
		console.log("criteria",criteria);
		
    }

    public search = (): void => {
        this.dataTableManager.startSearch();
    }
}


class Filters {
    public published: string = "";
	public contentTypes: string = "";
	public entityType: string = "";
}