import { Injectable } from "@angular/core";
import { SearchResult } from "app/common/models/models";
import { BaseProvider } from "app/common/services/common-provider.service";
import { CultureService } from "app/common/services/culture.service";
import { Role } from "app/models/roles";
import { User, UserSearchCriteria, UserView } from "app/models/user.model";
import { environment } from "environments/environment";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { HttpService } from "./http.service";

@Injectable()
export class UsersProvider extends BaseProvider {

	constructor(private _httpService: HttpService, private _cultureService: CultureService) {
		super();
	}
	
	private _initUser(src: User): User {
		let culture = this._cultureService.getCurrentCulture();
		src.roles = src.roles.map((role) => {
			// gr.roles = gr.roles.map((role) => {
				let nRole = new Role();
				Object.assign(nRole, role);
				nRole.defineLanguage(culture);
				return nRole;
			// })
			// return gr;
		});		
		return src;
	}

	public getUser = (id: any): Observable<User> => {
		// return of(this._initUser({"id":7,"email":"ing.giuseppedisanto@gmail.com","givenName":"Giuseppe","surname":"Di Santo","roles":[]}))
		let url = environment.services.appSettingsBasePath+"/users/"+id;
		return this._httpService.get<User>(url).pipe(
			map((result: User) => {
				return this._initUser(result);
			}
		))
	}

	/**
	 * @TODO da rivedere i parametri
	 * 
	 * @param searchCriteria 
	 * @returns 
	 */
	public searchUsers = (searchCriteria: UserSearchCriteria): Observable<SearchResult<UserView>> => {
		let url = environment.services.appSettingsBasePath+"/users?"+this.serializePagination(searchCriteria);
		return this._httpService.get<SearchResult<UserView>>(url).pipe(
			map((result: SearchResult<UserView>) => {
				let toReturn = new SearchResult<UserView>();
				toReturn.totalCount = result.totalCount;
				toReturn.items = [];
				result.items.forEach((item) => {
					toReturn.items.push(item);
				})
				return toReturn;
			})
		);
	}

	public updateUser = (entity: User): Observable<User> => {
		let toSave : any = {};
		toSave.id = entity.id;
		toSave.roles = entity.roles.map((r) => {return {id: r.id}});
		return this._httpService.put<User>(environment.services.appSettingsBasePath+"/users/"+entity.id, toSave);

	}
}