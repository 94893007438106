import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { FormPage } from './form/form.page';
import { RouterModule, Routes } from '@angular/router';
import { ComponentsModule } from 'app/common/components/components.module';
import { TablePage } from './tables/table.page';
import { ListPage } from './tables/list.page';
import { DialogsPage } from './dialogs/dialogs.page';
import { AuthGuard } from 'app/apex/shared/auth/auth-guard.service';
import { NgJsonEditorModule } from 'ang-jsoneditor';

export const SampleRoutes: Routes = [
    { path: 'samples/form', component: FormPage, canActivate: [AuthGuard] },
    { path: 'samples/data-table', component: TablePage, canActivate: [AuthGuard] },
    { path: 'samples/list-table', component: ListPage, canActivate: [AuthGuard] },
    { path: 'samples/dialogs', component: DialogsPage, canActivate: [AuthGuard] }
];

@NgModule({
    imports: [
        CommonModule,
        ComponentsModule,
        RouterModule.forChild(SampleRoutes),
		NgJsonEditorModule
    ],
    exports: [],
    declarations: [
        FormPage,
        TablePage,
        ListPage,
        DialogsPage
    ],
    providers: [],
})
export class SampleModule { }
