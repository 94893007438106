import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { OAuthService, AuthConfig } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';

import { from, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { filter, map, share, tap } from 'rxjs/operators';
import { merge } from 'lodash';
import { Identity, Settings } from 'app/models/security.models';


@Injectable()
export class SecurityService {
    constructor(private _oauthService: OAuthService, private http: HttpClient) {
        this._oauthService.configure(this.getAuthConfig());
        this._oauthService.tokenValidationHandler = new JwksValidationHandler();
        this._oauthService.setStorage(sessionStorage);
        this._oauthService.setupAutomaticSilentRefresh();
    }

    private getAuthConfig = (): AuthConfig => {
        let fullPath = document.head.baseURI;
        if (fullPath.endsWith("/")) {
            fullPath = fullPath.substr(0, fullPath.length - 1);
        }
        let ret = new AuthConfig();
        ret.issuer = environment.security.issuer;
        ret.redirectUri = fullPath;
        ret.strictDiscoveryDocumentValidation = false;
        ret.silentRefreshRedirectUri = fullPath + '/assets/authorization/silent-refresh.html'
        ret.clientId = "3a24aa23-949c-4560-a27e-48b30cab4128";
        ret.responseType = "code"
        ret.scope = 'openid profile offline_access 3a24aa23-949c-4560-a27e-48b30cab4128';
        ret.postLogoutRedirectUri = fullPath;

        return ret;
    }
    public initialize = (): Promise<any> => {
        const tryLogin = from(this._oauthService.loadDiscoveryDocument(environment.security.discoveryDocumentUrl).then(() =>
            this._oauthService.tryLogin()
        ).then(result => {
            if (this._oauthService.hasValidAccessToken()) {
                return of({}).toPromise();
            }
            else {
                return of(null).toPromise();
            }
        })).pipe(share());


        const noLogged = tryLogin.pipe(filter(result => result == null)).pipe(map(result => {
            return false;
        }));

        const loggedBase = tryLogin.pipe(filter(result => result != null)).pipe(tap(result => {
            let claims = this._oauthService.getIdentityClaims();
            let user = new Identity();
            user.name = claims['given_name'];
            user.surname = claims['family_name'];
            // user.id = claims['sub'];
            // user.preferred_username = claims['preferred_username'];
            Settings.identity = user;
        }));

        return merge(noLogged, loggedBase).toPromise();
    }

    public get isAuthenticated() {
        return this._oauthService.hasValidAccessToken();
    }

    public startLogin = (): void => {
        return this._oauthService.initCodeFlow();
    }

    public getAccessToken = (): string => {
        return this._oauthService.getAccessToken();
    }

	public getIdentityClaims = (): any => {
		return this._oauthService.getIdentityClaims();
	}
    public logOut = (): void => {
        return this._oauthService.logOut();
    }
}

