<p>
	Condivisi con uno o più utenti il tuo contenuto. Aggiungi gli utenti e definisci i permessi che possono avere sul Contenuto.
</p>
<form [formGroup]="form" *ngIf="form">
	
	<div class="row">
		<div class="col-12">
			<select-box formControlName="permission" label="permessi" [items]="possiblePermission" [multiple]="false">
			</select-box>
		</div>
	</div>
	<div class="row">
		<div class="col-12">
			<input-tags
				formControlName="emails"
				[label]="'Indirizzi email'"
				[placeHolder]="'aggiungi altro utente'"
				initialPlaceholder="{{'Inserisci il nome utente; invio per confermare'}}"
			>
			</input-tags>
		</div>
	</div>
</form>
<!-- <div class="card">
	<div class="card-header">
		{{'categories.editCategoryFormTitle' | translate}}
	</div>
	<div class="card-content">
		<div class="card-body">
			<div class="row">
				<div class="col-12">
					<form [formGroup]="form" *ngIf="form">
						<div class="row">
							<div class="col-12">
								<input-tags formControlName="emails" label="femail">
								</input-tags>
							</div>
						</div>
						<div class="row">
							<div class="col-12">
								<select-box formControlName="permission" label="permessi" [items]="possiblePermission" [multiple]="true">
								</select-box>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</div> -->