import {
  Component, OnInit, ViewChild, OnDestroy,
  ElementRef, AfterViewInit, ChangeDetectorRef, HostListener
} from "@angular/core";
import { ROUTES } from './vertical-menu-routes.config';
import { HROUTES } from '../horizontal-menu/navigation-routes.config';

import { Router } from "@angular/router";
import { customAnimations } from "../animations/custom-animations";
import { DeviceDetectorService } from 'ngx-device-detector';
import { ConfigService } from '../services/config.service';
import { Subscription } from 'rxjs';
import { LayoutService } from '../services/layout.service';
import { AccessManagement } from "app/services/access-management.service";
import { RouteInfo } from "./vertical-menu.metadata";

@Component({
  selector: "app-sidebar",
  templateUrl: "./vertical-menu.component.html",
  animations: customAnimations
})
export class VerticalMenuComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('toggleIcon') toggleIcon: ElementRef;
  public menuItems: any[];
  level: number = 0;
  logoUrl = 'assets/img/logo.png';
  public config: any = {};
  protected innerWidth: any;
  layoutSub: Subscription;
  configSub: Subscription;
  perfectScrollbarEnable = true;
  collapseSidebar = false;
  resizeTimeout;

  constructor(
    private router: Router,
    private layoutService: LayoutService,
    private configService: ConfigService,
    private cdr: ChangeDetectorRef,
    private deviceService: DeviceDetectorService,
	private _accessManagement: AccessManagement
  ) {
    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;
    this.isTouchDevice();
  }


  	verifyPermissionForRouteInfo(routeInfo: RouteInfo): RouteInfo {
		let result: any;
		if (routeInfo.hasOwnProperty('permissionId') && routeInfo.permissionId) {
			if (this.userHasPermission(routeInfo.permissionId))
				return routeInfo;
		}
		else if (!routeInfo.hasOwnProperty('permissionId') && routeInfo.submenu && routeInfo.submenu.length > 0) {
			result = {};
			Object.assign(result,routeInfo);
			let newSubmenu = [];
			routeInfo.submenu.forEach(r => {
				let added = this.verifyPermissionForRouteInfo(r);
				if (added)
				newSubmenu.push(added)
			});
			if (newSubmenu.length > 0) {
				result.submenu = newSubmenu;
			}
			else {
				result = undefined;
			}

		}
		else if (!routeInfo.hasOwnProperty('permissionId')) {
			return routeInfo;
		}
		return result;
	}
	private permissions: string[] = [];
	private routes: RouteInfo[] = [];
	private hRoutes: RouteInfo[] = [];
	loadingMenu = true;

	private userHasPermission = (permissionId): boolean => {
		return this.permissions && this.permissions.lastIndexOf(permissionId) >= 0;
	}
	ngOnInit() {		
		if (this.routes.length == 0) {
			this.loadingMenu = true;
			this._accessManagement.getAllUserPermissionIds().subscribe((permissions) => {
				this.permissions = permissions;
				let routes : RouteInfo[] = [];
				ROUTES.forEach((item) => {
					let r: RouteInfo = this.verifyPermissionForRouteInfo(item);
					if (r) routes.push(r);
				})
				this.routes = routes;
	
				routes = [];
				HROUTES.forEach((item) => {
					let r: RouteInfo = this.verifyPermissionForRouteInfo(item);
					if (r) routes.push(r);
				})
				this.hRoutes = routes;
				
				this.menuItems = this.routes;
				this.loadingMenu = false;
				this.loadLayout();
      			this.cdr.markForCheck();
			})
		}
		else {
			this.loadingMenu = false;
			this.loadLayout();
			this.cdr.markForCheck();
		}
	}

  ngAfterViewInit() {

    this.configSub = this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }
    //   this.loadLayout();
    //   this.cdr.markForCheck();

    });

    this.layoutSub = this.layoutService.overlaySidebarToggle$.subscribe(
      collapse => {
        if (this.config.layout.menuPosition === "Side") {
          this.collapseSidebar = collapse;
        }
      });

  }


  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
      if (this.resizeTimeout) {
          clearTimeout(this.resizeTimeout);
      }
      this.resizeTimeout = setTimeout((() => {
        this.innerWidth = event.target.innerWidth;
          this.loadLayout();
      }).bind(this), 500);
  }

  loadLayout() {

	if (!this.loadingMenu) {
		if (this.config.layout.menuPosition === "Top") { // Horizontal Menu
			if (this.innerWidth < 1200) { // Screen size < 1200
			  this.menuItems = this.hRoutes;
			}
		  }
		  else if (this.config.layout.menuPosition === "Side") { // Vertical Menu{
			this.menuItems = this.routes;
		  }
	  
	}
   



    if (this.config.layout.sidebar.backgroundColor === 'white') {
      this.logoUrl = 'assets/img/logo-dark.png';
    }
    else {
      this.logoUrl = 'assets/img/logo.png';
    }

    if(this.config.layout.sidebar.collapsed) {
      this.collapseSidebar = true;
    }
    else {
      this.collapseSidebar = false;
    }
  }

  toggleSidebar() {
    let conf = this.config;
    conf.layout.sidebar.collapsed = !this.config.layout.sidebar.collapsed;
    this.configService.applyTemplateConfigChange({ layout: conf.layout });

    setTimeout(() => {
      this.fireRefreshEventOnWindow();
    }, 300);
  }

  fireRefreshEventOnWindow = function () {
    const evt = document.createEvent("HTMLEvents");
    evt.initEvent("resize", true, false);
    window.dispatchEvent(evt);
  };

  CloseSidebar() {
    this.layoutService.toggleSidebarSmallScreen(false);
  }

  isTouchDevice() {

    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();

    if (isMobile || isTablet) {
      this.perfectScrollbarEnable = false;
    }
    else {
      this.perfectScrollbarEnable = true;
    }

  }


  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }

  }

}
