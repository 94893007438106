import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectListitem } from 'app/common/models/models';
import { CategoriesProvider } from 'app/services/CategoriesProvider.service';
import { iconSave, iconCancel} from "app/common/utils/icons.utils"
import { CommonValidators } from 'app/common/components/form/validators/common.validator';
import { CultureInfo, CultureService, CulturesKey } from 'app/common/services/culture.service';
import { FormFieldDescription } from 'app/common/models/forms';
import { MACROAREA_LIST_PATH } from 'app/common/utils/routing-paths.utils';
@Component({
    selector: 'edit-macroarea-page',
    templateUrl: './edit-macroarea.page.html'
})
export class EditMacroarea implements OnInit{
	public actionTitle: "categories.newMacroareaPageTitle" | any;
	public actionDescription;
    public form: FormGroup = null;
    public stringValue = null;
    public selectItems: SelectListitem[] = [];
	public iconSave = iconSave;
	public iconCancel = iconCancel;
	possibileMediaTypes = ['image/png', 'image/jpeg', 'image/gif'];
	
	namesDescription: FormFieldDescription[] = [
		{
			defaultValue: null,
			fieldName: "text",
			label: "categories.editMacroareaInputFieldNamesLabel",
			fieldType: 'inputstring',
			validators: [Validators.required]
		}
	];
    constructor(private _fb: FormBuilder, private _categoriesProvider: CategoriesProvider, private _activatedRoute: ActivatedRoute, private _router: Router, private _cultureService: CultureService) {
        
    }

	ngOnInit() {
		let requiredCultures: CultureInfo[] = this._cultureService.getCultureInfo([CulturesKey.IT]);
        this._activatedRoute.params.subscribe(params => {
			this._categoriesProvider.getAllCategoriesAutocomplete().subscribe(result => {
				this.selectItems = result.map(m => new SelectListitem(m.id, m.name));
			});
			let id = params['id'];
			if (id != undefined || id != null) {
				
				this._categoriesProvider.getMacroarea(id).subscribe((result) => {
				
					let localizedFields: any[] = [];
					if (result) {
						result.localizedNames.forEach((name) => {
							let dataDef: any = {
								language: [null, Validators.required]
							};
							this.namesDescription.forEach((field) => {
								dataDef[""+field.fieldName] = [field.defaultValue]
								field.validators.forEach((validator) => {
									dataDef[""+field.fieldName].push(validator);
								})
							});
							localizedFields.push(this._fb.group(dataDef));
						})
					}
					
					let form = this._fb.group({
						id: [null],
						localizedNames: this._fb.array(localizedFields, [CommonValidators.localizedMinItemsValidator(1), CommonValidators.requiredCultures(requiredCultures)]),
						categories: [[], CommonValidators.arrayMinItemsValidator(1, "emptyArray")],
						imageId: [null, CommonValidators.requiredImage]
					});
					let patchValue : any= new Object();
					Object.assign(patchValue,result);
					patchValue.categories = result.categories.map(m => { return m.id});
					form.patchValue(patchValue);
					this.actionTitle = patchValue.name;
					this.actionDescription = "categories.updateMacroareaPageTitle";
					form.updateValueAndValidity();
					this.form = form;
					this.form.valueChanges.subscribe((res) => {
						console.log("chages", res);
						
					})
				})
			}
			else {
				this.actionTitle = "categories.newMacroareaPageTitle";
				
				this.form = this._fb.group({
					id: [null],
					localizedNames: this._fb.array([], [
						CommonValidators.localizedMinItemsValidator(1),
						CommonValidators.requiredCultures(requiredCultures)
					]),
					categories: [[], CommonValidators.arrayMinItemsValidator(1, "emptyArray")],
					imageId: [null, CommonValidators.requiredImage]
				});
			}
		 });
	}

	

	cancel() {
		this._activatedRoute.queryParams.subscribe(qParam => {
			if (qParam.returnUrl)
				this._router.navigateByUrl(qParam.returnUrl);
			else
				this._router.navigate([MACROAREA_LIST_PATH])
		})
	}
    public save() {
        if (this.form.isValid()) {
			let value: any = new Object();
			Object.assign(value,this.form.value);
			value.categories = value.categories.map(m => { return {id: m}});
			this._categoriesProvider.saveOrUpdateMacroarea(value).subscribe((category) => {
				this._activatedRoute.queryParams.subscribe(qParam => {
					if (qParam.returnUrl)
						this._router.navigateByUrl(qParam.returnUrl)
					else
						this._router.navigate([MACROAREA_LIST_PATH])

				})
			})
        }
    }
}
