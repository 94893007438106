import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonValidators } from 'app/common/components/form/validators/common.validator';
import { FormFieldDescription } from 'app/common/models/forms';
import { SelectListitem } from 'app/common/models/models';
import { CultureInfo, CultureService, CulturesKey } from 'app/common/services/culture.service';
import { ModalService } from 'app/common/services/modal.service';
import { iconCancel, iconPublish, iconSave, iconTrash } from 'app/common/utils/icons.utils';
import { CULTURAL_HERITAGES_LIST_PATH, PLACE_LIST_PATH } from 'app/common/utils/routing-paths.utils';
import { LinkedContentsSet, LinkedContetsTagTypeForDomainEntity } from 'app/models/contents.model';
import { DomainEntityType } from 'app/models/domainentities.model';
import { BaseLocalizableEntity, LocalizableEntity } from 'app/models/object-space.model';
import { BaseEditLinkedContents, LinkedContentsSetView } from 'app/pages/BaseEditLinkedContents.page';
import { CategoriesProvider } from 'app/services/CategoriesProvider.service';
import { DomainEntityProvider } from 'app/services/DomainEntityProvider.service';
import { ObjectSpaceManagementProvider } from 'app/services/object-space-management.service';
import { forkJoin } from 'rxjs';



@Component({
	selector: 'edit-domain-entity-page',
	templateUrl: './edit-domain-entity.page.html'
})
export class EditDomainEntity extends BaseEditLinkedContents {
	public actionTitle;
	public actionDescription;
    public form: FormGroup = null;
    public stringValue = null;
    public selectItems: SelectListitem[] = [];
    public initialItem: SelectListitem = null;
	public iconSave = iconSave;
	public iconCancel = iconCancel;
	public iconPublish = iconPublish;
	public deleteLinkedContentsIcon = iconTrash;
	public isNew: boolean = true;
	private goBackUrl = "";
	public entityType: DomainEntityType;
	namesDescription: FormFieldDescription[] = [
		{
			defaultValue: null,
			fieldName: "text",
			label: "",
			fieldType: 'inputstring',
			validators: [Validators.required]
		}
	];
    constructor(private _fb: FormBuilder, private _domainEntitesProvider: DomainEntityProvider, private _categoriesProvider: CategoriesProvider, private _activatedRoute: ActivatedRoute, _router: Router, private _cultureService: CultureService, _modalService: ModalService) {
        super(_domainEntitesProvider,_router,_modalService);
    }
	type = "";
	
	ngOnInit() {
		let requiredCultures: CultureInfo[] = this._cultureService.getCultureInfo([CulturesKey.IT]);
		this.createActions();
		for (let prop in LinkedContetsTagTypeForDomainEntity) {
			this._addMenuDef.push({
				label: LinkedContetsTagTypeForDomainEntity[prop],
				value: LinkedContetsTagTypeForDomainEntity[prop]
			});
		}

        this._activatedRoute.params.subscribe(params => {
			
			
			let id = params['id'];
			this.entityType = params['entityType'];
			this._categoriesProvider.getAllCategoriesAutocomplete(null, [this.entityType]).subscribe(result => {
				this.selectItems = result.map(m => new SelectListitem(m.id, m.name));
			});
			this.type = (this.entityType == DomainEntityType.CULTURAL_HERITAGE)? "ch": "place";
			this.goBackUrl = (this.entityType == DomainEntityType.CULTURAL_HERITAGE)? CULTURAL_HERITAGES_LIST_PATH: PLACE_LIST_PATH
			this.namesDescription[0].label = (this.entityType == DomainEntityType.CULTURAL_HERITAGE)?
				"domainEntity.ch.editInputFieldNamesLabel": "domainEntity.place.editInputFieldNamesLabel";
			if (id != undefined || id != null) {
				this.entityId = id;
				
				forkJoin({
					entity: this._domainEntitesProvider.getEntity(id),
					linkedContentsSets: this._domainEntitesProvider.getLinkedContentsSets(id),
				}	
				)

				// this._domainEntitesProvider.getEntity(id)
				.subscribe((result) => {
				
					let localizedFields: any[] = [];
					if (result) {
						result.entity.localizedNames.forEach((name) => {
							let dataDef: any = {
								language: [null, Validators.required]
							};
							this.namesDescription.forEach((field) => {
								dataDef[""+field.fieldName] = [field.defaultValue]
								field.validators.forEach((validator) => {
									dataDef[""+field.fieldName].push(validator);
								})
							});
							localizedFields.push(this._fb.group(dataDef));
						})
					}
					let form = this._fb.group({
						entityType: [this.entityType],
						id: [null],
						published: [false],
						localizedNames: this._fb.array(localizedFields, [CommonValidators.localizedMinItemsValidator(1), CommonValidators.requiredCultures(requiredCultures)]),
						categories: [[], CommonValidators.arrayMinItemsValidator(1, "emptyArray")],
					});
					this._patchValue(result.entity,form);
					
					this.actionTitle = result.entity.name;
					this.actionDescription = 
						(this.entityType == DomainEntityType.CULTURAL_HERITAGE)?
						"domainEntity.ch.updateDomainEntityPageDescription": "domainEntity.place.updateDomainEntityPageDescription";

					this.linkedContentsSet = result.linkedContentsSets.map((el: LinkedContentsSet) => {
						let r = new LinkedContentsSetView(this.entityId, this._domainEntitesProvider, el.id);
						r.tag = el.tag;
						return r;
					})
					this.isNew = false;
				})
			}
			else {
				this.actionTitle = 
					(this.entityType == DomainEntityType.CULTURAL_HERITAGE)?
					"domainEntity.ch.newDomainEntityPageTitle": "domainEntity.place.newDomainEntityPageTitle";
				this.form = this._fb.group({
					entityType: [this.entityType],
					id: [null],
					localizedNames: this._fb.array([], [
						CommonValidators.localizedMinItemsValidator(1),
						CommonValidators.requiredCultures(requiredCultures)
					]),
					published: [false],
					categories: [[], CommonValidators.arrayMinItemsValidator(1, "emptyArray")],
				});
				this.form.valueChanges.subscribe((res) => {
					console.log("chages", res);
				})
				this.isNew = true;
			}
		 });
	}

	private _patchValue(value, form) {
		let patchValue : any= new Object();
		Object.assign(patchValue,value);
		patchValue.categories = value.categories.map(m => { return m.id});
		form.patchValue(patchValue);
		form.updateValueAndValidity();
		this.form = form;
	}

	private goToPrevPage = () => {
		let counterPages = 0;
		this._activatedRoute.queryParams.subscribe(qParam => {
			counterPages++;
			if (qParam.returnUrl && counterPages == 1) {
				this._router.navigateByUrl(qParam.returnUrl);
			}
			else if (counterPages == 1) {
				this._router.navigate([this.goBackUrl])
				// this._router.navigate([CONTENTS_LIST_PATH, this.contentType])
			}
		})
	} 

	public cancel = () => {
		this.goToPrevPage();
	}

	// public cancel = () => {
	// 	this._activatedRoute.queryParams.subscribe(qParam => {
	// 		if (qParam.returnUrl)
	// 			this._router.navigateByUrl(qParam.returnUrl);
	// 		else
	// 			this._router.navigate([this.goBackUrl])
	// 	})
	// }
    public save = () => {
        if (this.form.isValid()) {
			this._saveDomainEntity(this.form.value);
        }
    }

	private _saveDomainEntity = (value, goBack = true) => {
		value.categories = this.form.value.categories.map(m => { return {id: m}});
		
		this._domainEntitesProvider.saveOrUpdate(value).subscribe((entity) => {
			if (goBack) {
				this._activatedRoute.queryParams.subscribe(qParam => {
					if (qParam.returnUrl)
						this._router.navigateByUrl(qParam.returnUrl)
					else
						this._router.navigate([this.goBackUrl])
	
				})
			}
			else {
				this._patchValue(entity, this.form);
			}
			
		})
	}
	public updatePublishState() {
		if (this.form.isValid()) {
			let value: any = new Object();
			Object.assign(value,this.form.value);
			value.published = !value.published;
			this._saveDomainEntity(value, false);
		}
	}
	public get published() {
		return this.form.valid && this.form.value && this.form.value.published;
	}



	public get saveButtonLabel() {
		if (this.isNew) return "Crea";
		return "common.saveButtonLabel";
	}

	public get cancelButtonLabel() {
		if (this.isNew) return "common.cancelButtonLabel";
		return "common.goBackButtonLabel";
	}
	public get showAdditionalContent(): boolean {
		return (!this.isNew);
	}
}
