import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseDataTablePage } from 'app/common/components/pages/base-data-table.page';
import { DataTableAction, DataTableColumn, IDataTableManager, RemoteDataTableManager } from 'app/common/components/table/types';
import { DataTableUtils } from 'app/common/utils/data-table.utils';
import { MacroareaView, MacroareaSearchCriteria } from 'app/models/categories.model';
import { CategoriesProvider } from 'app/services/CategoriesProvider.service';
import { iconEdit, iconTrash,iconAdd } from 'app/common/utils/icons.utils';
import { ModalService } from 'app/common/services/modal.service';
import { FormFieldDescription } from 'app/common/models/forms';
import { map } from 'rxjs/operators';
import { SearchResult, SelectListitem } from 'app/common/models/models';
import { Observable } from 'rxjs';
import { EDIT_MACROAREA_PATH } from 'app/common/utils/routing-paths.utils';


@Component({
    selector: 'macroarea-list',
    templateUrl: './macroarea-list.page.html'
})
export class MacroareaList extends BaseDataTablePage<MacroareaView, Filters> {
    public tableColumns: DataTableColumn[] = [];
    public tableActions: DataTableAction[] = [];
    public filters: Filters = new Filters();
	public filterDef: FormFieldDescription[] = [];

	public iconAdd= iconAdd;
    constructor(_translateService: TranslateService, _activatedRoute: ActivatedRoute, private _router: Router, private _categoriesProvider: CategoriesProvider, private _modalService: ModalService) {
        super(_activatedRoute, true);
        this.createColumms();
        this.createActions();

    }

	protected initialize = (): void => {
		let categorySource: Observable<SelectListitem[]> = this._categoriesProvider.getAllCategoriesAutocomplete().pipe(
			map(result => {
				return result.map(m => new SelectListitem(m.id, m.name));
			})
		);
		this.filterDef = [
			{
				fieldName: "text",
				defaultValue: null,
				fieldType: 'inputstring',
				validators: [],
				label: "categories.macroareaNameColumnLabel"
			},
			{
				fieldName: "categoryIds",
				defaultValue: null,
				fieldType: 'miltipleselect',
				validators: [],
				label: "categories.categoriesListLinkedToAreaFielLabel",
				possibleDataValues: categorySource
			}
		];
    }

	private searchAreas =  (searchCriteria: MacroareaSearchCriteria): Observable<SearchResult<MacroareaView>> => {
		
		return this._categoriesProvider.searchMacroarea(searchCriteria).pipe(
			map((result: SearchResult<MacroareaView>) => {
				let toReturn = new SearchResult<MacroareaView>();
				toReturn.totalCount = result.totalCount;
				toReturn.items = result.items.map((value) => {
					(<any>value).icon = "";
					(<any>value).mediaId = value.imageId;
					return value;
				});
				return toReturn;
			})
		);
    }

    protected getDataTableManager = (searchCriteria: MacroareaSearchCriteria): IDataTableManager<MacroareaView> => {
        return new RemoteDataTableManager(this.searchAreas, this.setSearchCriteria, searchCriteria)
    }

    private createColumms = (): void => {
		let iconCoumn = DataTableUtils.createDataTableIconImageColumn("icon", "", false);
        this.tableColumns.push(iconCoumn);

        let nameColumn = DataTableUtils.createStringColumn("name", "categories.macroareaNameColumnLabel", true);
        this.tableColumns.push(nameColumn);

		let categoriesColumn = DataTableUtils.createArrayColumn("categories", "categories.categoriesListLinkedToAreaFielLabel", null, false);
		this.tableColumns.push(categoriesColumn);
    }

    private createActions = (): void => {
        let button = new DataTableAction();
        button.funcToInvoke = this.delete;
        button.label = "common.deleteButtonLabel";
        button.enablePermission = "macroareas.write";
		button.icon = iconTrash;
        this.tableActions.push(button)

		button = new DataTableAction();
        button.funcToInvoke = this.edit;
        button.label = "common.updateButtonLabel";
        button.enablePermission = "macroareas.write";
        button.icon = iconEdit;
        this.tableActions.push(button)
    }

    public edit = (row: MacroareaView): void => {
        const queryParams = {};
        queryParams['returnUrl'] = this.getReturnUrl();
        this._router.navigate([EDIT_MACROAREA_PATH, row.id], { queryParams: queryParams });
    }
	public delete = (row: MacroareaView): void => {
		let callback = (data) => {
			if (data) {
				this._categoriesProvider.deleteMacroarea(row).subscribe((result) => {
					this.dataTableManager.startSearch();
				})
			}
		}
		this._modalService.showConfirm("common.dialogs.alertRemoveItemTitle", "common.dialogs.alertRemoveItemMessage", {
			callback: callback,
			size: "md"
		});
    }

	public addItem = () => {
		const queryParams = {};
        queryParams['returnUrl'] = this.getReturnUrl();
        this._router.navigate([EDIT_MACROAREA_PATH], { queryParams: queryParams });
	}

    protected setSearchCriteria = (criteria: MacroareaSearchCriteria): void => {
		if (criteria.field == "name")
        	criteria.field = "localizedNames.text";
		
		criteria.text = this.filters.text;
		criteria.categoryIds = this.filters.categoryIds;
	}
	public onFiltersUpdate = (data) => {
		Object.assign(this.filters,data);
		this.dataTableManager.startSearch();
	}
    public search = (): void => {
        this.dataTableManager.startSearch();
    }
}

/**
 * inizializzare i campi della classe Filter altrimenti non vengono settati al ritorno sulla bagina
 */
class Filters {
	public text: string = null;
	public categoryIds: any[] = [];
}