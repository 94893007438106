import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { SelectListitem } from "app/common/models/models";
import { SharingInfo, SharingPermission } from "app/models/sharing.model";
import { SharingService } from "app/services/sharing.service";
import { ActivatedRoute } from "@angular/router";
import { DataTableUtils } from 'app/common/utils/data-table.utils';
import { DataTableAction, DataTableColumn, ListTableManager } from "../table/types";
import { iconTrash, iconUserRemove } from "app/common/utils/icons.utils";
import { Observable } from "rxjs";
import { BaseListTablePage } from "../pages/base-list-table.page";
import { map } from "rxjs/operators";


@Component({
    selector: 'content-sharing-form-user-update',
    templateUrl: './content-sharing-form-user-update.component.html'
})
export class ContentSharingFormUserUpdate extends BaseListTablePage<SharingInfo> {
	// form: FormArray;
	sharingInfo: SharingInfo[] = [];
	possiblePermission: SelectListitem[] = []
	iconTrash = iconTrash;
	public tableColumns: DataTableColumn[] = [];
    public tableActions: DataTableAction[] = [];
	@Input() updateEnabled: boolean = false;
	@Input() entityId;
	@Input() entityType;
	@Output() sharingRequest: EventEmitter<SharingInfo[]> = new EventEmitter();
	@Output() onValidityChange: EventEmitter<boolean> = new EventEmitter();

	constructor(_activatedRoute: ActivatedRoute, private _sharingService: SharingService) {
        super(_activatedRoute, true);
        this.createColumms();
        this.createActions();
    }


	findData = (): Observable<SharingInfo[]> => {
		this.onValidityChange.emit(false);
		return this._sharingService.getSharingInfo(this.entityId,this.entityType).pipe(
			map((result) => {
				this.sharingInfo = result;
				result.forEach((info) => {
					if (info.owner) (<any>info).permissionDescription = ["permissions.OWNER"];
					else if (info.permissions.indexOf(SharingPermission.ADMIN) >= 0) (<any>info).permissionDescription = ["permissions."+SharingPermission.ADMIN];
					else if (info.permissions.indexOf(SharingPermission.DELETE) >= 0) (<any>info).permissionDescription = ["permissions."+SharingPermission.DELETE];
					else if (info.permissions.indexOf(SharingPermission.WRITE) >= 0) (<any>info).permissionDescription = ["permissions."+SharingPermission.WRITE];
					else if (info.permissions.indexOf(SharingPermission.READ) >= 0) (<any>info).permissionDescription = ["permissions."+SharingPermission.READ];
				})
				return result;
			})
		)
	}
	protected getDataTableManager = (): ListTableManager<SharingInfo> => {
        return new ListTableManager(this.findData);
    }

	private createColumms = (): void => {
		let user = DataTableUtils.createUserAvatarColumn("email","Utente","givenName","surname", true);
		this.tableColumns.push(user);

		let permission = DataTableUtils.createArrayColumn("permissionDescription", "Permessi",null,true);
		this.tableColumns.push(permission);
	}

	private createActions = (): void => {
		let button = new DataTableAction();
		button.funcToInvoke = this.delete;
		button.label = "common.removePermissionLabel";
		button.icon = iconUserRemove;
		button.enableFunc = (row: SharingInfo) => {
			return this.updateEnabled && !row.owner;
		}
		this.tableActions.push(button);
	}
	
	public delete = (row: SharingInfo): void => {
		let index = this.sharingInfo.findIndex(value => {return value.identityServiceId == row.identityServiceId});
		if (index >= 0) {
			this.sharingInfo.splice(index,1);
			this._sharingService.updateSharingStatus(this.entityId,this.entityType,this.sharingInfo).subscribe((result) => {
				this.dataTableManager.startReload();
			})
		}
    }
}


class Filters {
}