import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { NgbModule, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { AuthGuard } from "app/apex/shared/auth/auth-guard.service";
import { ComponentsModule } from "app/common/components/components.module";
import { DirectiveModule } from "app/common/directives/directives.module";
import { EditPermission } from "./edit-permission/edit-permission.page";
import { EditRole } from "./edit-role/edit-role.page";
import { EditUserRoles } from "./edit-user-roles/edit-user-roles.page";
import { PermissionsList } from "./permissions-list.page";
import { RolesList } from "./roles-list.page";
import { UserList } from "./users-list.page";

export const PERMISSIONS_LIST_PATH = "settings/permissions/list"
export const EDIT_PERMISSION_PATH = "settings/permissions/edit"
export const ROLES_LIST_PATH = "settings/roles/list"
export const EDIT_ROLE_PATH = "settings/roles/edit"
export const USER_LIST_PATH = "settings/users/list"
export const EDIT_USER_ROLES_PATH = "settings/users/edit"


export const SettingsRoutes: Routes = [
	{ path: PERMISSIONS_LIST_PATH, component: PermissionsList, canActivate: [AuthGuard] },
	{ path: EDIT_PERMISSION_PATH, component: EditPermission, canActivate: [AuthGuard] },
	{ path: EDIT_PERMISSION_PATH+"/:id", component: EditPermission, canActivate: [AuthGuard] },
	{ path: ROLES_LIST_PATH, component: RolesList, canActivate: [AuthGuard] },
	{ path: EDIT_ROLE_PATH, component: EditRole, canActivate: [AuthGuard] },
	{ path: EDIT_ROLE_PATH+"/:id", component: EditRole, canActivate: [AuthGuard] },
	{ path: USER_LIST_PATH, component: UserList, canActivate: [AuthGuard] },
	{ path: EDIT_USER_ROLES_PATH+"/:id", component: EditUserRoles, canActivate: [AuthGuard] }
];

@NgModule({
	imports: [
		CommonModule,
		ComponentsModule,
		RouterModule.forChild(SettingsRoutes),
		NgbModule,
		NgbTooltipModule,
		DirectiveModule
	],
	exports: [],
	declarations: [
		PermissionsList,
		EditPermission,
		RolesList,
		EditRole,
		UserList,
		EditUserRoles
	],
	providers: [],
})
export class SettingsModule { }
