import { APP_INITIALIZER, NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";

import { NgbDateParserFormatter, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AgmCoreModule } from "@agm/core";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NgxSpinnerModule } from 'ngx-spinner';

import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./apex/shared/shared.module";
import { AppComponent } from "./app.component";
import { ContentLayoutComponent } from "./apex/layouts/content/content-layout.component";
import { FullLayoutComponent } from "./apex/layouts/full/full-layout.component";

import { AuthGuard } from "./apex/shared/auth/auth-guard.service";
import { WINDOW_PROVIDERS } from './apex/shared/services/window.service';
import { ComponentsModule } from "./common/components/components.module";
import './common/extensions-methods/array.extensions'  //or whatever path is appropriate
import './common/extensions-methods/string.extensions'  //or whatever path is appropriate
import './common/extensions-methods/form.extensions'  //or whatever path is appropriate
import './common/extensions-methods/router.extensions'  //or whatever path is appropriate
import { CULTURE_CONFIGURATION, defaultCultureConfiguration } from "./common/services/culture.service";

import { CustomDateParserFormatter } from "./common/components/form/input-date.component";
import { CitiesService } from "./services/cities.service";
import { CommonServicesModule } from "./common/services/common-services.module";
import { SecurityService } from "./services/security.service";
import { of } from "rxjs";
import { OAuthModule } from "angular-oauth2-oidc";
import { ContentsProvider } from "./services/ContentsProvider.service";
import { HttpService } from "./services/http.service";
import { CategoriesProvider } from "./services/CategoriesProvider.service";
import { RolesAndPermissionsProvider } from "./services/RolesAndPermissionsProvider.service";
import { UsersProvider } from "./services/UserProvider.service";
import { FileProvider } from "./services/FileProvider.service";
import { SharingService } from "./services/sharing.service";
import { AccessManagement } from "./services/access-management.service";
import { DomainEntityProvider } from "./services/DomainEntityProvider.service";
import { NgJsonEditorModule } from 'ang-jsoneditor' 
import { ObjectSpaceManagementProvider } from "./services/object-space-management.service";

var firebaseConfig = {
    apiKey: "YOUR_API_KEY", //YOUR_API_KEY
    authDomain: "YOUR_AUTH_DOMAIN", //YOUR_AUTH_DOMAIN
    databaseURL: "YOUR_DATABASE_URL", //YOUR_DATABASE_URL
    projectId: "YOUR_PROJECT_ID", //YOUR_PROJECT_ID
    storageBucket: "YOUR_STORAGE_BUCKET", //YOUR_STORAGE_BUCKET
    messagingSenderId: "YOUR_MESSAGING_SENDER_ID", //YOUR_MESSAGING_SENDER_ID
    appId: "YOUR_APP_ID", //YOUR_APP_ID
    measurementId: "YOUR_MEASUREMENT_ID" //YOUR_MEASUREMENT_ID
};


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelPropagation: false
};

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

export function loadSecurityConfiguration(securityService: SecurityService) {
    return () => securityService.initialize().then((result: boolean) => {
        return of(result).toPromise();
    });
}

@NgModule({
    declarations: [AppComponent, FullLayoutComponent, ContentLayoutComponent],
    imports: [
        BrowserAnimationsModule,
        AppRoutingModule,
        SharedModule,
        HttpClientModule,
        AngularFireModule.initializeApp(firebaseConfig),
        AngularFireAuthModule,
        NgbModule,
        NgxSpinnerModule,
        ComponentsModule,
        CommonServicesModule.forRoot(),
        TranslateModule.forRoot({
            defaultLanguage: 'it',
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        AgmCoreModule.forRoot({
            apiKey: "YOUR_GOOGLE_MAP_API_KEY"
        }),
        PerfectScrollbarModule,
        OAuthModule.forRoot(),
		NgJsonEditorModule
    ],
    providers: [
        SecurityService,
		HttpService,
        AuthGuard,
        ,
        CitiesService,
		ContentsProvider,
		CategoriesProvider,
		RolesAndPermissionsProvider,
		UsersProvider,
		FileProvider,
		SharingService,
        { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
        { provide: CULTURE_CONFIGURATION, useValue: defaultCultureConfiguration, multi: false },
        { provide: APP_INITIALIZER, useFactory: loadSecurityConfiguration, deps: [SecurityService], multi: true },
        { provide: NgbDateParserFormatter, useFactory: () => { return new CustomDateParserFormatter() } },
        WINDOW_PROVIDERS,
		AccessManagement,
		DomainEntityProvider,
		ObjectSpaceManagementProvider
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
