import { DataTableStringCellTemplateComponent, DataTableDateCellTemplateComponent, DataTableDateTimeCellTemplateComponent, DataTableTimeCellTemplateComponent, DataTableBooleanCellTemplateComponent, DataTableIntCellTemplateComponent, DataTableNumberCellTemplateComponent, DataTableCurrencyCellTemplateComponent, DataTableEnumCellTemplateComponent, DataTableClickCellTemplateComponent, DataTableArrayCellTemplateComponent, DataTableImageCellTemplateComponent, DataTableUserAvatarCellTemplateComponent, DataTableIconImageCellTemplateComponent } from "../components/table/data-table-cell-template.component";
import { DataTableAction, DataTableColumn, DataTableColumnAlignment } from "../components/table/types";

// @dynamic
export class DataTableUtils {
    public static createAction(label: string, icon: string, funcToInvoke: (row: any) => void, enableFunc: (row: any) => boolean = null) {
        let ret = new DataTableAction()
        ret.icon = icon;
        ret.label = label;
        if (funcToInvoke) {
            ret.funcToInvoke = funcToInvoke;
        }
        if (enableFunc) {
            ret.enableFunc = enableFunc;
        }
        return ret;
    }
    public static createStringColumn = (name: string, label: string, sortable: boolean = true): DataTableColumn => {
        let ret = DataTableUtils.createDataTableColumn(name, label, sortable);
        ret.template = DataTableStringCellTemplateComponent;
        return ret;
    }
    public static createActionColumn = (name: string, label: string, sortable: boolean = true): DataTableColumn => {
        let ret = DataTableUtils.createDataTableColumn(name, label, sortable, DataTableColumnAlignment.CENTER);
        ret.template = DataTableDateCellTemplateComponent;
        return ret;
    }
    public static createDateColumn = (name: string, label: string, sortable: boolean = true): DataTableColumn => {
        let ret = DataTableUtils.createDataTableColumn(name, label, sortable, DataTableColumnAlignment.CENTER);
        ret.template = DataTableDateCellTemplateComponent;
        return ret;
    }
    public static createDateTimeColumn = (name: string, label: string, sortable: boolean = true): DataTableColumn => {
        let ret = DataTableUtils.createDataTableColumn(name, label, sortable, DataTableColumnAlignment.CENTER);
        ret.template = DataTableDateTimeCellTemplateComponent;
        return ret;
    }
    public static createTimeColumn = (name: string, label: string, sortable: boolean = true): DataTableColumn => {
        let ret = DataTableUtils.createDataTableColumn(name, label, sortable, DataTableColumnAlignment.CENTER);
        ret.template = DataTableTimeCellTemplateComponent;
        return ret;
    }
    public static createBooleanColumn = (name: string, label: string, sortable: boolean = true): DataTableColumn => {
        let ret = DataTableUtils.createDataTableColumn(name, label, sortable, DataTableColumnAlignment.CENTER);
        ret.template = DataTableBooleanCellTemplateComponent;
        return ret;
    }
    public static createIntColumn = (name: string, label: string, sortable: boolean = true): DataTableColumn => {
        let ret = DataTableUtils.createDataTableColumn(name, label, sortable, DataTableColumnAlignment.RIGHT);
        ret.template = DataTableIntCellTemplateComponent;
        return ret;
    }
    public static createNumberColumn = (name: string, label: string, numOfDecimals: number = null, sortable: boolean = true): DataTableColumn => {
        let ret = DataTableUtils.createDataTableColumn(name, label, sortable, DataTableColumnAlignment.RIGHT);
        ret.template = DataTableNumberCellTemplateComponent;
        ret.metadata.numOfDecimals = 0;
        return ret;
    }
    public static createCurrencyColumn = (name: string, label: string, sortable: boolean = true): DataTableColumn => {
        let ret = DataTableUtils.createDataTableColumn(name, label, sortable, DataTableColumnAlignment.RIGHT);
        ret.template = DataTableCurrencyCellTemplateComponent;
        return ret;
    }
    public static createEnumColumn = (name: string, label: string, enumName: string, sortable: boolean = true): DataTableColumn => {
        let ret = DataTableUtils.createDataTableColumn(name, label, sortable);
        ret.template = DataTableEnumCellTemplateComponent;
        ret.metadata.enumName = enumName;
        return ret;
    }

    public static createLinkableColumn = (name: string, label: string, clickAction: (row) => void, sortable: boolean = true): DataTableColumn => {
        let ret = DataTableUtils.createDataTableColumn(name, label, sortable);
        ret.template = DataTableClickCellTemplateComponent;
        ret.metadata.clickAction = clickAction;
        return ret;
    }
	public static createArrayColumn = (name: string, label: string, fieldName: string, sortable: boolean = true): DataTableColumn => {
        let ret = DataTableUtils.createDataTableColumn(name, label, sortable);
        ret.template = DataTableArrayCellTemplateComponent;
		ret.metadata.fieldName = fieldName;
        return ret;
    }
	
	public static createAvatarColumn = (name: string, label: string, fieldName: string, fieldSurname: string, sortable: boolean = true): DataTableColumn => {
        let ret = DataTableUtils.createDataTableColumn(name, label, sortable);
        ret.template = DataTableImageCellTemplateComponent;
		ret.metadata.fieldName = fieldName;
		ret.metadata.fieldSurname = fieldSurname;
        return ret;
    }

	public static createUserAvatarColumn = (name: string, label: string, fieldName: string, fieldSurname: string, sortable: boolean = true): DataTableColumn => {
        let ret = DataTableUtils.createDataTableColumn(name, label, sortable);
        ret.template = DataTableUserAvatarCellTemplateComponent;
		ret.metadata.fieldName = fieldName;
		ret.metadata.fieldSurname = fieldSurname;
        return ret;
    }
	

    private static createDataTableColumn = (name: string, label: string, sortable: boolean = true, alignment: DataTableColumnAlignment = DataTableColumnAlignment.LEFT): DataTableColumn => {
        let ret = new DataTableColumn();
        ret.label = label;
        ret.name = name;
        ret.sortable = sortable;
        ret.alignment = alignment;
        return ret;
    }

	public static createDataTableIconImageColumn = (name: string, label: string, sortable: boolean = true): DataTableColumn => {
        let ret = DataTableUtils.createDataTableColumn(name, label, sortable);
        ret.template = DataTableIconImageCellTemplateComponent;
        return ret;
    }
	
}