import { SearchCriteria } from "app/common/models/models";

export class City {
    public id: number;
    public name: string;
    public region: string;
}

export class CitiesSearchCriteria extends SearchCriteria {
    public name: string;
}