import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { JsonEditorOptions } from 'ang-jsoneditor';
import { FormFieldDescription } from 'app/common/models/forms';
import { ActionCommand, SelectListitem } from 'app/common/models/models';
import { CitiesService } from 'app/services/cities.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { iconEdit, iconPropertiesEdit, iconTrash } from "app/common/utils/icons.utils";


@Component({
    selector: 'form-page',
    templateUrl: './form.page.html'
})

export class FormPage {
	editorOptions;
	data = {test: "Hello"}
	getData($event) {
		console.log("$event", $event);
		
	}

    public form: FormGroup = null;
    public stringValue = null;
    public selectItems: SelectListitem[] = [];
    public initialItem: SelectListitem = null;
	public exampleCenter = [14.781447642043426, 41.130769884646625];  //coordinate del museo del sannio [long, lat]
	public _polygon: any = []

	remove = () => {
		console.log("remove");
	}
	updateContent = () => {
		console.log("updateContent");
		
	}
	editProperties = () => {
		console.log("editProperties");
		
	}
	contentsAction = [];
	protected createActions = (): void => {
        let button = new ActionCommand();
        button.funcToInvoke = this.remove;
        button.label = "common.deleteButtonLabel";
        button.icon = iconTrash;
		button.enablePermission="contents.write";
        this.contentsAction.push(button);
		
		button = new ActionCommand();
        button.funcToInvoke = this.updateContent;
        button.label = "common.updateButtonLabel";
        button.icon = iconEdit;
		button.enablePermission="contents.write";
		
        this.contentsAction.push(button);

		button = new ActionCommand();
        button.funcToInvoke = this.editProperties;
        button.label = "common.updatePropertiesLabel";
        button.icon = iconPropertiesEdit;
		button.enablePermission="contents.write";
        this.contentsAction.push(button);

	}

	localized: FormFieldDescription[] = [
		{
			defaultValue: null,
			fieldName: "name",
			label: "Nome del campo di testo",
			fieldType: 'inputstring',
			validators: [Validators.required]
		},
		{
			defaultValue: "Ciao a tutti",
			fieldName: "description",
			fieldType: "multiline",
			label: "Campo testo multirighe",
			validators: []
		},
		{
			defaultValue: "<b>Hello World</b>",
			fieldName: "htmlField",
			fieldType: "html",
			label: "Campo HTML",
			validators: [Validators.required]
		}
	];
    constructor(private _fb: FormBuilder, private _citiesService: CitiesService) {
		this.createActions();
		this.editorOptions = new JsonEditorOptions()
		this.editorOptions.modes = ['code', 'text', 'tree', 'view'];

		let arr: FormGroup = this._fb.group({
            language: [null, Validators.required],
            value: [null, Validators.required]
        });


        this.form = _fb.group({
			mediaId: [1, Validators.required],
			jsonField: [
				"{\"pippo\":\"pluto\"}"
			],
            text: [null, Validators.required],
            multiline: [null, Validators.required],
            dateNoUTC: ["2020-12-26T23:00:00.000Z", Validators.required],
            dateUTC: ["2020-12-26T23:00:00.000Z", Validators.required],
            int: [20, Validators.required],
            decimal: [20.2, Validators.required],
            vertical: [true],
            horizontal: [false],
            single: [null, Validators.required],
            multiple: [[], Validators.required],
            autocomplete: [1, Validators.required],
            quill: [null, Validators.required],
            tags: [["1", "2"]],
            localizedData: _fb.array([])
        });
        
        // this.form.patchValue({
            
        //         // localizedData:[]
        //         localizedData:[{language: "it", value: ""}]
        // })
        this.form.updateValueAndValidity();
        console.log(this.form);
        this.form.valueChanges.subscribe((change) => {
            console.log("change", change);
            
        })
        this._citiesService.getAll().subscribe(result => {
            this.selectItems = result.map(m => new SelectListitem(m.id, m.name));
            this.initialItem = this.selectItems[0];
        });

		this.examplePolygonCoords()

    }

    public getCities = (term: string): Observable<SelectListitem[]> => {
        return this._citiesService.getAll().pipe(map(result => {
            return result.map(m => new SelectListitem(m.id, m.name));
        }));
    }


	private examplePolygonCoords() {
		var c1 = [14.781447642043426, 41.130769884646625]
		this._polygon.push(c1);
		c1 = [14.781425474197626, 41.130739816046706]
		this._polygon.push(c1);
		c1 = [14.781484482794388, 41.13069132959179]
		this._polygon.push(c1);
		c1 = [14.78164876809219, 41.1308832549327]
		this._polygon.push(c1);
		c1 = [14.781767784182525, 41.13096304741808]
		this._polygon.push(c1);
		c1 = [14.781704081720111, 41.13101759445141]
		this._polygon.push(c1);
		c1 = [14.781596122810129, 41.13095345117605]
		this._polygon.push(c1);
		c1 = [14.78135405344863, 41.13117365932768]
		this._polygon.push(c1);
		c1 = [14.780936299395641, 41.1309191067195]
		this._polygon.push(c1);
		c1 = [14.781140147274387, 41.13073879802389]
		this._polygon.push(c1);
		c1 = [14.781236036248783, 41.13080092124336]
		this._polygon.push(c1);
		c1 = [14.781363783391921, 41.13069486506368]
		this._polygon.push(c1);
	}
	
    public save() {
        if (this.form.isValid()) {
            alert(JSON.stringify(this.form.getRawValue()));
        }
    }
}
