<base-page [title]="actionTitle | translate" [description]="'userMngt.users-roles.editUserRolesActionDescriptionLabel' | translate">
    <ng-container page-body>
        <div class="card">
			<div class="card-header">
				<div class="row">
					<div class="col-6">
						<span *ngIf="userRolesList" class="badge badge-success">{{userRolesList.length}}</span> {{'userMngt.users-roles.editRolesCardHeader' | translate}}
					</div>
					<div class="col-6 text-right" *ngIf="assignableRoles.length > 0">
						<div class="dropdown btn-group mr-1 mb-1" ngbDropdown>
							<button type="button" ngbDropdownToggle class="btn btn-primary">
								<i class="{{iconAdd}}"></i> {{'userMngt.users-roles.addNewRoleButtonLabel' | translate}}
								<span class="caret"></span>
							</button>
							<div ngbDropdownMenu class="dropdown-menu" role="menu">
								<a *ngFor="let item of assignableRoles" class="dropdown-item" (click)="addGrantedRole(item.id)" >{{item.name | translate}}</a>								
							</div>
						</div>
					</div>
				</div>
            </div>
            <div class="card-content">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
							<list-table [columns]="tableColumns" [manager]="dataTableManager" [actions]="tableActions">
							</list-table>
                        </div>
                    </div>
					<div class="row text-right">
						<div class="col-12" *ngIf="userRolesList">
							<button type="button" (click)="cancel()" class="btn btn-outline-secondary"><i class="{{iconCancel}}"></i> {{'common.cancelButtonLabel' | translate}}</button>
							&nbsp;&nbsp;
							<button type="button" (click)="save()" [enabled-by-permission]="'users.write'" [disabled]="!canSave()" class="btn btn-primary"><i class="{{iconSave}}"></i> {{'common.saveButtonLabel' | translate}}</button>
						</div>
					</div>
                </div>
				
            </div>
        </div>
    </ng-container>
</base-page>
