import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ActionCommand } from "app/common/models/models";
import { LocationReferenceType, QRCodeReference } from "app/models/object-space.model";
import { LocationReferencePreviewComponent } from "./location-reference-preview.component";


@Component({
	selector: 'qrcode-preview',
	templateUrl: './qrcode-reference-preview.component.html',
	styles: [`
		.my-content-card {
			display: flex;
			align-items: flex-start;
			flex-direction: row;
			justify-content: flex-start;
			align-content: flex-start;
		}
		.heigth-20 {
			height: 20% !important;
		}
		.heigth-80 {
			height: 80% !important;
		}
  `]
})



export class QRCodePreview extends LocationReferencePreviewComponent {
	
	constructor(_fb: FormBuilder) {
		super(_fb)
	}
	
	protected initForm = () => {
		this.form = this._fb.group({
			id: [null],
			type: [LocationReferenceType.qrcode],
			qrCode: [null, [Validators.required]]
		});

	}
}