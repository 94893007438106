import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from '@angular/router';
import { ComponentsModule } from 'app/common/components/components.module';
import { AuthGuard } from 'app/apex/shared/auth/auth-guard.service';
import { ContentsList } from '../contents/contents-list.page';
import { EditContent } from './edit-actions/edit-content.page';
import { DirectiveModule } from 'app/common/directives/directives.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ContentsDescriptiveList, ContentsMediaList, ContentsWebList } from "./contents-pages-list.page";
import { CONTENTS_LIST_PATH, EDIT_CONTENTS_PATH } from 'app/common/utils/routing-paths.utils';


export const ContentsRoutes: Routes = [
	// { path: CONTENTS_LIST_PATH, component: ContentsList, canActivate: [AuthGuard] },
	{ path: CONTENTS_LIST_PATH+"/descriptive", component: ContentsDescriptiveList, canActivate: [AuthGuard] },
	{ path: CONTENTS_LIST_PATH+"/media", component: ContentsMediaList, canActivate: [AuthGuard] },
	{ path: CONTENTS_LIST_PATH+"/web", component: ContentsWebList, canActivate: [AuthGuard] },

	{ path: EDIT_CONTENTS_PATH, component: EditContent, canActivate: [AuthGuard] },
	{ path: EDIT_CONTENTS_PATH+"/:id", component: EditContent, canActivate: [AuthGuard] }
];

@NgModule({
	imports: [
		CommonModule,
		ComponentsModule,
		RouterModule.forChild(ContentsRoutes),
		NgbModule,
		DirectiveModule
	],
	exports: [],
	declarations: [
		ContentsList,
		EditContent,
		ContentsDescriptiveList,
		ContentsMediaList,
		ContentsWebList

	],
	providers: [],
})
export class ContentsModule { }
