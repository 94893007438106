

<modal [title]="data.title | translate"  (onClose)="close()" headerBgColor="gradient-man-of-steel">
	<div body>
		<div *ngIf="loading" class="d-flex justify-content-center text-primary">
			<div class="spinner-border" role="status">
				<span class="sr-only">Loading...</span>
			</div>
		</div>
		<p *ngIf="!loading">{{data.message | translate}}</p>
		<div *ngIf="!loading"  class="row">
			<div class="col-12" *ngIf="items && items.length==0">
				nessun elemento trovato
			</div>
			<div class="table-responsive" *ngIf="items && items.length>0">
				<table class="table table-striped">
					<thead>
						<tr>
							<th>
								<div class="checkbox">
									<input type="checkbox" id="selectedAll" [(ngModel)]="selectedAll" (change)="selectAll()">
									<label for="selectedAll"><span></span></label>
								</div>
							</th>
							<th scope="col"  *ngFor="let column of columns;let i = index"
								[ngClass]="{'pointer':column.sortable}">
								{{column.label | translate}}
								<i class="fas " *ngIf="sortColumn == column.name" [ngClass]="{'fa-sort-down':sortAscending,'fa-sort-up':!sortAscending}"></i>
							</th>
							<!-- <th scope="col" (click)="changeSort(column)" *ngFor="let column of columns;let i = index"
								[ngClass]="{'pointer':column.sortable}">
								{{column.label | translate}}
								<i class="fas " *ngIf="sortColumn == column.name"
									[ngClass]="{'fa-sort-down':sortAscending,'fa-sort-up':!sortAscending}"></i>
							</th> -->
							<!-- <th *ngIf="actions.length>0" style="width: 150px;">
			
							</th> -->
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let item of items">
							<td>
								<div class="checkbox">
									<input type="checkbox" id="{{item.id}}" [(ngModel)]="item.selected" (change)="change(item)">
									<label for="{{item.id}}"><span></span></label>
								  </div>
							</td>
							<td *ngFor="let column of columns">
								<ng-container [dataTableCellTemplate]="column" [dataTableCellTemplateRowData]="item"></ng-container>
							</td>
							<!-- <td *ngIf="actions.length>0">
								<button
									class="btn-clear"
									*ngFor="let action of actions"
									[enabled-by-permission]="action.enablePermission"
									(click)="invokeAction($event, action, item)"
									[disabled]="!action.enableFunc(item)"
									class=" {{ (!action.enableFunc(item))?'element-disabled':'text-primary'}} cursor-pointer">
									<i class="{{action.icon}}" placement="top" [ngbTooltip]="action.label | translate"></i>
								</button>
								
							</td> -->
						</tr>
					</tbody>
				</table>
			</div>
			
			<!-- <div class="col-12">
				<list-table [columns]="tableColumns" [manager]="dataTableManager" [actions]="tableActions">
				</list-table>
	
			</div> -->
		</div>
	</div>
	
    <div class="text-right" footer>
       
		<div class="text-right" footer>
			<button type="button" (click)="close()" class="btn btn-outline-secondary"><i class="{{iconCancel}}"></i> {{'common.cancelButtonLabel' | translate}}</button>
			&nbsp;&nbsp;
			<button type="button" (click)="ok()" [enabled-by-permission]="'contents.write'" [disabled]="!selected" class="btn btn-primary"><i class="{{iconSave}}"></i> {{'common.confirmButtonLabel' | translate}}</button>
		</div>
    </div>
</modal>