import { Router } from "@angular/router";
import { SelectContentData } from "app/common/components/content-list/select-content-list.component";
import { InnerContentPropertiesData } from "app/common/components/form/contents-forms/inner-content-properties.component";
import { ActionCommand, ModalOptions } from "app/common/models/models";
import { ModalService } from "app/common/services/modal.service";
import { iconEdit, iconPropertiesEdit, iconTrash } from "app/common/utils/icons.utils";
import { EDIT_CONTENTS_PATH } from "app/common/utils/routing-paths.utils";
import { BaseContent, LinkedContent, LinkedContentsSet } from "app/models/contents.model";
import { SharingPermission } from "app/models/sharing.model";
import { LinkedContentProvider } from "app/services/LinkedContentsBaseProvider";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";


export class LinkedContentView extends LinkedContent {
	refLinkedContentSet: LinkedContentsSetView;
}
export class LinkedContentsSetView extends LinkedContentsSet {
	constructor(private entityId, private _linkedContentsProvider: LinkedContentProvider, id){
		super();
		this.id = id;		
		if (this.id) {
			let thisIstance: LinkedContentsSetView = this;
			this.linkedContents = this._linkedContentsProvider.getLinkedContents(this.entityId, this.id).pipe(
				map((results: LinkedContent[]) => {
					let toReturn: LinkedContentView[] = [];
					toReturn = results.map((lc) => {
						// console.log("this", thisIstance);
						
						return {
							content: lc.content,
							id: lc.id,
							properties: lc.properties,
							refLinkedContentSet: thisIstance
						}
					})
					console.log("toReturn:",toReturn);
					
					this._linkedContents = toReturn;
					this.loaded = true;
					this.loading = false;
					return toReturn;
				})
			)
		}
		else {
			this.linkedContents = of(this._linkedContents);
		}

	}
	loaded = false;
	loading = false;
	_linkedContents: LinkedContentView[] = [];
	linkedContents: Observable<LinkedContentView[]>;
}

export class BaseEditLinkedContents {
	constructor(protected _linkedContentsProvider: LinkedContentProvider, protected _router: Router, protected _modalService: ModalService) {
		
	}
	public contentsAction: ActionCommand[] = [];
	public initialValue: any = undefined;
	public entityId: any;

	public linkedContentsSet: LinkedContentsSetView[] = [];
	public activeId = 0;
	public _addMenuDef = []

	removeLinkedContentsSet($event, index) {
		$event.preventDefault();
		let callback = (data) => {
			if (data) {
				if (index == this.linkedContentsSet.length - 1)
					this.activeId = (this.activeId > 0) ? this.activeId - 1 : 0;
				else if (index == this.activeId)
					this.activeId = 0;
				else
					this.activeId = (this.activeId > 0) ? this.activeId - 1 : 0;
				this._linkedContentsProvider.deleteLinkedContentsSets(this.entityId, this.linkedContentsSet[index]).subscribe((result) => {
					this.linkedContentsSet.splice(index,1);
				})
			}
		}
		this._modalService.showConfirm("common.dialogs.alertRemoveItemTitle", "common.dialogs.alertRemoveItemMessage", {
			callback: callback,
			size: "md"
		});
	}

	addLinkedContentSet = (cType: string) => {
		console.log("addLinkedContentSet", cType);
		let ls: LinkedContentsSetView = new LinkedContentsSetView(this.entityId,this._linkedContentsProvider, undefined);
		ls.tag = cType;

		this._linkedContentsProvider.saveLinkedContentsSets(this.entityId, ls).subscribe((result) => {
			ls.id = result.id;
			this.linkedContentsSet.push(ls);
			this.activeId = this.linkedContentsSet.length - 1;
		})
	}

	get addMenuDef() {
		let result = [];
		this._addMenuDef.forEach((element) => {
			if (this.linkedContentsSet.findIndex((e) => {return e.tag == element.value}) < 0) {
				result.push(element);
			}
		})
		return result;
	}

	linkNewContent(tag: LinkedContentsSetView) {
		let callBack = (result: number[]) => {
			console.log("Eccomi ", result);
			if (result && result.length > 0) {
				result.forEach(id => {
					let newLinkedContent: LinkedContentView = new LinkedContentView();
					newLinkedContent.content = {id: id};
					newLinkedContent.properties= "";
					newLinkedContent.refLinkedContentSet = tag;
					this._linkedContentsProvider.saveLinkedContent(this.entityId,tag.id, newLinkedContent).subscribe((result) => {
						newLinkedContent.id = result.id;
						tag._linkedContents.push(newLinkedContent);
					})
					
				});

				// console.log(tag._linkedContents);
				
				
			}
		}
		let options = new ModalOptions();
		options.size = 'lg';
		options.callback = callBack;
		let data: SelectContentData = new SelectContentData();
		data.ids = [];
		if (this.initialValue && this.initialValue.id)
			data.ids.push(this.initialValue.id);
		tag._linkedContents.forEach(lc => {
			data.ids.push(lc.content.id);
		});
		data.title = "contents.selectModalTitle";
		data.message = "contents.selectModalText";

		this._modalService.showSelectContentList(data, options);
	}

	protected createActions = (): void => {
        let button = new ActionCommand();
        button.funcToInvoke = this.remove;
        button.label = "common.deleteButtonLabel";
        button.icon = iconTrash;
		button.enablePermission="contents.write";
        this.contentsAction.push(button);
		
		button = new ActionCommand();
        button.funcToInvoke = this.updateContent;
        button.label = "common.updateButtonLabel";
        button.icon = iconEdit;
		button.enablePermission="contents.write";
		button.enableFunc = (element: BaseContent) => {
			return (element.permissions.lastIndexOf(SharingPermission.WRITE) >= 0)
		}
        this.contentsAction.push(button);

		button = new ActionCommand();
        button.funcToInvoke = this.editProperties;
        button.label = "common.updatePropertiesLabel";
        button.icon = iconPropertiesEdit;
		button.enablePermission="contents.write";
        this.contentsAction.push(button);

	}
	
	private remove = (element: LinkedContentView) => {
		console.log("Element to remove:",element);
		
		let callback = (data) => {
			if (data) {
				this.linkedContentsSet.forEach(lcs => {
					if (lcs.tag == element.refLinkedContentSet.tag) {
						let index = lcs._linkedContents.findIndex((value) => {return value.content.id == element.content.id});
						console.log("index:" + index);
						if (index >= 0) {
							this._linkedContentsProvider.deleteLinkedContent(this.entityId, lcs.id, lcs._linkedContents[index]).subscribe((result) => {
								lcs._linkedContents.splice(index,1);
							})
						}
					}
				})
			}
		}
		this._modalService.showConfirm("common.dialogs.alertRemoveItemTitle", "common.dialogs.alertRemoveItemMessage", {
			callback: callback,
			size: "md"
		});
	}

	private updateContent = (element: LinkedContentView) => {
		const queryParams = {};
        queryParams['returnUrl'] = window.location.pathname + '?' + (new URLSearchParams(window.location.search)).toString();
        this._router.navigate([EDIT_CONTENTS_PATH, element.content.id], { queryParams: queryParams });
	}

	private editProperties = (element: LinkedContentView) => {
		console.log("element", element);
		
		let callBack = (properties) => {
			console.log("editProperties Eccomi ", properties);
			element.properties = properties;
			this._linkedContentsProvider.saveLinkedContent(this.entityId,element.refLinkedContentSet.id, element).subscribe((result) => {
				this.linkedContentsSet.forEach((lcs) => {
					if (lcs.id == element.refLinkedContentSet.id) {
						lcs._linkedContents.forEach((lc) => {
							if (lc.id == element.id) {
								console.log("torvato elemento da aggiornare");
								lc.properties = result.properties;
								
							}
						})
					}
				})
				console.log("Saved",this.linkedContentsSet);
			})
		}
		let options = new ModalOptions();
		options.size = 'lg';
		let data: InnerContentPropertiesData = {
			title: "contents.editPropertiesModalTitle",
			inputLabel: "contents.propertiesJSONFieldLabel",
			value: element.properties
		}
		options.callback = callBack;
		this._modalService.showJSONPropertiesEditor(data, options);
	}
}