
<div class="full-height full-width" *ngIf="contentValue">
	<div class="heigth-20">
		<div class="row">
			<div class="col">
				<div class="card-title truncated-text">
					{{(contentValue)? contentValue.name: ''}}
				</div>
			</div>
			<div class="col text-right">
				<button
					*ngFor="let action of actions"
					[enabled-by-permission]="action.enablePermission"
					(click)="invokeAction($event, action, contentValue)"
					[disabled]="!action.enableFunc(contentValue)"
					class="btn-clear {{ (!action.enableFunc(contentValue))?'element-disabled':'text-primary'}} cursor-pointer">
					<i class="{{action.icon}}" placement="top" [ngbTooltip]="action.label | translate"></i>
				</button>
			</div>
		</div>
	</div>
	<div class="heigth-80 my-content-card ">
		<div class="half-width full-height" style="background-image: url('{{imagePreview}}'); background-position: center; background-repeat: no-repeat; background-size: cover;">
			&nbsp;
		</div>
		<div class="half-width full-height truncated-text" style="padding-left: 5px; padding-right: 5px;" [innerHtml]="(contentValue)? contentValue.description: ''">
		</div>
	</div>
</div>
