import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectListitem } from 'app/common/models/models';
import { iconSave, iconCancel} from "app/common/utils/icons.utils"
import { CultureInfo, CultureService, CulturesKey } from 'app/common/services/culture.service';
import { CommonValidators } from 'app/common/components/form/validators/common.validator';
import { FormFieldDescription } from 'app/common/models/forms';
import { RolesAndPermissionsProvider } from 'app/services/RolesAndPermissionsProvider.service';
import { PERMISSIONS_LIST_PATH } from '../settings.module';

@Component({
    selector: 'edit-permission-page',
    templateUrl: './edit-permission.page.html'
})
export class EditPermission implements OnInit{
	public actionTitle: "userMngt.permissions.newPageTitle" | any;
	public actionDescription;
    public form: FormGroup = null;
    public stringValue = null;
    public selectItems: SelectListitem[] = [];
    public initialItem: SelectListitem = null;
	public iconSave = iconSave;
	public iconCancel = iconCancel;
	descriptionFieldsDesc: FormFieldDescription[] = [
		{
			defaultValue: null,
			fieldName: "description",
			label: "userMngt.permissions.descriptionColumnLabel",
			fieldType: 'inputstring',
			validators: [Validators.required]
		}
	];
    constructor(private _fb: FormBuilder, private _entityPovider: RolesAndPermissionsProvider, private _activatedRoute: ActivatedRoute, private _router: Router, private _cultureService: CultureService) {
        
    }

	ngOnInit() {
		let requiredCultures: CultureInfo[] = this._cultureService.getCultureInfo([CulturesKey.IT]);

        this._activatedRoute.params.subscribe(params => {
			let id = params['id'];
			if (id != undefined || id != null) {
				this._entityPovider.getPermission(id).subscribe((result) => {
				
					let localizedFields: any[] = [];
					if (result) {
						result.localizedDescriptions.forEach((description) => {
							let dataDef: any = {
								language: [null, Validators.required]
							};
							this.descriptionFieldsDesc.forEach((field) => {
								dataDef[""+field.fieldName] = [field.defaultValue]
								field.validators.forEach((validator) => {
									dataDef[""+field.fieldName].push(validator);
								})
							});
							localizedFields.push(this._fb.group(dataDef));
						})
					}
					let form = this._fb.group({
						id: [null],
						name: [{value: null, disabled: true}, Validators.required],
						localizedDescriptions: this._fb.array(localizedFields, [CommonValidators.localizedMinItemsValidator(1), CommonValidators.requiredCultures(requiredCultures)])
					});
					form.patchValue(result);
					this.actionTitle = result.name;
					this.actionDescription = "userMngt.permissions.updatePageTitle";
					form.updateValueAndValidity();
					this.form = form;
					this.form.valueChanges.subscribe((cha) => {
						console.log(this.form.getRawValue());
						
					})
				})
			}
			else {
				this.actionTitle = "userMngt.permissions.newPageTitle";
				this.form = this._fb.group({
					id: [null],
					name: [null, Validators.required],
					localizedDescriptions: this._fb.array([], [
						CommonValidators.localizedMinItemsValidator(1),
						CommonValidators.requiredCultures(requiredCultures)
					])
				});
				
			}
		 });
	}


	cancel() {
		this._activatedRoute.queryParams.subscribe(qParam => {
			if (qParam.returnUrl)
				this._router.navigateByUrl(qParam.returnUrl);
			else
				this._router.navigate([PERMISSIONS_LIST_PATH])
		})
	}
    public save() {
        if (this.form.isValid()) {
			this._entityPovider.saveOrUpdatePermission(this.form.getRawValue()).subscribe((permission) => {
				this._activatedRoute.queryParams.subscribe(qParam => {
					if (qParam.returnUrl)
						this._router.navigateByUrl(qParam.returnUrl)
					else
						this._router.navigate([PERMISSIONS_LIST_PATH])

				})
			})
        }
    }
}
