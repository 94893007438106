import { Component, forwardRef, Optional, Host, SkipSelf, Inject, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlContainer, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { getLocaleNumberSymbol, NumberSymbol } from '@angular/common';
import { EnumUtils } from 'app/common/utils/enum.utils';
import { ContentType, MediaContent, MediaContentType, WebContent } from 'app/models/contents.model';
import { SelectListitem } from 'app/common/models/models';
import { CultureInfo, CultureService, CulturesKey } from 'app/common/services/culture.service';
import { FormFieldDescription } from 'app/common/models/forms';
import { CommonValidators } from '../validators/common.validator';
import { BaseFormContent } from './base-form-content';

@Component({
    selector: 'form-link-content',
    templateUrl: './form-link-content.component.html'
})

export class FormLinkContent extends BaseFormContent implements OnInit {

	constructor(private _fb: FormBuilder, private _translateService: TranslateService, private _cultureService: CultureService) {
		super();
	}
	
	webContentFieldsDescription: FormFieldDescription[] = [
		{
			defaultValue: null,
			fieldName: "text",
			label: "contents.editWebContentInputFieldNamesLabel",
			fieldType: 'inputstring',
			validators: [Validators.required]
		}
	];

	public init(): FormGroup {
		let form: FormGroup;
		if (this.initialValue) {
			form = this._getFormOfContent(this.initialValue);
			form.patchValue(this.initialValue);
			form.updateValueAndValidity();
			return form;
		}
		else {
			return this._fb.group({
				id: [null],
				type: [ContentType.web],
				published: [false],
				webpage: [null, [Validators.required, CommonValidators.secureWebsiteFormatValidator()]],
				localizedNames: this._fb.array([], [
					CommonValidators.localizedMinItemsValidator(1),
					CommonValidators.requiredCultures(this._cultureService.getCultureInfo([CulturesKey.IT]))
				])
			});
		}
	}

	private _getFormOfContent(content: WebContent) {
		let form;
		if (content) {
			let localizedFields: any[] = [];
			let requiredCultures: CultureInfo[] = this._cultureService.getCultureInfo([CulturesKey.IT]);
		
			content.localizedNames.forEach((name) => {
				let dataDef: any = {
					language: [null, Validators.required]
				};
				this.webContentFieldsDescription.forEach((field) => {
					dataDef["" + field.fieldName] = [field.defaultValue]
					field.validators.forEach((validator) => {
						dataDef["" + field.fieldName].push(validator);
					})
				});
				localizedFields.push(this._fb.group(dataDef));
			})
				
			form = this._fb.group({
				id: [null],
				type: [ContentType.web],
				published: [false],
				webpage: [null, [Validators.required, CommonValidators.secureWebsiteFormatValidator()]],
				localizedNames: this._fb.array(localizedFields, [CommonValidators.localizedMinItemsValidator(1), CommonValidators.requiredCultures(requiredCultures)])
			});
		}
		return form;
	}
}