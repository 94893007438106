<base-page [title]="actionTitle | translate" [description]="actionDescription | translate">
    <ng-container page-body>
        <div class="card">
            <div class="card-header">
                {{'categories.editMacroareaFormTitle' | translate}}
            </div>
            <div class="card-content">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <form [formGroup]="form" *ngIf="form">
                                <div class="row">
                                    <div class="col-9">
                                        <localized-input-container
											[fields]="namesDescription"
											[formArray]="form.controls.localizedNames"
											[label]="'categories.editMacroareaComponentNamesLabel' | translate"></localized-input-container>

                                    </div>
									<div class="col-3">
										<input-file formControlName="imageId" [possibileMediaTypes]="possibileMediaTypes" label="File associato"></input-file>
									</div>
                                </div>
								<div class="row">
                                    <div class="col-12">
                                        <select-box
											formControlName="categories"
											label="categories.categoriesListLinkedToAreaFielLabel"
                                            [items]="selectItems"
											[multiple]="true">
                                        </select-box>
                                    </div>
                                </div>
								<div class="row text-right">
                                    <div class="col">
                                        <button type="button" (click)="cancel()" class="btn btn-outline-secondary"><i class="{{iconCancel}}"></i> {{'common.cancelButtonLabel' | translate}}</button>
										&nbsp;&nbsp;
                                        <button type="button" (click)="save()" [enabled-by-permission]="'macroareas.write'" [disabled]="!form.valid" class="btn btn-primary"><i class="{{iconSave}}"></i> {{'common.saveButtonLabel' | translate}}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</base-page>