import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseDataTablePage } from 'app/common/components/pages/base-data-table.page';
import { BaseListTablePage } from 'app/common/components/pages/base-list-table.page';
import { DataTableAction, DataTableColumn, IDataTableManager, ListTableManager, RemoteDataTableManager } from 'app/common/components/table/types';
import { SelectListitem } from 'app/common/models/models';
import { DataTableUtils } from 'app/common/utils/data-table.utils';
import { CitiesSearchCriteria, City } from 'app/models/city.models';
import { CitiesService } from 'app/services/cities.service';
import { Observable, of } from 'rxjs';



@Component({
    selector: 'list-page',
    templateUrl: './list.page.html'
})

export class ListPage extends BaseListTablePage<City> {
    public tableColumns: DataTableColumn[] = [];
    public tableActions: DataTableAction[] = [];
    public filters: Filters = new Filters();
    constructor(private _formBuilder: FormBuilder, _translateService: TranslateService, _activatedRoute: ActivatedRoute, private _router: Router, private _citiesService: CitiesService) {
        super(_activatedRoute, true);
        this.createColumms();
        this.createActions();
    }

    protected getDataTableManager = (): ListTableManager<City> => {
        return new ListTableManager(this._citiesService.getAll)
    }

    private createColumms = (): void => {
        let idColumn = DataTableUtils.createIntColumn("id", "ID", true);
        this.tableColumns.push(idColumn);

        let nameColumn = DataTableUtils.createStringColumn("name", "Name", true);
        this.tableColumns.push(nameColumn);

        let dateColumn = DataTableUtils.createStringColumn("region", "Region", true);
        this.tableColumns.push(dateColumn);
    }

    private createActions = (): void => {
        let button = new DataTableAction();
        button.funcToInvoke = this.edit;
        button.label = "label";
        button.icon = "icon";
        this.tableActions.push(button)

    }

    public edit = (row: City): void => {
        const queryParams = {};
        queryParams['returnUrl'] = this.getReturnUrl();
        alert(queryParams['returnUrl']);
        //this._router.navigate(['/tables/table-edit', row.id], { queryParams: queryParams });
    }
}


class Filters {
    public name: string = null;
}