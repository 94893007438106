import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectListitem } from 'app/common/models/models';
import { iconSave, iconCancel, iconAdd, iconTrash } from "app/common/utils/icons.utils"
import { USER_LIST_PATH } from '../settings.module';
import { UsersProvider } from 'app/services/UserProvider.service';
import { RolesAndPermissionsProvider } from 'app/services/RolesAndPermissionsProvider.service';
import { forkJoin, Observable, of } from 'rxjs';
import { Role } from 'app/models/roles';
import { BaseListTablePage } from 'app/common/components/pages/base-list-table.page';
import { DataTableUtils } from 'app/common/utils/data-table.utils';
import { DataTableAction, DataTableColumn, ListTableManager } from 'app/common/components/table/types';
import { ModalService } from 'app/common/services/modal.service';
import { User } from 'app/models/user.model';

@Component({
    selector: 'edit-user-roles',
    templateUrl: './edit-user-roles.page.html'
})
export class EditUserRoles extends BaseListTablePage<Role> {
	public tableColumns: DataTableColumn[] = [];
    public tableActions: DataTableAction[] = [];

	public actionTitle: string;
    public stringValue = null;
    public rolesList: Role[] = [];
    public userRolesList: Role[] = [];
	private user: User;
    public grantedEntitiesList: SelectListitem[] = [];

	public iconSave = iconSave;
	public iconCancel = iconCancel;
	public iconAdd = iconAdd;
	public iconTrash = iconTrash;

	public get assignableRoles(): Role[] {
		let result: Role[]= [];
		this.rolesList.forEach(element => {
			if (this.userRolesList.findIndex((r) => {return r.id == element.id}) < 0)
				result.push(element);
		});
		return result;
	}


    constructor(private _entityPovider: UsersProvider, private _activatedRoute: ActivatedRoute, private _router: Router, private _rolesProvider: RolesAndPermissionsProvider, private _modalService: ModalService) {
		super(_activatedRoute, true);
        this.createColumms();
        this.createActions();
	}

	private createColumms = (): void => {
		this.tableColumns.push(DataTableUtils.createStringColumn("name", "ruolo", true));
		this.tableColumns.push(DataTableUtils.createStringColumn("description","descrizione",true));
	}

	private createActions = (): void => {
		let button = new DataTableAction();
		button.funcToInvoke = this.removeGrantedRole;
		button.label = "userMngt.users-roles.removeRoleLabel";
		button.icon = iconTrash;
		button.enablePermission = "users.write";
		button.enableFunc = (row: Role) => {
			return true;
		}
		this.tableActions.push(button);
	}


	findData = (): Observable<Role[]> => {
		if (this.rolesList && this.rolesList.length > 0)
			return of(this.userRolesList);

		return new Observable((observer) => {
			this._activatedRoute.params.subscribe(params => {
				let id = params['id'];
				return forkJoin({
					roles: this._rolesProvider.getAllRolesAutocomplete(),
					user: this._entityPovider.getUser(id)
				}).subscribe((results) => {
					this.user =results.user;
					this.rolesList = results.roles;
					this.actionTitle = results.user.givenName + " " + results.user.surname;
					this.userRolesList = results.user.roles;
					observer.next(this.userRolesList);
					observer.complete();
				})
			});
		})
	}
	protected getDataTableManager = (): ListTableManager<Role> => {
        return new ListTableManager(this.findData);
    }

	canSave = () => {
		return this.userRolesList && this.userRolesList.length>0
	}
	cancel() {
		this._activatedRoute.queryParams.subscribe(qParam => {
			if (qParam.returnUrl)
				this._router.navigateByUrl(qParam.returnUrl);
			else
				this._router.navigate([USER_LIST_PATH])
		})
	}

	addGrantedRole(id) {
		this.rolesList.forEach((r) => {
			if (r.id == id) {
				this.userRolesList.push(r);
				this.dataTableManager.startReload();
			}
		})
	}

	removeGrantedRole = (row: Role) => {
		let newRoles: Role[] = []
		this.userRolesList.forEach((r) => {
			if (r.id != row.id) {
				newRoles.push(r);
			}
		})
		this.userRolesList = newRoles;
		this.dataTableManager.startReload();

	}

    public save() {
		if (this.canSave() && this.user) {
			let callback = (data) => {
				if (data) {
					this.user.roles = this.userRolesList;
					this._entityPovider.updateUser(this.user).subscribe((result) => {
						this.cancel();
					}
					)

				}
			}
			this._modalService.showConfirm("userMngt.users-roles.confirmUserRolesUpdateTitle", "userMngt.users-roles.confirmUserRolesUpdateMessage", {
				callback: callback,
				size: "md"
			});
		}
    }
}
