<base-page [title]="'categories.macroareaListPageTitle' | translate">
    <ng-container page-body>
		<div class="row">
			<div class="col-8">
				<div class="card">
					<div class="card-header">
						<div class="row">
							<div class="col-6">
								<span class="badge badge-success">{{numberOfAvailableItems}}</span> {{'categories.listMacroareaPageCardHeaderTitle' | translate}}
							</div>
							<div class="col-6 text-right">
								<button type="button" (click)="addItem()" [enabled-by-permission]="'macroareas.write'" class="btn btn-primary"><i class="{{iconAdd}}"></i> {{'categories.newMacroareaPageTitle' | translate}}</button>
							</div>
						</div>
					</div>
					<div class="card-content">
						<div class="card-body">
							<div class="row">
								<div class="col-12">
									<data-table [columns]="tableColumns" [manager]="dataTableManager" [actions]="tableActions">
									</data-table>
								</div>
							</div>
						</div>
						
					</div>
				</div>
			</div>
			<div class="col-4">
				<list-table-filter [filterFielsDescription]="filterDef" (requestFilterUpdate)="onFiltersUpdate($event)" [filterValue]="filters"></list-table-filter>
			</div>
		</div>
    </ng-container>
</base-page>