import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
    template: ''
})
export abstract class BaseFormContent {
	@Input() initialValue;
	@Output() generatedForm: EventEmitter<FormGroup> = new EventEmitter();
	@Output() onValidityChange: EventEmitter<boolean> = new EventEmitter();

	form: FormGroup = null;

	public ngOnInit() {
		this.form = this.init();
		this.onValidityChange.emit(this.form.valid);
		this.generatedForm.emit(this.form);
		this.form.statusChanges.subscribe((result) => {
			this.onValidityChange.emit(this.form.valid);
		})
	}

	abstract init();
}