<div class="table-responsive">
    <table class="table table-striped">
        <thead>
            <tr>
                <th scope="col" (click)="changeSort(column)" *ngFor="let column of columns;let i = index"
                    [style]="getAlignment(column, i)" [ngClass]="{'pointer':column.sortable}">
                    {{column.label | translate}}
                    <i class="fas " *ngIf="sortColumn == column.name"
                        [ngClass]="{'fa-sort-down':sortAscending,'fa-sort-up':!sortAscending}"></i>
                </th>
                <th *ngIf="actions.length>0" style="width: 150px;">

                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of items">
                <td *ngFor="let column of columns">
                    <ng-container [dataTableCellTemplate]="column" [dataTableCellTemplateRowData]="item"></ng-container>
                </td>
                <td *ngIf="actions.length>0">
					<button
						class="btn-clear"
						*ngFor="let action of actions"
						[enabled-by-permission]="action.enablePermission"
						(click)="invokeAction($event, action, item)"
						[disabled]="!action.enableFunc(item)"
						class=" {{ (!action.enableFunc(item))?'element-disabled':'text-primary'}} cursor-pointer">
						<i class="{{action.icon}}" placement="top" [ngbTooltip]="action.label | translate"></i>
					</button>
					
					  <!-- <div ngbDropdown>
                        <button class="btn btn-outline-primary" ngbDropdownToggle>{{'common.tableActions' |
                            translate}}</button>
                        <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
                            <button *ngFor="let action of actions" ngbDropdownItem (click)="action.funcToInvoke(item)"
                                [disabled]="!action.enableFunc(item)">{{action.label | translate}}</button>
                        </div>
                    </div> -->
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div class=" d-flex justify-content-between p-2">
    <ngb-pagination [collectionSize]="totalCount" [boundaryLinks]="true" [page]="pageIndex"
        (pageChange)="changePageIndex($event)" [pageSize]="pageSize">
    </ngb-pagination>

    <select class="custom-select" [value]="pageSize" (change)="changePageSize(+$event.target.value)">
        <option *ngFor="let allowedPageSize of allowedPageSizes" [value]="allowedPageSize">
            {{allowedPageSize}}
            {{'common.itemsPerPage' | translate}}</option>
    </select>
</div>