import { BaseLocalizedEntity, LocalizedName, LocalizedParagraph, MediaLocalizedItem } from "app/common/models/localization";
import { SearchCriteria } from "app/common/models/models";
import { SharingPermission } from "./sharing.model";

export enum ContentType {
	descriptive = "descriptive",
	media = "media",
	web = "web"
}

export enum MediaContentType {
	image = "image",
	video = "video",
	ar = "ar",
	documentation = "documentation"
}

export enum LinkedContetsTagType {
	LOGO = "enums.LinkedContetsTagType.LOGO",
	PREVIEW = "enums.LinkedContetsTagType.PREVIEW",
	GALLERY = "enums.LinkedContetsTagType.GALLERY",
	MAIN_CONTENT = "enums.LinkedContetsTagType.MAIN_CONTENT",
	LINKED_RESOURCES = "enums.LinkedContetsTagType.LINKED_RESOURCES",
	ADDITIONAL_INFO = "enums.LinkedContetsTagType.ADDITIONAL_INFO"
}

export enum LinkedContetsTagTypeForDomainEntity {
	LOGO = "enums.LinkedContetsTagTypeForDomainEntity.LOGO",
	PREVIEW = "enums.LinkedContetsTagTypeForDomainEntity.PREVIEW",
	GALLERY = "enums.LinkedContetsTagTypeForDomainEntity.GALLERY",
	MAIN_CONTENT = "enums.LinkedContetsTagTypeForDomainEntity.MAIN_CONTENT",
	LINKED_RESOURCES = "enums.LinkedContetsTagTypeForDomainEntity.LINKED_RESOURCES",
	ADDITIONAL_INFO = "enums.LinkedContetsTagTypeForDomainEntity.ADDITIONAL_INFO"
}

export class LinkedContentsSet {
	id: any;
	tag: string;
}

export class LinkedContent {
	id: any;
	content: {id: any};
	properties: any;
}


export class LinkedContents {
	tag: string;
	contents: any[] = [];
}


export abstract class BaseContent extends BaseLocalizedEntity {
	id: any;
	type: ContentType;
	name: string;
	published: boolean = false;
	linkedContents: LinkedContents[] = [];
	createdDate: string;
	createdBy: string;
	lastModifiedDate: string;
	lastModifiedBy: string;
	permissions: SharingPermission[] = [];

}

export class MediaContent extends BaseContent {
	constructor() {
		super();
		this.type = ContentType.media;
		this.mappingLocalizedField.push({fieldSrc:"mediaContentLocalizations", fieldDest: "name", localizedFieldName: "name"});
		this.mappingLocalizedField.push({fieldSrc:"mediaContentLocalizations", fieldDest: "description", localizedFieldName: "text"});
	}
	description: string;
	mediaContentLocalizations: MediaLocalizedItem[] = [];
	mediaType: MediaContentType;
	useSameMediaId: boolean = true;
	mediaId: any;
}

export class DescriptiveContent extends BaseContent {
	constructor() {
		super();
		this.type = ContentType.descriptive;
		this.mappingLocalizedField.push({fieldSrc:"localizedParagraphs", fieldDest: "name", localizedFieldName: "title"});
		this.mappingLocalizedField.push({fieldSrc:"localizedParagraphs", fieldDest: "description", localizedFieldName: "text"});

	}
	description: string;
	localizedParagraphs: LocalizedParagraph[] = [];
}

export class WebContent extends BaseContent {
	webpage: string;
	localizedNames: LocalizedName[] = [];
	public get description(): string { return this.webpage};	
	constructor() {
		super();
		this.type = ContentType.web;
		this.mappingLocalizedField.push({fieldSrc:"localizedNames", fieldDest: "name", localizedFieldName: "text"});

	}
}


export class ContentsSearchCriteria extends SearchCriteria {
	public published = "";
	public contentTypes = "";
}
