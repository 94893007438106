import moment from 'moment'
import { SelectListitem } from '../models/models'
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// @dynamic
export class EnumUtils {

    public static toSelectListitems = <T>(enumType: T, enumName: string, translateService: TranslateService): Observable<SelectListitem[]> => {
        
		let keys = [];
        for (let prop in enumType) {
			keys.push("enums." + enumName + "." + prop);
        }

		return translateService.get(keys).pipe(
			map((res) => {
				console.log(res);
				
				let ret: SelectListitem[]= [];
				for (let prop in enumType) {
					ret.push(new SelectListitem(prop, res["enums." + enumName + "." + prop]));
				}
				return ret;
			})
		)
		
       
    }

}