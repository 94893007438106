import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from 'app/common/services/modal.service';
import { SharingService } from 'app/services/sharing.service';
import { DomainEntityType } from 'app/models/domainentities.model';
import { DomainEntityProvider } from 'app/services/DomainEntityProvider.service';
import { EntitiesList } from './entities-list.page';


@Component({
    selector: 'entities-list',
    templateUrl: './entities-list.page.html'
})
export class PlaceList extends EntitiesList {
    
	constructor(_activatedRoute: ActivatedRoute, _router: Router, _entitiesProvider: DomainEntityProvider, _modalService: ModalService, _sharingService: SharingService) {
        super(DomainEntityType.PLACE, _activatedRoute, _router, _entitiesProvider, _modalService, _sharingService);
		console.log("PLACE...");
		
    }
	public pageTitle() {
		return 'domainEntity.place.listContentsPageTitle';
	}
	public cardHeaderTitle() {
		return 'domainEntity.place.listContentsPageCardHeaderTitle';
	}
	public newButtonLabel() {
		return 'domainEntity.place.addNewButtonLabel';
	}
}
