import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { environment } from "environments/environment";
import { BaseLocalizableEntity, OSSearchCriteria, LocationReference, LocationReferenceType, BeaconReference, GeoSpatialReference, QRCodeReference, CartesianReference, BaseSpace } from "app/models/object-space.model";
import { DomainEntityType } from "app/models/domainentities.model";


@Injectable()
export class ObjectSpaceManagementProvider {
	constructor(private _httpService: HttpService) {
	}
	
	private serializeSerachParameters = (searchCriteria: OSSearchCriteria): string => {
		let result = "";
		for (let prop in searchCriteria) {
			if (searchCriteria[prop]!=undefined && searchCriteria[prop] != null)
				result = result + prop + "="+searchCriteria[prop] + "&"; 
		}
		return result;
	}

	public searchLocalizableEntities = (searchCriteria: OSSearchCriteria, entityType: DomainEntityType): Observable<BaseLocalizableEntity[]> => {

		console.log("search searchCriteria", searchCriteria);
		let url = environment.services.objectSpaceManagementPath + 
		
		((entityType == DomainEntityType.CULTURAL_HERITAGE)? "/entities": "/spaces")
		
		+
			"?"+this.serializeSerachParameters(searchCriteria);
		return this._httpService.get<BaseLocalizableEntity[]>(url);
    }
	public saveOrUpdateLocalizableEntity = (entity: BaseLocalizableEntity, entityType: DomainEntityType): Observable<BaseLocalizableEntity> => {
		let url = environment.services.objectSpaceManagementPath + ((entityType == DomainEntityType.CULTURAL_HERITAGE)? "/entities": "/spaces");
		if (entity.id != undefined && entity.id != null) {
			url = url+"/"+entity.id;
			return this._httpService.put(url, entity,"json");
		}
		else {
			return this._httpService.post(url,entity,"json").pipe(
				map(result=> {
					entity.id = result.id;
					return result;
				})
			)
		}
	}

	public deleteLocalizableEntity =  (entity: BaseLocalizableEntity, entityType: DomainEntityType): Observable<BaseLocalizableEntity> => {
		let url = environment.services.objectSpaceManagementPath + ((entityType == DomainEntityType.CULTURAL_HERITAGE)? "/entities/": "/spaces/") +entity.id;
		return this._httpService.delete(url);
	}

	public getLocalizableEntityDetails =  (id: any, entityType: DomainEntityType): Observable<BaseLocalizableEntity> => {
		let url = environment.services.objectSpaceManagementPath + "/entities/"+id;
		return this._httpService.get(url);
	}





	public searchLocationReferences = (localizableEntity: BaseLocalizableEntity): Observable<LocationReference[]> => {
		let url = environment.services.objectSpaceManagementPath + "/entity/"+localizableEntity.id+"/locations";
		return this._httpService.get<LocationReference[]>(url).pipe(
			map((result) => {
				let toReturn = [];
				if (result) {
					result.forEach(element => {
						let obj;
						if (element.type == LocationReferenceType.beacon) obj = new BeaconReference();
						else if (element.type == LocationReferenceType.geo) obj = new GeoSpatialReference();
						else if (element.type == LocationReferenceType.qrcode) obj = new QRCodeReference();
						else if (element.type == LocationReferenceType.cartesian) obj = new CartesianReference();
						if (obj) {
							Object.assign(obj,element);
							toReturn.push(obj);
						}
					});
				}
				return toReturn;
			})
		);
	}
	public getLocationReference = (localizableEntity: BaseLocalizableEntity, locationRefId: any): Observable<LocationReference> => {
		let url = environment.services.objectSpaceManagementPath + "/entity/"+localizableEntity.id+"/locations/"+locationRefId;
		return this._httpService.get<LocationReference>(url).pipe(
			map((result) => {
				let toReturn;
				if (result) {
					let toReturn;
					if (result.type == LocationReferenceType.beacon) toReturn = new BeaconReference();
					else if (result.type == LocationReferenceType.geo) toReturn = new GeoSpatialReference();
					else if (result.type == LocationReferenceType.qrcode) toReturn = new QRCodeReference();
					else if (result.type == LocationReferenceType.cartesian) toReturn = new CartesianReference();
					if (toReturn) {
						Object.assign(toReturn,result);
					}
				}
				return toReturn;
			})
		);
	}
	public saveOrUpdateLocationReference = (localizableEntity: BaseLocalizableEntity, locationReference: LocationReference): Observable<LocationReference> => {
		let toCall;
		if (locationReference.id) {
			let url = environment.services.objectSpaceManagementPath + "/entity/"+localizableEntity.id+"/locations/"+locationReference.id;
			toCall = this._httpService.put(url,locationReference)
		}
		else {
			let url = environment.services.objectSpaceManagementPath + "/entity/"+localizableEntity.id+"/locations";
			toCall = this._httpService.post(url,locationReference)
		}
		return  toCall.pipe(
			map((result: LocationReference) => {
				if (result)
					locationReference.id = result.id;
				return result;
			})
		)

	}

	public removeLocationReference = (localizableEntity: BaseLocalizableEntity, locationReference: LocationReference): Observable<any> => {
		let url = environment.services.objectSpaceManagementPath + "/entity/"+localizableEntity.id+"/locations/"+locationReference.id;
		return this._httpService.delete(url);
	}


	public getContainedspaces = (localizableEntity: BaseLocalizableEntity): Observable<BaseSpace[]> => {
		let url = environment.services.objectSpaceManagementPath + "/spaces/"+localizableEntity.id+"/containedspaces";
		return this._httpService.get<BaseSpace[]>(url); 
	}

	public addSpace = (localizableEntity: BaseSpace, spaceToAdd: BaseSpace): Observable<any> => {
		let url = environment.services.objectSpaceManagementPath + "/spaces/"+localizableEntity.id+"/containedspaces/"+spaceToAdd.id;
		return this._httpService.put(url, {});
	}
	public removeSpace = (localizableEntity: BaseSpace, spaceToremove: BaseSpace): Observable<any> => {
		let url = environment.services.objectSpaceManagementPath + "/spaces/"+localizableEntity.id+"/containedspaces/"+spaceToremove.id;
		return this._httpService.delete(url);
	}
	public getContainedEntities = (localizableEntity: BaseLocalizableEntity): Observable<BaseLocalizableEntity[]> => {
		let url = environment.services.objectSpaceManagementPath + "/spaces/"+localizableEntity.id+"/hostedentities";
		return this._httpService.get<BaseSpace[]>(url); 
	}

	public addEntity = (localizableEntity: BaseSpace, spaceToAdd: BaseLocalizableEntity): Observable<any> => {
		let url = environment.services.objectSpaceManagementPath + "/spaces/"+localizableEntity.id+"/hostedentities/"+spaceToAdd.id;
		return this._httpService.put(url, {});
	}
	public removeEntity = (localizableEntity: BaseSpace, spaceToremove: BaseLocalizableEntity): Observable<any> => {
		let url = environment.services.objectSpaceManagementPath + "/spaces/"+localizableEntity.id+"/hostedentities/"+spaceToremove.id;
		return this._httpService.delete(url);
	}
}