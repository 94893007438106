import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentsProvider } from 'app/services/ContentsProvider.service';
import { ModalService } from 'app/common/services/modal.service';
import { SharingService } from 'app/services/sharing.service';
import { ContentsList, Filters } from './contents-list.page';
import { ContentsSearchCriteria, ContentType } from 'app/models/contents.model';

@Component({
    selector: 'contents-descriptive-list',
    templateUrl: './contents-list.page.html'
})
export class ContentsDescriptiveList extends ContentsList {
	public fileName = "assets/img/icons/descriptive-content.png";
    
    constructor(_activatedRoute: ActivatedRoute, _router: Router, _contentssProvider: ContentsProvider, _modalService: ModalService, _sharingService: SharingService) {
        super(_activatedRoute, _router,_contentssProvider, _modalService, _sharingService);
		this.filters = new Filters();
		this.filters.contentTypes = ContentType.descriptive.toUpperCase();
		
    }

	get title() {
		return "Le tue descrizioni";
	}
	get listContentsPageCardHeaderTitle() {
		return "descrizioni trovate";
	}

	protected setSearchCriteria = (criteria: ContentsSearchCriteria): void => {
		console.log("setSearchCriteria " + ContentType.descriptive.toUpperCase());
		criteria.contentTypes = ContentType.descriptive.toUpperCase();
    }
}


@Component({
    selector: 'contents-media-list',
    templateUrl: './contents-list.page.html'
})
export class ContentsMediaList extends ContentsList {
	public fileName = "assets/img/icons/file.png";
    
    constructor(_activatedRoute: ActivatedRoute, _router: Router, _contentssProvider: ContentsProvider, _modalService: ModalService, _sharingService: SharingService) {
        super(_activatedRoute, _router,_contentssProvider, _modalService, _sharingService);
		this.filters = new Filters();
		this.filters.contentTypes = ContentType.media.toUpperCase();
		
    }
	get title() {
		return "I tuoi file";
	}
	get listContentsPageCardHeaderTitle() {
		return "file tovati";
	}
	protected setSearchCriteria = (criteria: ContentsSearchCriteria): void => {
		console.log("setSearchCriteria " + ContentType.media.toUpperCase());
		criteria.contentTypes = ContentType.media.toUpperCase();
    }
}


@Component({
    selector: 'contents-web-list',
    templateUrl: './contents-list.page.html'
})
export class ContentsWebList extends ContentsList {
	public fileName = "assets/img/icons/globe.png";
    
    constructor(_activatedRoute: ActivatedRoute, _router: Router, _contentssProvider: ContentsProvider, _modalService: ModalService, _sharingService: SharingService) {
        super(_activatedRoute, _router,_contentssProvider, _modalService, _sharingService);
		this.filters = new Filters();
		this.filters.contentTypes = ContentType.web.toUpperCase();
		
    }
	get title() {
		return "I tuoi riferimenti";
	}
	get listContentsPageCardHeaderTitle() {
		return "link trovati";
	}
	protected setSearchCriteria = (criteria: ContentsSearchCriteria): void => {
		console.log("setSearchCriteria " + ContentType.web.toUpperCase());
		criteria.contentTypes = ContentType.web.toUpperCase();
    }
}