import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'app/common/services/toasts.service';
import { iconCancel, iconSave } from 'app/common/utils/icons.utils';
import { SharingService } from 'app/services/sharing.service';

export class InnerContentPropertiesData {
	value: any;
	title: string;
	inputLabel: string;
}
@Component({
    selector: 'inner-content-properties',
    templateUrl: 'inner-content-properties.component.html',
    changeDetection: ChangeDetectionStrategy.Default
})
export class InnerContentPropertiesModalComponent implements OnInit {
	iconSave = iconSave;
	iconCancel = iconCancel;
    public data: InnerContentPropertiesData;
	public form: FormGroup = null;
	get valid(): boolean {
		return this.form && this.form.valid;
	}
    constructor(public activeModal: NgbActiveModal, private _sharingService: SharingService, private _toastService: ToastService, private _fb: FormBuilder) {

    }

    ngOnInit(): void {
		this.form = this._fb.group({
			jsonField: [this.data.value]
        });
    }

	
    public close = (): void => {
		this.activeModal.close(this.data.value);
    }
    public ok = (): void => {
		if (this.valid) {
			this.activeModal.close(this.form.value.jsonField);	
		}
    }
}