import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectListitem } from 'app/common/models/models';
import { iconSave, iconCancel} from "app/common/utils/icons.utils"
import { CultureInfo, CultureService, CulturesKey } from 'app/common/services/culture.service';
import { CommonValidators } from 'app/common/components/form/validators/common.validator';
import { FormFieldDescription } from 'app/common/models/forms';
import { RolesAndPermissionsProvider } from 'app/services/RolesAndPermissionsProvider.service';
import { ROLES_LIST_PATH } from '../settings.module';

@Component({
    selector: 'edit-role-page',
    templateUrl: './edit-role.page.html'
})
export class EditRole implements OnInit{
	public actionTitle: "userMngt.roles.newPageTitle" | any;
	public actionDescription;
    public form: FormGroup = null;
    public stringValue = null;
    public selectItems: SelectListitem[] = [];
    public initialItem: SelectListitem = null;
	public iconSave = iconSave;
	public iconCancel = iconCancel;
	descriptionFieldsDesc: FormFieldDescription[] = [
		{
			defaultValue: null,
			fieldName: "text",
			label: "userMngt.roles.descriptionColumnLabel",
			fieldType: 'inputstring',
			validators: [Validators.required]
		},
		{
			defaultValue: null,
			fieldName: "id",
			label: "",
			fieldType: 'inputstring',
			validators: [],
			hidden: true
		}
	];
    constructor(private _fb: FormBuilder, private _entityPovider: RolesAndPermissionsProvider, private _activatedRoute: ActivatedRoute, private _router: Router, private _cultureService: CultureService) {
        
    }

	ngOnInit() {
		let requiredCultures: CultureInfo[] = this._cultureService.getCultureInfo([CulturesKey.IT]);

        this._activatedRoute.params.subscribe(params => {
			this._entityPovider.getAllPermissionsAutocomplete().subscribe(result => {
				this.selectItems = result.map(m => new SelectListitem(m.id, m.description));
			});
			let id = params['id'];
			if (id != undefined || id != null) {
				
				this._entityPovider.getRole(id).subscribe((result) => {
				
					let localizedFields: any[] = [];
					if (result) {
						result.localizedDescriptions.forEach((description) => {
							let dataDef: any = {
								language: [null, Validators.required]
							};
							this.descriptionFieldsDesc.forEach((field) => {
								dataDef[""+field.fieldName] = [field.defaultValue]
								field.validators.forEach((validator) => {
									dataDef[""+field.fieldName].push(validator);
								})
							});
							localizedFields.push(this._fb.group(dataDef));
						})
					}
					let form = this._fb.group({
						id: [null],
						name: [null, Validators.required],
						localizedDescriptions: this._fb.array(localizedFields, [CommonValidators.localizedMinItemsValidator(1), CommonValidators.requiredCultures(requiredCultures)]),
						permissions: [[], CommonValidators.arrayMinItemsValidator(1, "emptyArray")]
					});
					let patchValue : any= new Object();
					Object.assign(patchValue,result);
					patchValue.permissions = result.permissions.map(m => { return m.id});					
					form.patchValue(patchValue);
					this.actionTitle = result.name;
					this.actionDescription = "userMngt.roles.updatePageTitle";
					form.updateValueAndValidity();
					this.form = form;
				
				})
			}
			else {
				this.actionTitle = "userMngt.roles.newPageTitle";
				this.form = this._fb.group({
					id: [null],
					name: [null, Validators.required],
					localizedDescriptions: this._fb.array([], [
						CommonValidators.localizedMinItemsValidator(1),
						CommonValidators.requiredCultures(requiredCultures)
					]),
					permissions: [[], CommonValidators.arrayMinItemsValidator(1, "emptyArray")]
				});
				this.form.valueChanges.subscribe((cha) => {
					console.log(cha);
				})
			}
		 });
	}


	cancel() {
		this._activatedRoute.queryParams.subscribe(qParam => {
			if (qParam.returnUrl)
				this._router.navigateByUrl(qParam.returnUrl);
			else
				this._router.navigate([ROLES_LIST_PATH])
		})
	}

	

    public save() {
        if (this.form.isValid()) {
			let value: any = new Object();
			Object.assign(value,this.form.value);
			value.permissions = value.permissions.map(m => { return {id: m}});
			this._entityPovider.saveOrUpdateRole(value).subscribe((item) => {
				this._activatedRoute.queryParams.subscribe(qParam => {
					if (qParam.returnUrl)
						this._router.navigateByUrl(qParam.returnUrl)
					else
						this._router.navigate([ROLES_LIST_PATH])

				})
			})
        }
    }
}
