import { serializeNodes } from '@angular/compiler/src/i18n/digest';
import { Injectable } from '@angular/core';
import { SearchResult } from 'app/common/models/models';
import { CitiesSearchCriteria, City } from 'app/models/city.models';
import { Observable, of } from 'rxjs';
@Injectable()
export class CitiesService {
    private _cities: City[] = [];

    constructor() {
        this._cities.push(this.createCity(1, "Roma", "Lazio"));
        this._cities.push(this.createCity(2, "Napoli", "Campania"));
        this._cities.push(this.createCity(3, "Milano", "Lombardia"));
        this._cities.push(this.createCity(4, "Torino", "Piemonte"));
        this._cities.push(this.createCity(5, "Bologna", "Emilia Romagna"));
        this._cities.push(this.createCity(6, "Firenze", "Toscana"));
        this._cities.push(this.createCity(7, "Benevento", "Campania"));
        this._cities.push(this.createCity(8, "Palermo", "Sicilia"));
        this._cities.push(this.createCity(9, "Bari", "Puglia"));
        this._cities.push(this.createCity(10, "Catanzaro", "Calabria"));
        this._cities.push(this.createCity(11, "Genova", "Liguria"));
        this._cities.push(this.createCity(12, "Udine", "Friuli-Venezia Giulia"));
        this._cities.push(this.createCity(13, "Trento", "Trentino-Alto Adige"));
        this._cities.push(this.createCity(14, "Verona", "Veneto"));
        this._cities.push(this.createCity(15, "Brescia", "Lombardia"));
        this._cities.push(this.createCity(16, "Potenza", "Basilicata"));
        this._cities.push(this.createCity(17, "Cagliari", "Sardegna"));
        this._cities.push(this.createCity(18, "L'aquila", "Abruzzo"));
        this._cities.push(this.createCity(19, "Perugia", "Umbria"));
        this._cities.push(this.createCity(20, "Ancona", "Marche"));
        this._cities.push(this.createCity(21, "Aosta", "Val d'Aosta"));
    }

    private createCity = (id: number, name: string, region: string): City => {
        let ret = new City();
        ret.id = id;
        ret.name = name;
        ret.region = region;
        return ret;
    }

    public search = (searchCriteria: CitiesSearchCriteria): Observable<SearchResult<City>> => {
        let cities = [...this._cities];
        if (searchCriteria.field) {
            cities = searchCriteria.ascending ? cities.sortAsc(s => s[searchCriteria.field]) : cities.sortDesc(s => s[searchCriteria.field]);
        }
        else {
            cities = cities.sortAsc(s => s.name);
        }
        if (searchCriteria.name) {
            cities = cities.filter(f => f.name.toLowerCase().indexOf(searchCriteria.name.toLowerCase()) >= 0);
        }
        let startIndex = (searchCriteria.pageNumber - 1) * searchCriteria.itemsPerPage;
        let endIndex = startIndex + searchCriteria.itemsPerPage;
        let ret = new SearchResult<City>();
        ret.totalCount = cities.length;
        for (let i = startIndex; i < endIndex && i < cities.length; i++) {
            ret.items.push(cities[i]);
        }
        return of(ret);
    }

    public getAll = (): Observable<City[]> => {
        let cities = [...this._cities.sortAsc(s => s.name)];
        return of(cities);
    }


}

