import { Component, forwardRef, Optional, Host, SkipSelf, Inject, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlContainer, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { getLocaleNumberSymbol, NumberSymbol } from '@angular/common';
import { EnumUtils } from 'app/common/utils/enum.utils';
import { ContentType, DescriptiveContent, MediaContent, MediaContentType } from 'app/models/contents.model';
import { SelectListitem } from 'app/common/models/models';
import { CultureInfo, CultureService, CulturesKey } from 'app/common/services/culture.service';
import { FormFieldDescription } from 'app/common/models/forms';
import { CommonValidators } from '../validators/common.validator';
import { BaseFormContent } from './base-form-content';

@Component({
    selector: 'form-descriptive-content',
    templateUrl: './form-descriptive-content.component.html'
})

export class FormDescriptiveContent extends BaseFormContent implements OnInit{
	constructor(private _fb: FormBuilder, private _translateService: TranslateService, private _cultureService: CultureService) {
		super();
	}
	descriptiveContentFieldsDescription: FormFieldDescription[] = [
		{
			defaultValue: null,
			fieldName: "title",
			label: "contents.editContentInputFieldTitleLabel",
			fieldType: 'inputstring',
			validators: [Validators.required]
		},
		{
			defaultValue: null,
			fieldName: "text",
			label: "contents.editContentInputFieldTextLabel",
			fieldType: 'html',
			validators: [Validators.required]
		}
	];

	public init(): FormGroup {
		let form: FormGroup;
		if (this.initialValue) {
			form = this._getFormOfContent(this.initialValue);
			form.patchValue(this.initialValue);
			form.updateValueAndValidity();
		}
		else {
			form = this._fb.group({
				id: [null],
				type: [ContentType.descriptive],
				published: [false],
				localizedParagraphs: this._fb.array([], [
					CommonValidators.localizedMinItemsValidator(1),
					CommonValidators.requiredCultures(this._cultureService.getCultureInfo([CulturesKey.IT]))
				])
			});
		}
		return form;
	}

	private _getFormOfContent(content: DescriptiveContent) {
		let form;
		if (content) {
			let localizedFields: any[] = [];
			let requiredCultures: CultureInfo[] = this._cultureService.getCultureInfo([CulturesKey.IT]);
			content.localizedParagraphs.forEach((paragraph) => {
				let dataDef: any = {
					language: [null, Validators.required]
				};
				this.descriptiveContentFieldsDescription.forEach((field) => {
					dataDef["" + field.fieldName] = [field.defaultValue]
					field.validators.forEach((validator) => {
						dataDef["" + field.fieldName].push(validator);
					})
				});
				localizedFields.push(this._fb.group(dataDef));
			})

			form = this._fb.group({
				id: [null],
				published: [false],
				type: [ContentType.descriptive],
				localizedParagraphs: this._fb.array(localizedFields, [CommonValidators.localizedMinItemsValidator(1), CommonValidators.requiredCultures(requiredCultures)])
			});
		}
		return form;
	}










	// public isNew: boolean = true;
	// public actionDescription;



	// public form: FormGroup = null;
	// public stringValue = null;
	// public iconSave = iconSave;
	// public iconCancel = iconCancel;

	// public get actionTitle() {
	// 	switch (this.contentType) {
	// 		case ContentType.descriptive:
	// 			return (this.isNew)? "contents.newDescriptiveContentPageTitle": "contents.updateDescriptiveContentPageTitle";
	// 		case ContentType.web:
	// 			return (this.isNew)? "contents.newWebContentPageTitle": "contents.updateWebContentPageTitle";
	// 		case ContentType.media:
	// 			return (this.isNew)? "contents.newFileContentPageTitle": "contents.updateFileContentPageTitle";
	// 		default:
	// 			return "";
	// 	}
	// }
	// contentType: ContentType;

	// _useSameFile: boolean = true;
	// public get useSameMediaId() {
	// 	console.log(this._useSameFile);
		
	// 	return this._useSameFile
	// }
	// public set useSameMediaId(value) {
	// 	console.log("set " + value);
	// 	this._useSameFile = value;
	// }

	
	
	
	
	// getLocalizedImageValidator = (v) => {
	// 	if (this.form && this.form.value && this.form.value.useSameMediaId){
	// 		return [];
	// 	}
	// 	return Validators.required(v);
	// }
	// getCommonImageValidator = (v) => {
	// 	if (this.form && this.form.value && this.form.value.useSameMediaId){
	// 		return Validators.required(v);
	// 	}
	// 	return [];
	// }

	// public get mediaFileEnabled () {
	// 	console.log("NOT Hello");
							
	// 	if (this.form && this.form.value && this.form.value.useSameMediaId){
	// 		console.log("NOT this.form.value.useSameMediaId "+this.form.value.useSameMediaId);
	// 		return false ;

	// 	}
	// 	return true;
	// }

	// mediaContentFieldsDescriptionFile: FormFieldDescription[] = [
	// 	{
	// 		defaultValue: null,
	// 		fieldName: "name",
	// 		label: "contents.editMediaInputFieldNameLabel",
	// 		fieldType: 'inputstring',
	// 		validators: [Validators.required],
	// 		enabled: true
	// 	},
	// 	{
	// 		defaultValue: null,
	// 		fieldName: "description",
	// 		label: "contents.editMediaInputFieldDescriptionLabel",
	// 		fieldType: 'html',
	// 		validators: [],
	// 		enabled: true
	// 	},
	// 	{
	// 		defaultValue: null,
	// 		fieldName: "mediaId",
	// 		label: "contents.editMediaInputFieldMediaIdLabel",
	// 		fieldType: 'file',
	// 		validators: [Validators.required],
	// 		enabled: true
	// 	}
	// ];
	// mediaContentFieldsDescriptionNoFile: FormFieldDescription[] = [
	// 	{
	// 		defaultValue: null,
	// 		fieldName: "name",
	// 		label: "contents.editMediaInputFieldNameLabel",
	// 		fieldType: 'inputstring',
	// 		validators: [Validators.required],
	// 		enabled: true
	// 	},
	// 	{
	// 		defaultValue: null,
	// 		fieldName: "description",
	// 		label: "contents.editMediaInputFieldDescriptionLabel",
	// 		fieldType: 'html',
	// 		validators: [],
	// 		enabled: true
	// 	},
	// 	{
	// 		defaultValue: null,
	// 		fieldName: "mediaId",
	// 		label: "contents.editMediaInputFieldMediaIdLabel",
	// 		fieldType: 'file',
	// 		validators: [],
	// 		enabled: false
	// 	}
	// ];
	// private _activeMediaContentDescription;
	// get mediaContentFieldsDescription() {
	// 	return this._activeMediaContentDescription;
	// }
	// constructor(private _fb: FormBuilder, private _contentssProvider: ContentsProvider, private _activatedRoute: ActivatedRoute, private _router: Router, private _cultureService: CultureService, private _translateService: TranslateService) {
		
	// }

	// // get mediaContentTypesDef() {
	// // 	return EnumUtils.toSelectListitems(MediaContentType, "MediaContentType", this._translateService);
	// // }

	// ngOnInit() {

	// 	EnumUtils.toSelectListitems(MediaContentType, "MediaContentType", this._translateService).subscribe((result) => {
	// 		this.mediaContentTypesDef = result;
	// 	});
		
	// 	this._activatedRoute.params.subscribe(params => {
	// 		let id = params['id'];
	// 		if (id != undefined || id != null) {
	// 			this._contentssProvider.getContent(id).subscribe((result) => {
	// 				let form = this._getFormOfContent(result);
	// 				form.patchValue(result);
	// 				this.isNew = false;
	// 				this.actionDescription = "contents.defaultUpdateContentPageTitle";
	// 				form.updateValueAndValidity();
	// 				this.form = form;
	// 			})
	// 		}
	// 		else {
	// 			this._activatedRoute.queryParams.subscribe(qParam => {
	// 				this.contentType = qParam.contentType;
	// 				this.isNew = true;
	// 				if (this.contentType == ContentType.descriptive) {
	// 					this.form = this._fb.group({
	// 						id: [null],
	// 						type: [this.contentType],
	// 						localizedParagraphs: this._fb.array([], [
	// 							CommonValidators.localizedMinItemsValidator(1),
	// 							CommonValidators.requiredCultures(this._cultureService.getCultureInfo([CulturesKey.IT]))
	// 						])
	// 					});
	// 				}
	// 				else if (this.contentType == ContentType.web) {
	// 					this.form = this._fb.group({
	// 						id: [null],
	// 						type: [this.contentType],
	// 						webpage: [null, [Validators.required, CommonValidators.secureWebsiteFormatValidator()]],
	// 						localizedNames: this._fb.array([], [
	// 							CommonValidators.localizedMinItemsValidator(1),
	// 							CommonValidators.requiredCultures(this._cultureService.getCultureInfo([CulturesKey.IT]))
	// 						])
	// 					});
	// 				}
	// 				else if (this.contentType == ContentType.media) {
	// 					this._activeMediaContentDescription = this.mediaContentFieldsDescriptionNoFile;
	// 					this.form = this._fb.group({
	// 						id: [null],
	// 						type: [this.contentType],
	// 						useSameMediaId: [true],
	// 						mediaId: [null, Validators.required],
							
	// 						mediaType: [null, Validators.required],
	// 						mediaContentLocalizations: this._fb.array([], [
	// 							CommonValidators.localizedMinItemsValidator(1),
	// 							CommonValidators.requiredCultures(this._cultureService.getCultureInfo([CulturesKey.IT]))
	// 						])
	// 					});

	// 					this.form.get("mediaContentLocalizations").valueChanges.subscribe((value) => {
	// 						if (value.useSameMediaId) {
	// 							(<FormArray>this.form.get("mediaContentLocalizations")).controls.forEach(c => {
	// 								if (c.get("mediaId").value != value.mediaId)
	// 									c.get("mediaId").setValue(value.mediaId);
	// 							})
	// 						}
	// 					});

	// 					this.form.get("useSameMediaId").valueChanges.subscribe((useSameMediaId) => {
	// 						console.log(useSameMediaId);
	// 						if (useSameMediaId) {
	// 							this.form.get("mediaId").setValidators([Validators.required]);
	// 							(<FormArray>this.form.get("mediaContentLocalizations")).controls.forEach(c => {
	// 								c.get("mediaId").setValue(this.form.value.mediaId);
	// 							})
	// 							this._activeMediaContentDescription = this.mediaContentFieldsDescriptionNoFile;
	// 						}
	// 						else {
	// 							this.form.get("mediaId").setValidators([]);
	// 							this._activeMediaContentDescription = this.mediaContentFieldsDescriptionFile;
	// 						}
	// 					})
	// 				}
	// 			})
	// 		}
	// 	});
	// }

	// private _getFormOfContent(content: BaseContent) {
	// 	let form;
	// 	if (content) {
	// 		this.contentType = content.type;
	// 		let localizedFields: any[] = [];
	// 		let requiredCultures: CultureInfo[] = this._cultureService.getCultureInfo([CulturesKey.IT]);
	// 		if (content.type == ContentType.descriptive) {
	// 			
	// 		}
	// 		else if (content.type == ContentType.web) {

	// 		}
	// 		else if (content.type == ContentType.media) {
	// 			(<MediaContent>content).mediaContentLocalizations.forEach((name) => {
	// 				let dataDef: any = {
	// 					language: [null, Validators.required]
	// 				};
	// 				this.mediaContentFieldsDescription.forEach((field) => {
	// 					dataDef["" + field.fieldName] = [field.defaultValue]
	// 					field.validators.forEach((validator) => {
	// 						dataDef["" + field.fieldName].push(validator);
	// 					})
	// 				});
	// 				localizedFields.push(this._fb.group(dataDef));
	// 			})
				
	// 			form = this._fb.group({
	// 				id: [null],
	// 				type: [this.contentType],
	// 				useSameMediaId: [true],
	// 				mediaId: [null, Validators.required],
	// 				mediaType: [null, Validators.required],
	// 				mediaContentLocalizations: this._fb.array(localizedFields, [CommonValidators.localizedMinItemsValidator(1), CommonValidators.requiredCultures(requiredCultures)])
	// 			});
	// 		}
	// 	}
	// 	return form;
	// }

}