import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from '@angular/router';
import { ComponentsModule } from 'app/common/components/components.module';
import { AuthGuard } from 'app/apex/shared/auth/auth-guard.service';
import { DirectiveModule } from 'app/common/directives/directives.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EntitiesList } from './entities-list.page';
import { EditDomainEntity } from './edit-domain-entities/edit-domain-entity.page';
import { CulturalHeritagesList } from './cultural-heritage-list.page';
import { PlaceList } from './place-list.page';
import { CULTURAL_HERITAGES_LIST_PATH, EDIT_DOMAIN_ENTITIES_PATH, PLACE_LIST_PATH } from 'app/common/utils/routing-paths.utils';


export const DomainEntitiesRoutes: Routes = [
	{ path: CULTURAL_HERITAGES_LIST_PATH, component: CulturalHeritagesList, canActivate: [AuthGuard] },
	{ path: PLACE_LIST_PATH, component: PlaceList, canActivate: [AuthGuard] },
	{ path: EDIT_DOMAIN_ENTITIES_PATH, component: EditDomainEntity, canActivate: [AuthGuard] },
	{ path: EDIT_DOMAIN_ENTITIES_PATH+"/:entityType/edit", component: EditDomainEntity, canActivate: [AuthGuard] },
	{ path: EDIT_DOMAIN_ENTITIES_PATH+"/:entityType/edit/:id", component: EditDomainEntity, canActivate: [AuthGuard] },
];

@NgModule({
	imports: [
		CommonModule,
		ComponentsModule,
		RouterModule.forChild(DomainEntitiesRoutes),
		NgbModule,
		DirectiveModule
	],
	exports: [],
	declarations: [
		EntitiesList,
		EditDomainEntity,
		CulturalHeritagesList,
		PlaceList
	],
	providers: [],
})
export class DomainEntitiesModule { }
