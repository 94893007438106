<div class="full-height full-width" *ngIf="reference">
	<div class="heigth-20">
		<div class="row">
			<div class="col">
				<div class="card-title truncated-text">
					
				</div>
			</div>
			<div class="col text-right">
				<button
					*ngFor="let action of actions"
					[enabled-by-permission]="action.enablePermission"
					(click)="invokeAction($event, action)"
					[disabled]="!action.enableFunc(reference)"
					class="btn-clear {{ (!action.enableFunc(reference))?'element-disabled':'text-primary'}} cursor-pointer">
					<i class="{{action.icon}}" placement="top"></i>
				</button>
			</div>
		</div>
	</div>
	<div class="heigth-80 my-content-card ">
		<div class="full-height full-width">
			<form [formGroup]="form" *ngIf="form">
				<div class="row">
					<div class="col-12">
						<select-box formControlName="uuid" label="UUID"
							[items]="possibleUUIDs">
						</select-box>
					</div>
				</div>
				<div class="row">
					<div class="col-6">
						<input-int formControlName="major" label="major"></input-int>
					</div>
					<div class="col-6">
						<input-int formControlName="minor" label="minor"></input-int>
					</div>
				</div>
				
			</form>
		</div>
		
	</div>
</div>
