<base-page [title]="'Forms' | translate" [description]="'Componenti form' | translate">
	<ng-container page-body>
		<div class="card">
			<div class="card-header">
				Forms
			</div>
			<div class="card-content">
				<div class="card-body">
					<div class="row">
						<div class="col-12">
							<form [formGroup]="form" *ngIf="form">
								<div class="row">
									<div class="col-12">
										<!-- ciao -->
										<input-json formControlName="jsonField" label="jsonField"></input-json>
										<!-- <json-editor [options]="editorOptions" [data]="data"></json-editor> -->
									</div>
								</div>
								<div class="row">
									<div class="col-12">
										<input-file formControlName="mediaId" label="File associato"></input-file>
									</div>
								</div>
								<div class="row">
									<div class="col-12">
										<localized-input-container [fields]="localized"
											[formArray]="form.controls.localizedData"
											label="input multipli localizzati"></localized-input-container>

									</div>
								</div>
								<div class="row">
									<div class="col-12">
										<label>Componente Mappa</label>
											<div style="height: 200px; width: 200px;">
												<map-preview [center]="exampleCenter">
												</map-preview>
											</div>
									</div>
								</div>
								<div class="row">
									<div class="col-12">
										<input-string formControlName="text" label="text"></input-string>
									</div>
								</div>
								<div class="row">
									<div class="col-12">
										<input-string formControlName="multiline" label="multiline" [rows]="10">
										</input-string>
									</div>
								</div>
								<div class="row">
									<div class="col-6">
										<input-date formControlName="dateUTC" label="date"></input-date>
										<p>{{form.controls.dateUTC.value | date}}</p>
									</div>
									<div class="col-6">
										<input-date formControlName="dateNoUTC" label="date" [utc]="false"></input-date>
										<p>{{form.controls.dateNoUTC.value | date}}</p>
									</div>
								</div>
								<div class="row">
									<div class="col-12">
										<input-int formControlName="int" label="formPage.int"></input-int>
									</div>
								</div>
								<div class="row">
									<div class="col-12">
										<input-decimal formControlName="decimal" label="formPage.decimal">
										</input-decimal>
									</div>
								</div>
								<div class="row">
									<div class="col-6">
										<input-slide formControlName="horizontal" label="formPage.horizontal"
											[horizontal]="true">
										</input-slide>
									</div>
									<div class="col-6">
										<input-slide formControlName="vertical" label="formPage.vertical"
											[horizontal]="false">
										</input-slide>
									</div>
								</div>
								<div class="row">
									<div class="col-12">
										<select-box formControlName="single" label="formPage.singleSelect"
											[items]="selectItems">
										</select-box>
									</div>
								</div>
								<div class="row">
									<div class="col-12">
										<select-box formControlName="multiple" label="formPage.multipleSelect"
											[items]="selectItems" [multiple]="true">
										</select-box>
									</div>
								</div>
								<div class="row">
									<div class="col-12">
										<input-tags formControlName="tags" label="formPage.tags">
										</input-tags>
									</div>
								</div>
								<div class="row">
									<div class="col-12">
										<input-autocomplete formControlName="autocomplete" label="formPage.autocomplete"
											[initialItem]="initialItem" [loader]="getCities">
										</input-autocomplete>
									</div>
								</div>
								<div class="row">
									<div class="col-12">
										<input-html formControlName="quill" label="formPage.quill">
										</input-html>
									</div>
								</div>
								<div class="row text-right">
									<div class="col">
										<button type="button" (click)="save()" class="btn btn-primary">Salva</button>
									</div>
								</div>

							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</ng-container>
</base-page>