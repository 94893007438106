import { CultureInfo, CultureService } from "../services/culture.service";

export abstract class BaseLocalizedItem {
	public id: any;
	public language: string;
}

export class LocalizedDescription extends BaseLocalizedItem {
	text: string;
}
export class LocalizedName extends BaseLocalizedItem{
	public text: string;
}
export class LocalizedParagraph extends BaseLocalizedItem{
	public title: string;
	public text: string;
}
export class MediaLocalizedItem extends BaseLocalizedItem{
	public name: string;
	public text: string;
	public mediaId: any;
}

export class MappingDef {
	fieldSrc: string;
	fieldDest: string;
	localizedFieldName: string;
}
export abstract class BaseLocalizedEntity {
	protected mappingLocalizedField: MappingDef[] = [];

	public defineLanguage(culture: CultureInfo) {
		let result = "";
		let _default = "";
		let _last = "";
		this.mappingLocalizedField.forEach((mapping) => {

			if (this[mapping.fieldSrc]) {
				this[mapping.fieldSrc].forEach((l) => {
					if (l.language == CultureService.DEFAULT_LANGUAGE)
						_default = l[mapping.localizedFieldName];
					if (l.language == culture.code)
						result = l[mapping.localizedFieldName];
					_last = l[mapping.localizedFieldName];
				})
			}
			this[mapping.fieldDest] = result || _default || _last;
		})
		
	}
}