export class Identity {
    public name: string = null;
    public surname: string[] = [];
}



export class Settings {
    public static identity: Identity = new Identity();

}