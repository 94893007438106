<modal [iconClass]="iconShare" [title]="'Gestione condivisione di ' + data.name" (onClose)="close()" headerBgColor="bg-info">
	<div body class="row">
		<div class="col-12">
			<ul ngbNav #navContainer="ngbNav" [(activeId)]="activeId" class="nav-tabs">
				<li ngbNavItem="newPermission" *ngIf="updateEnabled">
					<a ngbNavLink>
						<p>
							<i [class]="iconUserAdd"></i> Nuova condivisione</p>
					</a>
					<ng-template ngbNavContent>
						<content-sharing-form
							[updateEnabled]="updateEnabled"
							[entityId]="data.contentId"
							[entityType]="data.entityType"
							(sharingRequest)="sharingRequest($event)"
							(onValidityChange)="onValidityChange($event)"
						></content-sharing-form>
					</ng-template>
				</li>
				<li ngbNavItem="permissionsList" *ngIf="sharingNumbers > 1">
					<a ngbNavLink>
						<p>
							<i [class]="iconMoreUsers"></i> Condiviso con {{sharingNumbers}} utenti</p>
						<!-- <p *ngIf="sharingNumbers <= 1">Non ancora condiviso</p> -->
					</a>
					<ng-template ngbNavContent>
						<content-sharing-form-user-update
							[entityId]="data.contentId"
							[entityType]="data.entityType"
							[updateEnabled]="updateEnabled"
							(sharingRequest)="sharingRequest($event)"
							(onValidityChange)="onValidityChange($event)"
						></content-sharing-form-user-update>
					</ng-template>
				</li>
			</ul>
			<div [ngbNavOutlet]="navContainer" class="mt-2"></div>


			
		</div>
	</div>
    <div class="text-right" footer>
		<button type="button" (click)="close()" class="btn btn-outline-secondary"><i class="{{iconCancel}}"></i> {{'common.cancelButtonLabel' | translate}}</button>
		<button type="button" (click)="ok()" [disabled]="!valid" class="btn btn-primary"><i class="{{iconSave}}"></i> {{'common.saveButtonLabel' | translate}}</button>
    </div>
</modal>

<!-- 
<div class="row">
    <div class="col-md-12 col-lg-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Select an active tab by id</h4>
        </div>
        <div class="card-body">
          <div class="card-block">
            <ngb-tabset #t="ngbTabset">
              <ngb-tab title="Simple" id="foo">
                <ng-template ngbTabContent><p>Lemon drops pastry chocolate. Jujubes sweet roll tootsie roll. Oat cake donut bonbon chocolate croissant candy candy brownie. Wafer jelly beans jelly ice cream caramels. Cookie bonbon lemon drops cheesecake brownie cake macaroon sweet. Toffee pie icing candy ice cream croissant caramels jelly.
                Muffin jelly gummies icing cheesecake chocolate cake. Sweet chupa chups croissant pudding sesame snaps soufflé. Marzipan cotton candy jujubes halvah cheesecake. Cupcake wafer gummies croissant candy brownie jelly. Sweet wafer chocolate halvah. Danish sweet roll cheesecake candy canes marzipan liquorice. Sugar plum halvah lemon drops donut sesame snaps lollipop oat cake.
                Cake dessert topping tiramisu jelly beans. Gummies oat cake sugar plum cookie carrot cake halvah powder sesame snaps. Bear claw gummi bears marzipan powder pudding lemon drops cotton candy sweet roll chupa chups. Marzipan tootsie roll tart lemon drops gummies. Cheesecake wafer carrot cake chocolate jelly beans candy liquorice fruitcake pastry. Gummi bears cheesecake macaroon jelly beans wafer cookie. Gingerbread chocolate bar icing. Candy canes donut icing macaroon cupcake. Ice cream ice cream cake pudding gummi bears.</p></ng-template>
              </ngb-tab>
              <ngb-tab id="bar">
                <ng-template ngbTabTitle><b>Fancy</b> title</ng-template>
                <ng-template ngbTabContent><p>Jelly-o cake chocolate bar. Ice cream gummi bears candy canes cookie toffee halvah marzipan. Gummies dessert powder biscuit sweet roll. Biscuit muffin jujubes. Tiramisu pie danish muffin oat cake. Apple pie cupcake apple pie cotton candy toffee biscuit pie. Lemon drops dessert marshmallow. Jelly-o soufflé muffin muffin. Tootsie roll carrot cake sugar plum brownie chocolate cake croissant cotton candy lemon drops toffee.
                Sweet roll cotton candy jelly-o tootsie roll candy canes liquorice tart cotton candy tiramisu. Powder sesame snaps chocolate liquorice pudding jelly cake pie. Lemon drops ice cream chocolate bar pudding dragée. Pastry cake cheesecake chocolate cake. Toffee bonbon fruitcake candy canes biscuit. Jelly chocolate cake danish tart jelly-o candy jelly powder. Brownie gummi bears brownie bear claw brownie bear claw croissant marshmallow. Pudding cake icing gummies pudding candy gingerbread macaroon. Chupa chups pastry tiramisu liquorice tiramisu marshmallow gummies brownie candy canes.</p></ng-template>
              </ngb-tab>
            </ngb-tabset>
            <p>
              <button class="btn btn-outline-primary" (click)="t.select('bar')">Selected tab with "bar" id</button>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div> -->