import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbDatepickerModule, NgbDropdownModule, NgbModule, NgbNavModule, NgbPaginationModule, NgbProgressbarModule, NgbToastModule, NgbTooltipModule, NgbTypeaheadModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { DirectiveModule } from "../directives/directives.module";
import { InputDateComponent } from "./form/input-date.component";
import { InputDecimalComponent } from "./form/input-decimal.component";
import { InputIntComponent } from "./form/input-int.component";
import { InputSlideComponent } from "./form/input-slide.component";
import { InputStringComponent } from "./form/input-string.component";
import { SelectBoxComponent } from "./form/select-box.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { InputAutocompleteComponent } from "./form/input-autocomplete.component";
import { QuillModule } from 'ngx-quill';
import { InputHtmlComponent } from "./form/input-html.component";
import { DataTableComponent } from "./table/data-table.component";
import { DataTableUserAvatarCellTemplateComponent, DataTableImageCellTemplateComponent, DataTableStringCellTemplateComponent, DataTableDateCellTemplateComponent, DataTableEnumCellTemplateComponent, DataTableBooleanCellTemplateComponent, DataTableDateTimeCellTemplateComponent, DataTableTimeCellTemplateComponent, DataTableIntCellTemplateComponent, DataTableNumberCellTemplateComponent, DataTableCurrencyCellTemplateComponent, DataTableClickCellTemplateComponent, DataTableArrayCellTemplateComponent, DataTableIconImageCellTemplateComponent } from "./table/data-table-cell-template.component";
import { DataTableCellTemplateDirective } from "./table/data-table-cell-template.directive";
import { ListTableComponent } from "./table/list-table.component";
import { ModalComponent } from "./modals/modal.component";
import { ConfirmModalComponent } from "./modals/confirm-modal.component";
import { ErrorsModalComponent } from "./modals/errors-modal.component";
import { ToastsComponent } from "./toast/toasts.component";
import { InputTagsComponent } from "./form/input-tags.component";
import { TagInputModule } from 'ngx-chips';
import { SpinnerComponent } from "./spinner/spinner.component";
import { BasePageComponent } from "./pages/base-page.component";
import { LocalizedInputContainer } from "./form/localized-input-container.component";
import { FileUploadModule } from 'ng2-file-upload';


import { ListTableFilter } from "./table/list-table-filter.component";
import { PipeModule } from "app/apex/shared/pipes/pipe.module";
import { InputFileComponent } from "./form/input-file.component";
import { FormMediaContent } from "./form/contents-forms/form-media-content.component";
import { FormLinkContent } from "./form/contents-forms/form-link-content.component";
import { FormDescriptiveContent } from "./form/contents-forms/form-descriptive-content.component";
import { ContentSharingForm } from "./content-sharing/content-sharing-form.component";
import { SharingModalComponent } from "./content-sharing/sharing-modal.component";
import { ContentSharingFormUserUpdate } from "./content-sharing/content-sharing-form-user-update.component";
import { FormContentPreview } from "./form/contents-forms/form-content-preview.component";
import { SelectContentListModalComponent } from "./content-list/select-content-list.component";
import { FileUploadComponent } from "./files/FIleUpload";
import { InputJSONComponent } from "./form/input-json.component";
import { NgJsonEditorModule } from "ang-jsoneditor";
import { InnerContentPropertiesModalComponent } from "./form/contents-forms/inner-content-properties.component";
import { SecuredImage } from "./files/secured-image.component";
import { MapPreviewComponent } from './map/map-preview/map-preview.component';
import { ObjectSpaceTabComponent } from "./object-space-tab/obj-space-tab.component";

import { QRCodeModule } from 'angularx-qrcode';
import { QRCodePreview } from "./object-space-tab/qrcode-reference-preview.component";
import { BeaconCodePreview } from "./object-space-tab/beacon-reference-preview.component";
import { SelectDomainEntityListModalComponent } from "./domain-entities/select-domain-entity-list.component";
import { MapEditorModal } from "./map/map-editor-modal/map-editor.component";
import { DomainEntityPreview } from "./domain-entities/domain-entity-preview.component";

@NgModule({
    declarations: [
        InputStringComponent,
        InputDateComponent,
        InputDecimalComponent,
        InputIntComponent,
        InputSlideComponent,
        SelectBoxComponent,
        DataTableComponent,
        ListTableComponent,
        InputAutocompleteComponent,
        InputHtmlComponent,
        InputTagsComponent,
        DataTableCellTemplateDirective,
        DataTableStringCellTemplateComponent,
        DataTableDateCellTemplateComponent,
        DataTableEnumCellTemplateComponent,
        DataTableBooleanCellTemplateComponent,
        DataTableDateTimeCellTemplateComponent,
        DataTableTimeCellTemplateComponent,
        DataTableIntCellTemplateComponent,
        DataTableNumberCellTemplateComponent,
        DataTableCurrencyCellTemplateComponent,
        DataTableClickCellTemplateComponent,
		DataTableArrayCellTemplateComponent,
		DataTableImageCellTemplateComponent,
		DataTableUserAvatarCellTemplateComponent,
		DataTableIconImageCellTemplateComponent,
        ModalComponent,
        ConfirmModalComponent,
        ErrorsModalComponent,
		SharingModalComponent,
        ToastsComponent,
        SpinnerComponent,
        BasePageComponent,
        LocalizedInputContainer,
		SelectContentListModalComponent,
		MapEditorModal,
		SelectDomainEntityListModalComponent,
		ListTableFilter,
		InputFileComponent,
		FormMediaContent,
		FormLinkContent,
		FormDescriptiveContent,
		ContentSharingForm,
		ContentSharingFormUserUpdate,
		FormContentPreview,
		FileUploadComponent,
		InputJSONComponent,
		InnerContentPropertiesModalComponent,
		SecuredImage,
		MapPreviewComponent,
		ObjectSpaceTabComponent,
		QRCodePreview,
		BeaconCodePreview,
		DomainEntityPreview
    ],
    imports: [
        TranslateModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbDatepickerModule,
        DirectiveModule,
        NgSelectModule,
        NgbTypeaheadModule,
        NgbPaginationModule,
        NgbDropdownModule,
        NgbToastModule,
        TagInputModule,
        QuillModule.forRoot(),
        NgbNavModule,
        NgbDropdownModule,
		FileUploadModule,
		NgbProgressbarModule,
		NgbTooltipModule,
		PipeModule,
		NgbModule,
		NgJsonEditorModule,
		QRCodeModule
    ],
    providers: [
    ],
    exports: [
        InputStringComponent,
        InputDateComponent,
        InputDecimalComponent,
        InputIntComponent,
        InputSlideComponent,
        FormsModule,
        ReactiveFormsModule,
        SelectBoxComponent,
        InputAutocompleteComponent,
        InputHtmlComponent,
        DataTableComponent,
        ListTableComponent,
        DataTableCellTemplateDirective,
        DataTableStringCellTemplateComponent,
        DataTableDateCellTemplateComponent,
        DataTableEnumCellTemplateComponent,
        DataTableBooleanCellTemplateComponent,
        DataTableDateTimeCellTemplateComponent,
        DataTableTimeCellTemplateComponent,
        DataTableIntCellTemplateComponent,
        DataTableNumberCellTemplateComponent,
        DataTableCurrencyCellTemplateComponent,
        DataTableClickCellTemplateComponent,
		DataTableArrayCellTemplateComponent,
		DataTableImageCellTemplateComponent,
		DataTableUserAvatarCellTemplateComponent,
		DataTableIconImageCellTemplateComponent,
        NgbDropdownModule,
        ModalComponent,
        ConfirmModalComponent,
        ErrorsModalComponent,
		SharingModalComponent,
        ToastsComponent,
        InputTagsComponent,
        SpinnerComponent,
        BasePageComponent,
        TranslateModule,
        LocalizedInputContainer,
		SelectContentListModalComponent,
		MapEditorModal,
		SelectDomainEntityListModalComponent,
		ListTableFilter,
		InputFileComponent,
		FormMediaContent,
		FormLinkContent,
		FormDescriptiveContent,
		ContentSharingForm,
		ContentSharingFormUserUpdate,
		FormContentPreview,
		MapPreviewComponent,
		FileUploadComponent,
		InputJSONComponent,
		InnerContentPropertiesModalComponent,
		SecuredImage,
		ObjectSpaceTabComponent,
		QRCodePreview,
		BeaconCodePreview,
		DomainEntityPreview
    ],
    entryComponents: [
        DataTableStringCellTemplateComponent,
        DataTableDateCellTemplateComponent,
        DataTableEnumCellTemplateComponent,
        DataTableBooleanCellTemplateComponent,
        DataTableDateTimeCellTemplateComponent,
        DataTableTimeCellTemplateComponent,
        DataTableIntCellTemplateComponent,
        DataTableNumberCellTemplateComponent,
        DataTableCurrencyCellTemplateComponent,
        DataTableClickCellTemplateComponent,
		DataTableArrayCellTemplateComponent,
		DataTableImageCellTemplateComponent,
		DataTableUserAvatarCellTemplateComponent,
		DataTableIconImageCellTemplateComponent,
        ConfirmModalComponent,
        ErrorsModalComponent,
		SharingModalComponent,
		SelectContentListModalComponent,
		MapEditorModal,
		SelectDomainEntityListModalComponent,
		ContentSharingForm,
		ContentSharingFormUserUpdate,
		InnerContentPropertiesModalComponent,
		SecuredImage
    ],
})
export class ComponentsModule { }
