import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { SecurityService } from 'app/services/security.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private securityService: SecurityService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!this.securityService.isAuthenticated) {
            this.securityService.startLogin();
            return false;
        }
        else {
            return true;
        }
    }
}
