<div class="row">
	<div class="col-md-12 col-lg-6">
		<div ng2FileDrop
			class="py-5 mb-3 text-center  drop-zone"
		
			[ngClass]="{'invalid-drop-zone': invalid, 'text-danger': invalid, 'text-primary': (!invalid), 'nv-file-over': hasBaseDropZoneOver}"
			(fileOver)="fileOverBase($event)"
			[uploader]="uploader"

			style="position:relative; background-repeat: no-repeat; background-size: contain; background-position: center;"
			[style.background-image]="'url(' + ((currentFile)? currentFile.imageUrl: '') + ')'"
			>
				<div *ngIf="!readonly && updateEnabled" class="container-div" (click)="clickOnDropzone($event)">
					<i class="font-large-2 icon-cloud-upload"></i>
					<div>
						<div class="font-small-2">trascina qui un file oppure clicca per selezionarlo</div>
						<input #fileInputElement type="file" ng2FileSelect [uploader]="uploader"  class="custom-file-input">
	
					</div>
				</div>
				<i *ngIf="!invalid" class="show-image-button font-medium-3 {{iconToShow}}" (click)="clickOnshowFile($event)"></i>
				<div *ngIf="item && item.isUploading" class="progress file-progress-bar" style="margin-bottom: 0;">
					<div class="progress-bar" role="progressbar"
						[ngStyle]="{ 'width': item?.progress + '%' }">
						{{item?.progress + '%'}}
					</div>
				</div>
		</div>
		
	</div>
	
</div>
<!-- <section id="file-upload">
	<div class="card">
		<div class="card-header">
			<h4>Angular2 File Upload</h4>
		</div>
		<div class="card-block">
			<div class="row">
				<div class="col-md-12 col-lg-6">
					<div ng2FileDrop [ngClass]="{'nv-file-over': hasBaseDropZoneOver}" (fileOver)="fileOverBase($event)"
						[uploader]="uploader"
						
						class="py-5 mb-3 text-center  grey my-drop-zone"
						style="position:relative; background-repeat: no-repeat; background-size: contain; background-position: center;"
						[style.background-image]="'url(' + ((currentFile)? currentFile.imageUrl: '') + ')'"
						>
							<i class="font-large-2 icon-cloud-upload"></i>
							<div>
								<div class="font-small-2" style="cursor: pointer;" (click)="clickOnDropzone($event)">trascina qui un file oppure clicca per selezionarlo</div>
								<input #fileInputElement type="file" ng2FileSelect [uploader]="uploader"  class="custom-file-input">
	
							</div>
							
							<div *ngIf="item && item.isUploading" class="progress file-progress-bar" style="margin-bottom: 0;">
								<div class="progress-bar" role="progressbar"
									[ngStyle]="{ 'width': item?.progress + '%' }">
									{{item?.progress + '%'}}
								</div>
							</div>
					</div>
					
				</div>
				
			</div>
			<div class="row">
				<div class="col-md-3">
					<h4>Select files</h4>
					<div>Multiple</div>
					<label class="custom-file mb-3">
						<input type="file" ng2FileSelect [uploader]="uploader" multiple class="custom-file-input">
						<span class="custom-file-control"></span>
					</label>

					<div>Single</div>
					<label class="custom-file">
						<input type="file" ng2FileSelect [uploader]="uploader" class="custom-file-input">
						<span class="custom-file-control"></span>
					</label>
				</div>

				<div class="col-md-9">
					<h4>Upload queue</h4>
					<p>Queue length: {{ uploader?.queue?.length }}</p>

					<table class="table">
						<thead>
							<tr>
								<th width="50%">Name</th>
								<th>Size</th>
								<th>Progress</th>
								<th>Status</th>
								<th>Actions</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let item of uploader.queue">
								<td><strong>{{ item?.file?.name }}</strong></td>
								<td *ngIf="uploader.isHTML5" nowrap>{{ item?.file?.size/1024/1024 | number:'.2' }} MB
								</td>
								<td *ngIf="uploader.isHTML5">
									ciao
									<div class="progress" style="margin-bottom: 0;">
										<div class="progress-bar" role="progressbar"
											[ngStyle]="{ 'width': item.progress + '%' }"></div>
									</div>
								</td>
								<td class="text-center">
									<span *ngIf="item.isSuccess"><i class="fa fa-ok"></i></span>
									<span *ngIf="item.isCancel"><i class="fa fa-ban"></i></span>
									<span *ngIf="item.isError"><i class="fa fa-remove"></i></span>
								</td>
								<td nowrap>
									<button type="button" class="btn btn-success btn-sm" (click)="item.upload()"
										[disabled]="item.isReady || item.isUploading || item.isSuccess">
										<span class="fa fa-upload"></span> Upload
									</button>
									<button type="button" class="btn btn-warning btn-sm" (click)="item.cancel()"
										[disabled]="!item.isUploading">
										<span class="fa fa-ban"></span> Cancel
									</button>
									<button type="button" class="btn btn-danger btn-sm" (click)="item.remove()">
										<span class="fa fa-trash"></span> Remove
									</button>
								</td>
							</tr>
						</tbody>
					</table>

					<div>
						<p>Queue progress: <ngb-progressbar type="primary" [value]="uploader.progress">
							</ngb-progressbar>
						</p>
						<button type="button" class="btn btn-success" (click)="uploader.uploadAll()"
							[disabled]="!uploader.getNotUploadedItems().length">
							<span class="fa fa-upload"></span> Upload all
						</button>
						<button type="button" class="btn btn-warning" (click)="uploader.cancelAll()"
							[disabled]="!uploader.isUploading">
							<span class="fa fa-ban"></span> Cancel all
						</button>
						<button type="button" class="btn btn-danger" (click)="uploader.clearQueue()"
							[disabled]="!uploader.queue.length">
							<span class="fa fa-trash"></span> Remove all
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</section> -->