import { Injectable } from "@angular/core"
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { RolesAndPermissionsProvider } from "./RolesAndPermissionsProvider.service";
import { SecurityService } from "./security.service"


@Injectable()
export class AccessManagement {
	constructor(private _securityService: SecurityService, private _rolesProvider: RolesAndPermissionsProvider) {}
	private loggedUserPermissions: string[];

	public getLoggedUserId = (): string => {
		if (this._securityService.isAuthenticated) {
			let claims = this._securityService.getIdentityClaims();
			console.log("decoded token:", claims);
			if (claims) {
				return claims["sub"];
			}
		}
		return undefined;
	}

	public getAllUserPermissionIds(): Observable<string[]> {
		if (this.loggedUserPermissions && this.loggedUserPermissions.length>0)
			return of(this.loggedUserPermissions);

		return this._rolesProvider.getAllPermissionsId().pipe(
			map((result) => {
				this.loggedUserPermissions = result;
				return result;
			})
		)
	}
	public userHasPermission(permissionId): Observable<boolean> {
		if (this.loggedUserPermissions)
			return of(this.loggedUserPermissions.lastIndexOf(permissionId) >= 0);

		return this._rolesProvider.getAllPermissionsId().pipe(
			map((result) => {
				this.loggedUserPermissions = result;
				return result.lastIndexOf(permissionId) >= 0;
			})
		)
	}
}