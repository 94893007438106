import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { ActionCommand, ModalOptions } from "app/common/models/models";
import { iconEdit, iconTrash, iconMap, iconQRCode, iconBeacon, iconSpaces, iconMonument } from "app/common/utils/icons.utils";
import { DomainEntityType } from "app/models/domainentities.model";
import { BaseLocalizableEntity, BaseSpace, BeaconReference, GeoSpatialReference, LocalizableEntity, LocationReferenceType, QRCodeReference } from "app/models/object-space.model";
import { ObjectSpaceManagementProvider } from "app/services/object-space-management.service";
import { forkJoin, Observable, of, timer } from "rxjs";
import { SelectDomainEntityData } from "../domain-entities/select-domain-entity-list.component";
import { ModalService } from "app/common/services/modal.service";
import { ActivatedRoute, Router } from '@angular/router';
import { CULTURAL_HERITAGES_LIST_PATH, EDIT_DOMAIN_ENTITIES_PATH } from "app/common/utils/routing-paths.utils";


@Component({
	selector: 'object-space-tab',
	templateUrl: './object-space-tab.component.html'
})
export class ObjectSpaceTabComponent implements OnInit, AfterViewInit{
	static TAB_MAP = 0;
	static TAB_BEACON = 1;
	static TAB_QRCODE = 2;
	static TAB_SPACES = 3;
	static TAB_REF_ELEMENT = 4;

	qrCodes: QRCodeReference[];
	beacons: BeaconReference[];
	geoReferences: GeoSpatialReference[];

	constructor(private _objectSpaceProvider: ObjectSpaceManagementProvider,protected _modalService: ModalService, private _router: Router ) {

	}
	
	activeTabId = ObjectSpaceTabComponent.TAB_MAP;

	@Input() domainEntityId;
	@Input() entityType: DomainEntityType;
	linkedLocalizableEntity: BaseLocalizableEntity;
	private entitySpaceId;
	private _isSpace = false;

	ngOnInit() {

	}
	ngAfterViewInit() {
		console.log("ngAfterViewInit");
		
		this.entitySpaceId = "content-service/domain-entity/"+this.entityType+"/"+this.domainEntityId;
		if (this.entityType == DomainEntityType.PLACE) {
			this._isSpace = true;
		}
		this._objectSpaceProvider.searchLocalizableEntities({entityId: this.entitySpaceId}, this.entityType).subscribe((result) => {
			if (result && result.length>0) {
				this.linkedLocalizableEntity = result[0];
				if (this.entityType == DomainEntityType.PLACE) {
					this.initTabSpace();
					this._isSpace = true;
				}
				this._objectSpaceProvider.searchLocationReferences(this.linkedLocalizableEntity).subscribe((result) => {
					if (result) {
						result.forEach(ref => {
							if (ref.type == LocationReferenceType.geo) {
								this.tabLocationReferences[ObjectSpaceTabComponent.TAB_MAP].references.push(ref);
							}
							else if (ref.type == LocationReferenceType.qrcode) {
								this.tabLocationReferences[ObjectSpaceTabComponent.TAB_QRCODE].references.push(ref);
							}
							else if (ref.type == LocationReferenceType.beacon) {
								this.tabLocationReferences[ObjectSpaceTabComponent.TAB_BEACON].references.push(ref);
							}
						})
					}
				})
			}
				
		})
		
	}
	
	public get isSpace() {
		return this._isSpace;
	}
	addNewElement = () => {
		console.log("Adding new element " + this.activeTabId);
		if (this.activeTabId == ObjectSpaceTabComponent.TAB_QRCODE) {
			this.tabLocationReferences[ObjectSpaceTabComponent.TAB_QRCODE].references.push(
				{
					id: null,
					type: LocationReferenceType.qrcode,
					qrCode: null
				}
			)
		}
		else if (this.activeTabId == ObjectSpaceTabComponent.TAB_BEACON) {
			console.log("adding beacon");
			
			this.tabLocationReferences[ObjectSpaceTabComponent.TAB_BEACON].references.push(
				{
					id: null,
					type: LocationReferenceType.beacon,
					qrCode: null
				}
			)
		}
		else if (this.activeTabId == ObjectSpaceTabComponent.TAB_MAP) {
			console.log("adding Georef");
			
			this.tabLocationReferences[ObjectSpaceTabComponent.TAB_MAP].references.push(
				{
					id: null,
					type: LocationReferenceType.geo,
					centerPoint: {coordinates: null},
					cenpolygonterPoint: {coordinates: null}
				}
			)
		}

		else if (this.activeTabId == ObjectSpaceTabComponent.TAB_REF_ELEMENT) {
			this.addNewDomainEntity(DomainEntityType.CULTURAL_HERITAGE)
		}
		else if (this.activeTabId == ObjectSpaceTabComponent.TAB_SPACES) {
			this.addNewDomainEntity(DomainEntityType.PLACE)
		}

	}

	private addNewDomainEntity = (entityType: DomainEntityType) => {
		let callBack = (result: any) => {

			console.log("Eccomi ", result);

			if (result && result.length > 0) {
				result.forEach(id => {
					let entitySpaceId = "content-service/domain-entity/"+entityType+"/"+id;
					this._objectSpaceProvider.searchLocalizableEntities({entityId: entitySpaceId}, entityType).subscribe((result) => {
						// if (result && result.length>0) {
						// 	this.tabSpaces[this.activeTabId]._references.push()
						// }
						console.log("result", result);
						if (result && result.length>0) {
							let tabIndex = (entityType==DomainEntityType.CULTURAL_HERITAGE)? 1:0;
							(<any>result[0]).realEntityId = result[0].entityId.substring(result[0].entityId.lastIndexOf("/")+1);
							this.tabSpaces[tabIndex]._references.push(result[0]);
							if (entityType==DomainEntityType.CULTURAL_HERITAGE) {
								this._objectSpaceProvider.addEntity((<BaseSpace>this.linkedLocalizableEntity),result[0]).subscribe((result) => {
									console.log("Aggiunto...");
									
								})
							}
							else {
								this._objectSpaceProvider.addSpace((<BaseSpace>this.linkedLocalizableEntity),<BaseSpace>result[0]).subscribe((result) => {
									console.log("Aggiunto...");
									
								})
							}

						}
					})
				});
			}

			// if (result && result.length > 0) {
			// 	result.forEach(id => {
			// 		let newLinkedContent: LinkedContentView = new LinkedContentView();
			// 		newLinkedContent.content = {id: id};
			// 		newLinkedContent.properties= "";
			// 		newLinkedContent.refLinkedContentSet = tag;
			// 		this._linkedContentsProvider.saveLinkedContent(this.entityId,tag.id, newLinkedContent).subscribe((result) => {
			// 			newLinkedContent.id = result.id;
			// 			tag._linkedContents.push(newLinkedContent);
			// 		})
					
			// 	});

			// 	// console.log(tag._linkedContents);
				
				
			// }
		}
		let options = new ModalOptions();
		options.size = 'lg';
		options.callback = callBack;
		let data: SelectDomainEntityData = new SelectDomainEntityData();
		data.ids = [];
		data.entityType = entityType;
		data.ids.push(this.domainEntityId);
		this.tabSpaces.forEach(tab=> {
			tab._references.forEach(element => {
				data.ids.push((<any>element).realEntityId);
			});
		})
		data.title = "domainEntity."+((entityType==DomainEntityType.CULTURAL_HERITAGE)?"ch":"place")+".selectModalTitle";
		data.message = "domainEntity."+((entityType==DomainEntityType.CULTURAL_HERITAGE)?"ch":"place")+".selectModalText";
		
		this._modalService.showSelectDomainEntityList(data, options);
	}

	public linkLocalizableEntity = () => {
		let entityToSave = new BaseLocalizableEntity();
		entityToSave.entityId = "content-service/domain-entity/"+this.entityType+"/"+this.domainEntityId;
		this._objectSpaceProvider.saveOrUpdateLocalizableEntity(entityToSave, this.entityType).subscribe((result) => {
			if (result) this.linkedLocalizableEntity = result;
		})
	}

	// references
	removeReference = (element: QRCodeReference | BeaconReference | GeoSpatialReference, index: number) => {
		if (element.id != undefined && element.id != null) {
			this._objectSpaceProvider.removeLocationReference(this.linkedLocalizableEntity,element).subscribe((result) => {
				console.log("result", result);
				this.tabLocationReferences[this.activeTabId].references.splice(index,1);
			})
		}
		else {
			this.tabLocationReferences[this.activeTabId].references.splice(index,1);
		}
	}
	updateReference = (element: QRCodeReference | BeaconReference | GeoSpatialReference, index: number) => {
		console.log("updateReference", element);
		
		this._objectSpaceProvider.saveOrUpdateLocationReference(this.linkedLocalizableEntity,element).subscribe((result) => {
			console.log("result", result);
			this.tabLocationReferences[this.activeTabId].references[index] = element;
		})
	}

	tabLocationReferences = [
		{
			id: ObjectSpaceTabComponent.TAB_MAP,
			title: "riferimenti geografici",
			icon: iconMap,
			referenceType: LocationReferenceType.geo,
			shouldAddReference: false,
			references: []
		},
		{
			id: ObjectSpaceTabComponent.TAB_BEACON,
			title: "Beacon associati",
			icon: iconBeacon,
			referenceType: LocationReferenceType.beacon,
			shouldAddReference: true,
			references: []
		},
		{
			id: ObjectSpaceTabComponent.TAB_QRCODE,
			title: "QRCode associati",
			icon: iconQRCode,
			referenceType: LocationReferenceType.qrcode,
			shouldAddReference: true,
			references: []
		}
	]

	getContainedSpaces = (): Observable<BaseSpace[]> => {
		return this._objectSpaceProvider.getContainedspaces(this.linkedLocalizableEntity);
	}
	getContainedEntities = (): Observable<BaseSpace[]> => {
		return this._objectSpaceProvider.getContainedspaces(this.linkedLocalizableEntity);
	}
	tabSpaces: TabSpaceReference[]= []


	protected getReturnUrl = (baseUrl: string = null): string => {
        const ret = baseUrl || window.location.pathname;
        const urlParams = new URLSearchParams(window.location.search);
        return ret + '?' + urlParams.toString();
    }
	//SPaces
	private removeSpace = (element: any) => {
		this._objectSpaceProvider.removeSpace(<BaseSpace>this.linkedLocalizableEntity,element.localizableEntity).subscribe((result)=> {
			this.tabSpaces[0]._references.forEach((element,index) => {
				if (element.entityId.endsWith(""+element.entityId))
					this.tabSpaces[0]._references.splice(index,1);
			});
		})
	
	}
	private updateSpace = (element: any) => {
		let queryParams: any = {};
        queryParams['returnUrl'] = this.getReturnUrl();
		this._router.routeReuseStrategy.shouldReuseRoute = () => false;
		this._router.onSameUrlNavigation = 'reload';
        this._router.navigate([EDIT_DOMAIN_ENTITIES_PATH, this.entityType, "edit", element.entityId], { queryParams: queryParams });
	}
	// hosted entities
	private removeHostedEntity = (element: any) => {
		this._objectSpaceProvider.removeEntity(<BaseSpace>this.linkedLocalizableEntity,element.localizableEntity).subscribe((result)=> {
			this.tabSpaces[1]._references.forEach((element,index) => {
				if (element.entityId.endsWith(""+element.entityId))
					this.tabSpaces[1]._references.splice(index,1);
			});
		})
	}
	private updateHostedEntity = (element: any) => {
		let queryParams: any = {};
        queryParams['returnUrl'] = this.getReturnUrl();
		this._router.routeReuseStrategy.shouldReuseRoute = () => false;
		this._router.onSameUrlNavigation = 'reload';
        this._router.navigate([EDIT_DOMAIN_ENTITIES_PATH, this.entityType, "edit", element.entityId], { queryParams: queryParams });
	}

	
	initTabSpace = () => {
		

		let tab = new TabSpaceReference(
			ObjectSpaceTabComponent.TAB_SPACES,
			"Composizione del luogo",
			iconSpaces,
			"space",
			true,
			this.linkedLocalizableEntity,
			[],
			this._objectSpaceProvider
		)
		this.tabSpaces.push(tab);
		

		let button = new ActionCommand();
        button.funcToInvoke = this.removeSpace;
        button.label = "common.deleteButtonLabel";
        button.icon = iconTrash;
		tab.actions.push(button);

		button = new ActionCommand();
        button.funcToInvoke = this.updateSpace;
        button.label = "common.updateButtonLabel";
        button.icon = iconEdit;
		tab.actions.push(button);

		tab = new TabSpaceReference(
			ObjectSpaceTabComponent.TAB_REF_ELEMENT,
			"beni culturali associati",
			iconMonument,
			"hosted_entities",
			true,
			this.linkedLocalizableEntity,
			[],
			this._objectSpaceProvider
		);
		this.tabSpaces.push(tab);
		
		button = new ActionCommand();
        button.funcToInvoke = this.removeHostedEntity;
        button.label = "common.deleteButtonLabel";
        button.icon = iconTrash;
		tab.actions.push(button);

		button = new ActionCommand();
        button.funcToInvoke = this.updateHostedEntity;
        button.label = "common.updateButtonLabel";
        button.icon = iconEdit;
		tab.actions.push(button);
	}
}

class TabSpaceReference {
	_references: BaseLocalizableEntity[] = [];

	constructor(
		public id,
		public title: string,
		public icon: string,
		public referenceType: "space" | "hosted_entities",
		public  shouldAddReference: boolean,
		public linkedLocalizableEntity: BaseLocalizableEntity,
		public actions: ActionCommand[],
		_objectSpaceProvider: ObjectSpaceManagementProvider) {
			if (this.referenceType == "space")
				_objectSpaceProvider.getContainedspaces(linkedLocalizableEntity).subscribe((result) => {
					this._references = result.map((element) => {
						(<any>element).realEntityId = element.entityId.substring(result[0].entityId.lastIndexOf("/")+1);
						return element;
					});
				})
			else
				_objectSpaceProvider.getContainedEntities(linkedLocalizableEntity).subscribe((result) => {
					this._references = result.map((element) => {
						(<any>element).realEntityId = element.entityId.substring(result[0].entityId.lastIndexOf("/")+1);
						return element;
					});
				})
	}

	get references(): Observable<BaseLocalizableEntity[]> {
		return of(this._references);
	};
}