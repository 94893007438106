import { Component } from '@angular/core';
import { BaseComponent } from 'app/common/components/base.component';
import { ModalSizes, NotificationMessage } from 'app/common/models/models';
import { EventBusService } from 'app/common/services/event-bus.service';
import { ModalService } from 'app/common/services/modal.service';
import { SpinnerService } from 'app/common/services/spinner.service';
import { ToastService } from 'app/common/services/toasts.service';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';


@Component({
    selector: 'dialogs-page',
    templateUrl: './dialogs.page.html'
})

export class DialogsPage extends BaseComponent {
    constructor(private _modalService: ModalService, private _toastService: ToastService, private _eventBusService: EventBusService, private _spinnerService: SpinnerService) {
        super()
        this._eventBusService.subscribe(NotificationMessage1.code, (message: NotificationMessage1) => {
            this._toastService.showSuccess(message.text)
        });
        this._eventBusService.subscribe(NotificationMessage2.code, (message: NotificationMessage2) => {
            this._toastService.showDanger(message.text)
        });
    }

    public openConfirm = (size: ModalSizes = 'sm'): void => {
        this._modalService.showConfirm("Titolo conferma", "Confrmi l'operazione", {
            callback: (data) => { alert(data) },
            size: size
        });
    }

    public openErrors = (): void => {
        this._modalService.showErrors(["Errore 1", "Errore 2"]);
    }

    public openSuccessToast = (): void => {
        this._toastService.showSuccess("Operazione completata con successo")
    }

    public openDangerToast = (): void => {
        this._toastService.showDanger("Operazione non completata. errore!")
    }

    public openInfoToast = (): void => {
        this._toastService.showInfo("Info operazione")
    }

    public sendMessage1 = (): void => {
        this._eventBusService.emit(new NotificationMessage1())
    }
    public sendMessage2 = (): void => {
        this._eventBusService.emit(new NotificationMessage2())
    }
    public showSpinner = (): void => {
        this._spinnerService.show();
        of(1).pipe(delay(112000)).subscribe(() => this._spinnerService.hide());
    }
}

class NotificationMessage1 extends NotificationMessage {
    public static code = "Message1";
    public text: string = "Messaggio tipo 1";
}
class NotificationMessage2 extends NotificationMessage {
    public static code = "Message2";
    public text: string = "Messaggio tipo 2";


}