import { SearchCriteria } from "../models/models";

export class BaseProvider {
	protected serializePagination(paginationCriteria: SearchCriteria): string {
		let result;
		result = "page=" + paginationCriteria.pageNumber + "&size=" + paginationCriteria.itemsPerPage;
		if (paginationCriteria.field)
			result += ("&sort=" + paginationCriteria.field + "," + ((paginationCriteria.ascending)? "ASC": "DESC"));
		return result;
	}
}