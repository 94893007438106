<modal [title]="data.title" (onClose)="close()" headerBgColor="bg-info">
	<div body class="row">
		<div class="col-12">
			<form [formGroup]="form" *ngIf="form">
				<div class="row">
					<div class="col-12">
						<input-json formControlName="jsonField" [label]="data.inputLabel"></input-json>
					</div>
				</div>
			</form>
		</div>
	</div>
    <div class="text-right" footer>
		<button type="button" (click)="close()" class="btn btn-outline-secondary"><i class="{{iconCancel}}"></i> {{'common.cancelButtonLabel' | translate}}</button>
		<button type="button" (click)="ok()" [disabled]="!valid" class="btn btn-primary"><i class="{{iconSave}}"></i> {{'common.saveButtonLabel' | translate}}</button>
    </div>
</modal>
