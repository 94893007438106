import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { FullLayoutComponent } from './apex/layouts/full/full-layout.component';
import { AuthGuard } from './apex/shared/auth/auth-guard.service';
import { CategoriesRoutes } from './pages/categories/categories.module';
import { ContentsRoutes } from './pages/contents/contents.module';
import { SettingsRoutes } from './pages/settings/settings.module';
import { SampleRoutes } from './pages/samples/sample.module';
import { DomainEntitiesRoutes } from './pages/entities/entities.module';
import { CULTURAL_HERITAGES_LIST_PATH } from './common/utils/routing-paths.utils';

const appRoutes: Routes = [
    {
        path: '',
        redirectTo: CULTURAL_HERITAGES_LIST_PATH,
        pathMatch: 'full',
    },
    { path: '', component: FullLayoutComponent, data: { title: 'full Views' }, children: SampleRoutes, canActivate: [AuthGuard] },
    { path: '', component: FullLayoutComponent, data: { title: 'Categories' }, children: CategoriesRoutes, canActivate: [AuthGuard] },
    { path: '', component: FullLayoutComponent, data: { title: 'Contents' }, children: ContentsRoutes, canActivate: [AuthGuard] },
    { path: '', component: FullLayoutComponent, data: { title: 'Entities' }, children: DomainEntitiesRoutes, canActivate: [AuthGuard] },
    { path: '', component: FullLayoutComponent, data: { title: 'Settings' }, children: SettingsRoutes, canActivate: [AuthGuard] },
    {
        path: '**',
        redirectTo: 'pages/error'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})

export class AppRoutingModule {
}
