<base-page [title]="actionTitle | translate" [description]="actionDescription | translate">
    <ng-container page-body>
		<div class="card" *ngIf="!form">
			<div class="card-content">
                <div class="card-body">
					<div class="spinner-border" role="status">
						<span class="sr-only">Loading...</span>
					</div>
				</div>
			</div>
			
		</div>
		<div class="card" *ngIf="form">
            <div class="card-header">
				<div class="row">
					<div class="col-6">
						{{'domainEntity.'+type+'.editEntityNameFormTitle' | translate}}
					</div>
					<div class="col-6 text-right">
						<button type="button" (click)="updatePublishState()" [enabled-by-permission]="'domainentities.write'" [disabled]="!form.valid" class="btn btn-primary">
							<i class="{{iconPublish}}"></i> {{((published)?'contents.unpublishButtonLabel':'contents.publishButtonLabel') | translate}}
						</button>
					</div>
				</div>
                
            </div>
            <div class="card-content">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <form [formGroup]="form" *ngIf="form">
                                <div class="row">
                                    <div class="col-12">
                                        <localized-input-container
											[fields]="namesDescription"
											
											[formArray]="form.controls.localizedNames"
											label="{{'domainEntity.'+type+'.editComponentNamesLabel' | translate}}"></localized-input-container>

                                    </div>
                                </div>
								<div class="row">
                                    <div class="col-12">
                                        <select-box
											formControlName="categories"
											label="domainEntity.{{type}}.categoriesListLinkedToDomainEntityFieldLabel"
                                            [items]="selectItems"
											[multiple]="true">
                                        </select-box>
                                    </div>
                                </div>
								<div class="row text-right">
                                    <div class="col">
                                        <button type="button" (click)="cancel()" class="btn btn-outline-secondary"><i class="{{iconCancel}}"></i> {{'common.cancelButtonLabel' | translate}}</button>
										&nbsp;&nbsp;
                                        <button type="button" (click)="save()" [enabled-by-permission]="'domainentities.write'" [disabled]="!form.valid" class="btn btn-primary"><i class="{{iconSave}}"></i> {{'common.saveButtonLabel' | translate}}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>



		<div class="card" *ngIf="form && showAdditionalContent">
            <div class="card-header">
				<div class="row">
					<div class="col-6">
						{{'Proprietà ed informazioni associate' | translate}}
					</div>
				</div>
                
            </div>
            <div class="card-content">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
							<div class="bordered">
								<ul ngbNav #navContainer="ngbNav" [(activeId)]="activeId" class="nav-tabs">
									<li *ngFor="let content of linkedContentsSet; let i=index" [ngbNavItem]="i">
										<a ngbNavLink>
											{{(content.tag) | translate}}
											<span class="{{deleteLinkedContentsIcon}} position-relative pl-2 font-weight-light" (click)="removeLinkedContentsSet($event, i)"></span>
										</a>
										<ng-template ngbNavContent >
											<div class="linked-contents-container">
												<div class="element-preview bordered" *ngFor="let linkedContent of content.linkedContents | async">
													<content-preview [actions]="contentsAction" [contentId]="linkedContent.content.id" [contentView]="linkedContent"></content-preview>
												</div>
												<div class="element-preview linked-contents-container">
													<button class="btn-clear font-large-2 text-secondary cursor-pointer" style="margin: inherit;" (click)="linkNewContent(content)">
														<i class="far fa-plus-square"></i>
													</button>
												</div>
											</div>
											
										</ng-template>
									</li>
									<li ngbDropdown class="nav-item"  #myDrop="ngbDropdown" *ngIf="addMenuDef.length > 0">
										<a href (click)="false" class="nav-link" ngbDropdownToggle><i class="ft-plus"></i></a>
										<div ngbDropdownMenu>
											<button *ngFor="let item of addMenuDef" ngbDropdownItem (click)="addLinkedContentSet(item.value)">
												{{item.label | translate}}
											</button>
											<div class="dropdown-divider"></div>
											<div class="row" style="padding: 10px 20px;">
												<div class="col-12">
													<label for="newLabel">nuova etichetta</label>
													<input #newLabel type="text" class="input" id="newLabel" class="form-control">
												</div>
											</div>
											<div class="row" style="padding: 10px 20px;">
												<div class="col-12 text-right">
													<button (click)="myDrop.close(); addLinkedContentSet(newLabel.value)" class="btn btn-primary btn-sm">
														aggiungi
													</button>
												</div>
											</div>
											

										</div>
									</li>
								</ul>
								<div [ngbNavOutlet]="navContainer" class="mt-2"></div>
							</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
		<div *ngIf="entityId">
			<object-space-tab [domainEntityId]="entityId" [entityType]="entityType"></object-space-tab>
		</div>
	</ng-container>
</base-page>