<base-page [title]="title | translate">
    <ng-container page-body>
        <div class="card">
			<div class="card-header">
				<div class="row">
					<div class="col-6">
						<span class="badge badge-success">{{numberOfAvailableItems}}</span> {{listContentsPageCardHeaderTitle | translate}}
					</div>
					<div class="col-6 text-right">
						<div class="dropdown btn-group mr-1 mb-1" ngbDropdown>
							<button type="button" class="btn btn-primary dropdown-toggle"  [enabled-by-permission]="'contents.write'" ngbDropdownToggle>
								<i class="{{iconAdd}}"></i> {{'contents.addNewContentButtonLabel' | translate}}
								<span class="caret"></span>
							</button>
							<div ngbDropdownMenu class="dropdown-menu" role="menu">
								<a *ngFor="let item of addMenuDef" class="dropdown-item" (click)="addEntity(item.value)" >{{item.label | translate}}</a>								
							</div>
						</div>
					</div>
				</div>
            </div>
            <div class="card-content">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <data-table [columns]="tableColumns" [manager]="dataTableManager" [actions]="tableActions">
                            </data-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

		
    </ng-container>
</base-page>