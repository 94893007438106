import { TemplateRef } from "@angular/core";
import { Injectable } from "@angular/core";
import { ComponentType } from '@angular/cdk/portal';
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { ModalConfirmData, ModalOptions } from "../models/models";
import { ConfirmModalComponent } from "../components/modals/confirm-modal.component";
import { ErrorsModalComponent } from "../components/modals/errors-modal.component";
import { ModalSharingContentData, SharingModalComponent } from "../components/content-sharing/sharing-modal.component";
import { ContentsList } from "app/pages/contents/contents-list.page";
import { SelectContentData, SelectContentListModalComponent } from "../components/content-list/select-content-list.component";
import { InnerContentPropertiesModalComponent } from "../components/form/contents-forms/inner-content-properties.component";
import { SelectDomainEntityData, SelectDomainEntityListModalComponent } from "../components/domain-entities/select-domain-entity-list.component";
import { MapEditorInputDataDef, MapEditorModal } from "../components/map/map-editor-modal/map-editor.component";

@Injectable()
export class ModalService {
    constructor(private _dialog: NgbModal) {
    }
    public showErrors = (errors: string[]): void => {
        this.show(ErrorsModalComponent, errors);
    }
    public showConfirm = (title: string, message: string, config: ModalOptions<boolean> = new ModalOptions<boolean>()): void => {
        let data = new ModalConfirmData(title, message);
        this.show(ConfirmModalComponent, data, config);
    }
	public showSelectContentList = (data: SelectContentData, config: ModalOptions<boolean> = new ModalOptions<boolean>()): void => {
        this.show(SelectContentListModalComponent, data, config);
    }

	public showEditOnMap = (data: MapEditorInputDataDef, config: ModalOptions<boolean> = new ModalOptions<boolean>()): void => {
		this.show(MapEditorModal, data, config);
	}

	public showSelectDomainEntityList = (data: SelectDomainEntityData, config: ModalOptions<boolean> = new ModalOptions<boolean>()) : void => {
		this.show(SelectDomainEntityListModalComponent, data, config)
	}

	public showSharingRequest = (contentId: any, entityType: "contents" | "domainentities", entityName: string, config: ModalOptions<boolean> = new ModalOptions<boolean>()): void => {
        let data = new ModalSharingContentData(contentId, entityType, entityName);
        this.show(SharingModalComponent, data, config);
	}
	public showJSONPropertiesEditor = (data: any, config: ModalOptions<boolean> = new ModalOptions<boolean>()): void => {
		this.show(InnerContentPropertiesModalComponent, data, config);
	}
    public show = <T, C>(dialogComponent: ComponentType<any> | TemplateRef<any>, data: C = null, config: ModalOptions<T> = new ModalOptions<T>()): void => {
        let modalOptions: NgbModalOptions = {
            size: config.size
        };

        let dialogRef = this._dialog.open(dialogComponent, modalOptions);
        dialogRef.componentInstance.data = data;
        let closeSubscription = dialogRef.closed.subscribe(result => {
            config.callback(result);
            closeSubscription.unsubscribe();
            dismissSubscription.unsubscribe();
        });
        let dismissSubscription = dialogRef.closed.subscribe(result => {
            config.callback(null);
            closeSubscription.unsubscribe();
            dismissSubscription.unsubscribe();
        });
    }
}
