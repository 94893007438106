import { Directive, Input, HostListener, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { AccessManagement } from 'app/services/access-management.service';


@Directive({
    selector: '[enabled-by-permission]'
})
export class HasUserPermission implements OnInit{
	@Input('enabled-by-permission') permission: string;
	private disabled;

	constructor(private el: ElementRef, private _accessManagement: AccessManagement, private renderer: Renderer2) {
	}

	ngOnInit() {
		if (this.permission) {
			this._accessManagement.userHasPermission(this.permission).subscribe((result) => {
				if (!result) {
					this.el.nativeElement.disabled = true;
					// this.renderer.addClass(this.el.nativeElement, "element-disabled");
					this.disabled = true;
				}
					
			})
		}
		
	}
    @HostListener('click', ['$event'])
    public onClick(event: MouseEvent) {
		if (this.disabled) {
			event.preventDefault();
			event.stopPropagation();
		}
    }
}