import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseDataTablePage } from 'app/common/components/pages/base-data-table.page';
import { DataTableAction, DataTableColumn, IDataTableManager, RemoteDataTableManager } from 'app/common/components/table/types';
import { DataTableUtils } from 'app/common/utils/data-table.utils';
import { iconEdit, iconTrash } from 'app/common/utils/icons.utils';
import { iconAdd } from "app/common/utils/icons.utils"
import { ModalService } from 'app/common/services/modal.service';
import { Role, RoleSearchCriteria } from 'app/models/roles';
import { EDIT_ROLE_PATH } from "./settings.module"
import { RolesAndPermissionsProvider } from 'app/services/RolesAndPermissionsProvider.service';


@Component({
	selector: 'roles-list',
	templateUrl: './roles-list.page.html'
})
export class RolesList extends BaseDataTablePage<Role, Filters> {
	public tableColumns: DataTableColumn[] = [];
	public tableActions: DataTableAction[] = [];
	public filters: Filters = new Filters();
	public iconAdd = iconAdd;
	constructor(_translateService: TranslateService, _activatedRoute: ActivatedRoute, private _router: Router, private _entityProvider: RolesAndPermissionsProvider, private _modalService: ModalService) {
		super(_activatedRoute, true);
		this.createColumms();
		this.createActions();
	}

	protected getDataTableManager = (searchCriteria: RoleSearchCriteria): IDataTableManager<Role> => {
		return new RemoteDataTableManager(this._entityProvider.searchRoles, this.setSearchCriteria, searchCriteria)
	}

	private createColumms = (): void => {
		let nameColumn = DataTableUtils.createStringColumn("name", "userMngt.roles.nameColumnLabel", true);
		this.tableColumns.push(nameColumn);

		let descriptionColumn = DataTableUtils.createStringColumn("description", "userMngt.roles.descriptionColumnLabel", false);
		this.tableColumns.push(descriptionColumn);
	}

	private createActions = (): void => {
		let button = new DataTableAction();
		button.funcToInvoke = this.delete;
		button.label = "common.deleteButtonLabel";
		button.enablePermission = "roles.write";
		button.icon = iconTrash;
		this.tableActions.push(button)

		button = new DataTableAction();
		button.funcToInvoke = this.edit;
		button.enablePermission = "roles.write";
		button.label = "common.updateButtonLabel";
		button.icon = iconEdit;
		this.tableActions.push(button)
	}

	public edit = (row: Role): void => {
		const queryParams = {};
		queryParams['returnUrl'] = this.getReturnUrl();
		console.log(queryParams['returnUrl']);
		this._router.navigate([EDIT_ROLE_PATH, row.id], { queryParams: queryParams });
	}
	public delete = (row: Role): void => {
		let callback = (data) => {
			if (data) {
				this._entityProvider.deleteRole(row).subscribe((result) => {
					this.dataTableManager.startSearch();
				})
			}
		}
		this._modalService.showConfirm("common.dialogs.alertRemoveItemTitle", "common.dialogs.alertRemoveItemMessage", {
			callback: callback,
			size: "md"
		});
	}
	
	public addEntity = () => {
		const queryParams = {};
		queryParams['returnUrl'] = this.getReturnUrl();
		console.log(queryParams['returnUrl']);
		this._router.navigate([EDIT_ROLE_PATH], { queryParams: queryParams });

	}

	protected setSearchCriteria = (criteria: RoleSearchCriteria): void => {
		if (criteria.field == "description")
			criteria.field = "localizedDescriptions.description";
	}

	public search = (): void => {
		this.dataTableManager.startSearch();
	}
}


class Filters {
	public name: string = "";
}