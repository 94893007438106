// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    security: {
        issuer: "https://odfheritagedev.b2clogin.com/b8a6805a-ff0c-4e51-8fbe-becafb18ad39/v2.0/",
        discoveryDocumentUrl: "https://odfheritagedev.b2clogin.com/odfheritagedev.onmicrosoft.com/B2C_1A_SignUpOrSignIn/v2.0/.well-known/openid-configuration"
    },
	services: {
		// categoryServiceBasePath: "http://91.121.169.44:18090/api/v1/bo/categories",
		// contentsServiceBasePath: "http://91.121.169.44:18090/api/v1/bo/contents",
		// macroareaServiceBasePath: "http://91.121.169.44:18090/api/v1/bo/macroareas",
		// domainEntityServiceBasePath: "http://91.121.169.44:18090/api/v1/bo/domainentities",
		// appSettingsBasePath: "http://91.121.169.44:18091/api/v1/bo",
		// baseUserServicesBasePath: "http://91.121.169.44:18091/api/v1/users",
		// mediaContentsBasePath: "http://91.121.169.44:18092/api/v1/files",
		// sharingServiceBasePath: "http://91.121.169.44:18090/api/v1/bo/{{entityType}}"

		categoryServiceBasePath:	"https://api-gateway.officinaedisanto.cloud/new-api/contents-service/api/v1/bo/categories",
		contentsServiceBasePath:	"https://api-gateway.officinaedisanto.cloud/new-api/contents-service/api/v1/bo/contents",
		macroareaServiceBasePath:	"https://api-gateway.officinaedisanto.cloud/new-api/contents-service/api/v1/bo/macroareas",
		sharingServiceBasePath:		"https://api-gateway.officinaedisanto.cloud/new-api/contents-service/api/v1/bo/{{entityType}}",
		domainEntityServiceBasePath:"https://api-gateway.officinaedisanto.cloud/new-api/contents-service/api/v1/bo/domainentities",

		appSettingsBasePath:		"https://api-gateway.officinaedisanto.cloud/new-api/core-service/api/v1/bo",
		baseUserServicesBasePath:	"https://api-gateway.officinaedisanto.cloud/new-api/core-service/api/v1/users",

		mediaContentsBasePath:		"https://api-gateway.officinaedisanto.cloud/new-api/files-service/api/v1/bo/files",
		objectSpaceManagementPath:	"https://api-gateway.officinaedisanto.cloud/new-api/osm-service/api/v1/bo"
		
	}
};
