import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from 'app/common/services/modal.service';
import { iconSave, iconCancel, iconPublish, iconAdd, iconTrash } from "app/common/utils/icons.utils"
import { CONTENTS_LIST_PATH } from 'app/common/utils/routing-paths.utils';
import { ContentType, LinkedContentsSet, LinkedContetsTagType } from 'app/models/contents.model';
import { BaseEditLinkedContents, LinkedContentsSetView} from 'app/pages/BaseEditLinkedContents.page';
import { ContentsProvider } from 'app/services/ContentsProvider.service';
import { forkJoin} from 'rxjs';


@Component({
	selector: 'edit-content-page',
	templateUrl: './edit-content.page.html'
})
export class EditContent extends BaseEditLinkedContents {

	constructor(private _contentssProvider: ContentsProvider, private _activatedRoute: ActivatedRoute, _router: Router, _modalService: ModalService) {
		super(_contentssProvider,_router,_modalService);	
	}

	public get showAdditionalContent(): boolean {
		return (!this.isNew);
	}

	public isNew: boolean = true;
	public actionDescription;
	public deleteLinkedContentsIcon = iconTrash;

	componentInitialized = false;
	generatedForm($event: FormGroup) {
		this.form = $event;
	}
	onValidityChange($event) {
		setTimeout(() => {
			this.valid = $event;
		})
	}

	valid: boolean = false;
	public form: FormGroup = null;
	public iconSave = iconSave;
	public iconCancel = iconCancel;
	public iconPublish = iconPublish;
	public iconAdd = iconAdd;
	public get published() {
		return this.valid && this.initialValue && this.initialValue.published;
	}
	public get actionTitle() {
		if (this.isNew) {
			switch (this.contentType) {
				case ContentType.descriptive:
					return "contents.newDescriptiveContentPageTitle";
				case ContentType.web:
					return "contents.newWebContentPageTitle";
				case ContentType.media:
					return "contents.newFileContentPageTitle";
				default:
					return "";
			}
		}
		else if (this.initialValue) return this.initialValue.name;
		return "";
	}
	contentType: ContentType;
	


	ngOnInit() {
		this.createActions();
        for (let prop in LinkedContetsTagType) {
			this._addMenuDef.push({
				label: LinkedContetsTagType[prop],
				value: LinkedContetsTagType[prop]
			});
        }

		this._activatedRoute.params.subscribe(params => {
			let id = params['id'];
			if (id != undefined || id != null) {
				this.entityId = id;
				forkJoin({
					content: this._contentssProvider.getContent(id),
					linkedContentsSets: this._contentssProvider.getLinkedContentsSets(id)
				}	
				)
				.subscribe((result) => {
					this.isNew = false;
					this.actionDescription = "contents.defaultUpdateContentPageTitle";
					this.initialValue = result.content;
					
					this.linkedContentsSet = result.linkedContentsSets.map((el: LinkedContentsSet) => {
						let result = new LinkedContentsSetView(this.entityId, this._contentssProvider, el.id);
						// result.id = el.id;
						result.tag = el.tag;
						return result;
					})
					this.contentType = result.content.type;
					// if (this.contentType == ContentType.descriptive)
					// 	this.showAdditionalContent = true;
					this.componentInitialized = true;
				})
			}
			else {
				this._activatedRoute.queryParams.subscribe(qParam => {					
					this.contentType = qParam.contentType;
					// if (this.contentType == ContentType.descriptive)
					// 	this.showAdditionalContent = true;
					this.isNew = true;
					this.componentInitialized = true;
				})
			}
		});
	}

	

	private goToPrevPage = () => {
		let counterPages = 0;
		this._activatedRoute.queryParams.subscribe(qParam => {
			counterPages++;
			if (qParam.returnUrl && counterPages == 1) {
				this._router.navigateByUrl(qParam.returnUrl);
			}
			else if (counterPages == 1) {
				this._router.navigate([CONTENTS_LIST_PATH, this.contentType])
			}
		})
	} 

	cancel() {
		this.goToPrevPage();
	}
	public save() {
		if (this.form.isValid()) {
			let value = this.form.value;
			this._contentssProvider.saveOrUpdateContent(value).subscribe((savedContent) => {
				this.initialValue = savedContent;
				this.entityId = savedContent.id;
				this.contentType = savedContent.type;
				this.isNew = false;
				this.actionDescription = "contents.defaultUpdateContentPageTitle";
				this.form.get("id").setValue(this.entityId);
			})
		}
	}

	public get saveButtonLabel() {
		if (this.isNew) return "Crea";
		return "common.saveButtonLabel";
	}

	public get cancelButtonLabel() {
		if (this.isNew) return "common.cancelButtonLabel";
		return "torna indietro";
	}


	public updatePublishState() {
		if (this.form.isValid()) {
			let value = this.form.value;
			value.published = !this.published;
			this._contentssProvider.saveOrUpdateContent(value).subscribe((category) => {
				this.goToPrevPage();
			})
		}
	}
}
