import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { SelectListitem } from "app/common/models/models";
import { ModalService } from "app/common/services/modal.service";
import { EnumUtils } from "app/common/utils/enum.utils";
import { SharingInfo, SharingPermission } from "app/models/sharing.model";
import { SharingService } from "app/services/sharing.service";
import { CommonValidators } from "../form/validators/common.validator";
import { ValidatorUtils } from "../form/validators/validator-utils";


@Component({
    selector: 'content-sharing-form',
    templateUrl: './content-sharing-form.component.html'
})
export class ContentSharingForm implements OnInit {
	form: FormGroup;
	possiblePermission: SelectListitem[] = []
	@Input() updateEnabled: boolean = false;
	@Input() entityId;
	@Input() entityType;
	@Output() sharingRequest: EventEmitter<SharingInfo[]> = new EventEmitter();
	@Output() onValidityChange: EventEmitter<boolean> = new EventEmitter();

	public constructor(private _fb: FormBuilder, private _modalService: ModalService, private _sharingService: SharingService, private _translateService: TranslateService) {

	}

	ngOnInit() {
		EnumUtils.toSelectListitems(SharingPermission, "SharingPermissionLongDescription", this._translateService).subscribe((result) => {
			this.possiblePermission = result;
		})
		let form = this._fb.group({
			emails: [[], CommonValidators.arrayMinItemsValidator(1)],
			permission: [null, Validators.required]
		});
		this.form = form;
		this.onValidityChange.emit(this.form.valid && this.updateEnabled);
		this.form.valueChanges.subscribe((value) => {
			this.onValidityChange.emit(this.form.valid && this.updateEnabled);
			if (this.form.valid) {
				let req: SharingInfo[]= [];
				this.form.value.emails.forEach(email => {
					req.push({
						email: email,
						permissions: [this.form.value.permission]
					})
				
				});
				this.sharingRequest.emit(req);
			}
		})
	}
}