import { Component, ChangeDetectionStrategy, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalOptions } from 'app/common/models/models';
import { DataTableUtils } from 'app/common/utils/data-table.utils';
import { iconAdd, iconCancel, iconEdit, iconSave, iconShare, iconTrash } from 'app/common/utils/icons.utils';
import { BaseContent, ContentsSearchCriteria } from 'app/models/contents.model';
import { SharingPermission } from 'app/models/sharing.model';
import { ContentsProvider } from 'app/services/ContentsProvider.service';
import { FileProvider, MediaFileDescription } from 'app/services/FileProvider.service';
import { SecurityService } from 'app/services/security.service';
import { environment } from 'environments/environment';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { BaseComponent } from '../base.component';
import { BaseDataTablePage } from '../pages/base-data-table.page';
import { BaseListTablePage } from '../pages/base-list-table.page';
import { DataTableAction, DataTableColumn, IDataTableManager, ListTableManager, RemoteDataTableManager } from '../table/types';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export class SelectContentData {
	title: string;
	message: string;
	ids: any[];
}
@Component({
	selector: 'select-content-list-component',
	templateUrl: './select-content-list.component.html',
	changeDetection: ChangeDetectionStrategy.Default
})
export class SelectContentListModalComponent implements OnInit {
	public columns: DataTableColumn[] = [];
	public data: SelectContentData;
	public loading: boolean = true;
	public selectedAll = false;
	iconCancel = iconCancel;
	iconSave = iconSave;
	items = [];
	ngOnInit() {
		this.loading = true;
		this.createColumms();
		
		this._contentssProvider.findAll().subscribe((result) => {
			result.forEach((element) => {
				if (this.data && this.data.ids && this.data.ids.indexOf(element.id) < 0)
					this.items.push(element);
			})
			this.loading = false;
		},
		(err) => {
			this.loading = false;
		}
		)
	}
    constructor(public activeModal: NgbActiveModal, _activatedRoute: ActivatedRoute, private _contentssProvider: ContentsProvider) {
		
    }

    private createColumms = (): void => {
        let nameColumn = DataTableUtils.createStringColumn("name", "contents.contentNameColumnLabel", true);
        this.columns.push(nameColumn);
		let typeColumn = DataTableUtils.createEnumColumn("type", "contents.contentTypeColumnLabel", "ContentType", false);
        this.columns.push(typeColumn);
    }

	public close = (): void => {
		this.activeModal.close([]);
    }
    public ok = (): void => {
        let result = [];
		this.items.forEach(element => {
			if (element.selected)
				result.push(element.id);
		});
		console.log(result);
		
        this.activeModal.close(result);
    }

	selected=false;
	
	change(row) {
		let change =  false
		
		this.items.forEach(element => {
			change ||= element.selected;
		});
		this.selected = change;
	}

	selectAll() {
		this.items.forEach(element => {
			element.selected = this.selectedAll;
		});
		this.selected = this.selectedAll;
	}
	
}


// class Filters {
//     public published: string = "";
// 	public contentTypes: string = "";
// }