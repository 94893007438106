<div class="card">
	<div class="card-header">
		<div class="row">
			<div class="col-6" *ngIf="linkedLocalizableEntity">
				{{'Caratteristiche geometriche e spaziali' | translate}}
			</div>
			<div class="col-6" *ngIf="!linkedLocalizableEntity">
				<button type="button" (click)="linkLocalizableEntity()" [enabled-by-permission]="'domainentities.write'" class="btn btn-primary">
					<i class="{{iconPublish}}"></i> {{ 'definisci informazioni spaziali' | translate}}
				</button>
			</div>
		</div>
	</div>
	<div class="card-content" >
		<div class="card-body">
			<div class="row" *ngIf="linkedLocalizableEntity">
				<div class="col-12">
					<div class="bordered">
						<ul ngbNav #navContainer="ngbNav" [(activeId)]="activeTabId" class="nav-tabs">
							<li *ngFor="let tab of tabLocationReferences" [ngbNavItem]="tab.id">
								<a ngbNavLink>
									<i [ngClass]="tab.icon"></i> {{tab.title | translate}}
								</a>
								<ng-template ngbNavContent >
									<div class="linked-contents-container">
										<div class="element-preview bordered" *ngFor="let reference of tab.references; let i = index">
											<map-preview
												*ngIf="tab.referenceType == 'geo'"
												(onDeleteRequest)="removeReference($event,i)"
												(onSaveRequest)="updateReference($event,i)"							
												[reference]="reference"
												>
											</map-preview>
											<div *ngIf="tab.referenceType == 'beacon'">
												<beacon-preview
													(onDeleteRequest)="removeReference($event,i)"
													(onSaveRequest)="updateReference($event,i)"											
													[reference]="reference"
												></beacon-preview>
											</div>
											<div *ngIf="tab.referenceType == 'qrcode'">
												<qrcode-preview	
													(onDeleteRequest)="removeReference($event,i)"
													(onSaveRequest)="updateReference($event,i)"											
													[reference]="reference"
												></qrcode-preview>
											</div>
											
										</div>
										<div *ngIf="tab.shouldAddReference || tab.references.length==0" class="element-preview linked-contents-container">
											<button class="btn-clear font-large-2 text-secondary cursor-pointer" style="margin: inherit;" (click)="addNewElement()">
												<i class="far fa-plus-square"></i>
											</button>
										</div>
									</div>
								</ng-template>
							</li>
							
						</ul>
						<div [ngbNavOutlet]="navContainer" class="mt-2"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="card" *ngIf="linkedLocalizableEntity && isSpace">
	<div class="card-header">
		<div class="row">
			<div class="col-6">
				{{'Composizione e contenuto del luogo' | translate}}
			</div>
		</div>
	</div>
	<div class="card-content">
		<div class="card-body">
			<div class="row">
				<div class="col-12">
					<div class="bordered">
						<ul ngbNav #navContainer="ngbNav" [(activeId)]="activeTabId" class="nav-tabs">
							
							<li *ngFor="let tab of tabSpaces" [ngbNavItem]="tab.id">
								<a ngbNavLink>
									<i [ngClass]="tab.icon"></i> {{tab.title | translate}}
								</a>
								<ng-template ngbNavContent >
									<div class="linked-contents-container">
										<div class="element-preview bordered" *ngFor="let reference of tab.references | async ">
											<domain-entity-preview
												[entityId]="reference.realEntityId"
												[localizableEntity]="reference"
												[actions]="tab.actions"
											>

											</domain-entity-preview>
											
										</div>
										<div *ngIf="tab.shouldAddReference" class="element-preview linked-contents-container">
											<button class="btn-clear font-large-2 text-secondary cursor-pointer" style="margin: inherit;" (click)="addNewElement()">
												<i class="far fa-plus-square"></i>
											</button>
										</div>
									</div>
								</ng-template>
							</li>
						</ul>
						<div [ngbNavOutlet]="navContainer" class="mt-2"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>