<form [formGroup]="form" *ngIf="form">
	<div class="row">
		<div class="col-12">
			<localized-input-container
				[fields]="descriptiveContentFieldsDescription"
				[formArray]="form.controls.localizedParagraphs"
				[label]="'contents.editDescriptiveContentParagraphLabel' | translate"></localized-input-container>

		</div>
	</div>
</form>