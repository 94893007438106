<form [formGroup]="form" *ngIf="form">
	<div class="row">
		<div class="col-12">
			<localized-input-container
				[fields]="webContentFieldsDescription"
				[formArray]="form.controls.localizedNames"
				[label]="'contents.editWebContentNamesLabel' | translate"></localized-input-container>

		</div>
	</div>
	<div class="row" >
		<div class="col-12">
			<input-string formControlName="webpage" label="Link alla pagina web"></input-string>
		</div>
	</div>
</form>