
export const iconTrash = "ft-trash-2";
export const iconEdit = "ft-edit";
export const iconSave = "ft-check";
export const iconRefresh = "ft-repeat";
export const iconCancel = "ft-x";
export const iconAdd = "ft-plus";
export const iconPublish = "icon-cloud-upload";
export const iconShare = "icon-share";
export const iconUserAdd = "fas fa-user-plus";
export const iconUserRemove = "fas fa-user-times";
export const iconMoreUsers = "fas fa-user-friends";
export const iconElementView = "fas fa-eye";
export const iconPropertiesEdit = "fas fa-wrench";
export const iconMap = "fas fa-map-marked-alt";
export const iconQRCode = "fas fa-qrcode";
export const iconBeacon = "fas fa-tablets";
export const iconSpaces = "fas fa-landmark";
export const iconMonument = "fas fa-monument";
export const iconPolygon = "fas fa-draw-polygon"
export const iconCenter = "fas fa-map-marker"
