import { BaseLocalizedEntity, LocalizedName } from "app/common/models/localization";
import { SearchCriteria } from "app/common/models/models";
import { DomainEntityType } from "./domainentities.model";

export class Category extends BaseLocalizedEntity{
	public id: any;
	public localizedNames: LocalizedName[] = [];
	public parent: Category;
	public name: string;
	public entityTypes: DomainEntityType[] = [];
	constructor() {
		super();
		this.mappingLocalizedField.push({fieldSrc:"localizedNames", fieldDest: "name", localizedFieldName: "text"});
	}
}

export class BaseMacroarea extends BaseLocalizedEntity {
	public id: any;
	public localizedNames: LocalizedName[] = [];
	public name: string;
	public imageId?: any;
	constructor() {
		super();
		this.mappingLocalizedField.push({fieldSrc:"localizedNames", fieldDest: "name", localizedFieldName: "text"});
	}
}

export class Macroarea extends BaseMacroarea{
	public categories: Category[] = [];
}
export class MacroareaView extends BaseMacroarea{
	public categories: string[] = [];
}

export class CategoriesSearchCriteria extends SearchCriteria {
}

export class MacroareaSearchCriteria extends SearchCriteria {
	text: string = null;
	categoryIds: any[] = [];
}