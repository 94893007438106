<base-page [title]="'Modali ed alerts' | translate">
    <ng-container page-body>
        <div class="card">
            <div class="card-header">
                Confirm dialog
            </div>
            <div class="card-content">
                <div class="card-body">
                    <div class="row">
                        <div class="col">
                            <button type="button" class="btn btn-primary" (click)="openConfirm('sm')">Small</button>
                        </div>
                        <div class="col">
                            <button type="button" class="btn btn-primary" (click)="openConfirm()">Medium
                                (Default)</button>
                        </div>
                        <div class="col">
                            <button type="button" class="btn btn-primary" (click)="openConfirm('lg')">Large</button>
                        </div>
                        <div class="col">
                            <button type="button" class="btn btn-primary" (click)="openConfirm('xl')">Extra
                                large</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header">
                Error dialog
            </div>
            <div class="card-content">
                <div class="card-body">
                    <div class="row">
                        <div class="col">
                            <button type="button" class="btn btn-primary" (click)="openErrors()">Open</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header">
                Toast
            </div>
            <div class="card-content">
                <div class="card-body">
                    <div class="row">
                        <div class="col">
                            <button type="button" class="btn btn-success" (click)="openSuccessToast()">Success</button>
                        </div>
                        <div class="col">
                            <button type="button" class="btn btn-info" (click)="openInfoToast()">Info</button>
                        </div>
                        <div class="col">
                            <button type="button" class="btn btn-danger" (click)="openDangerToast()">Danger</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="card">
            <div class="card-header">
                Notifiche
            </div>
            <div class="card-content">
                <div class="card-body">
                    <div class="row">
                        <div class="col">
                            <button type="button" class="btn btn-success" (click)="sendMessage1()">Messaggio 1</button>
                        </div>
                        <div class="col">
                            <button type="button" class="btn btn-danger" (click)="sendMessage2()">Messaggio 2</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="card">
            <div class="card-header">
                Spinner
            </div>
            <div class="card-content">
                <div class="card-body">
                    <div class="row">
                        <div class="col">
                            <button type="button" class="btn btn-success" (click)="showSpinner()">Spinner</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</base-page>