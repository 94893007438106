<form [formGroup]="form" *ngIf="form">
	<div class="row">
		<div class="col-8">
			<localized-input-container
				[fields]="mediaContentFieldsDescription"
				[formArray]="form.controls.mediaContentLocalizations"
				[label]="'contents.editMediaContentParagraphLabel' | translate"></localized-input-container>
		</div>
		<div class="col-4">
			<div class="row">
				<div class="col-12">
					<select-box
						formControlName="mediaType"
						label="{{'contents.editMediaInputFieldMediaTypeLabel' | translate}}"
						[items]="mediaContentTypesDef">
					</select-box>
				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<input-slide formControlName="useSameMediaId" label="Usa lo stesso file per lingue differenti" [horizontal]="true">
					</input-slide>
				</div>
			</div>
			<div class="row" *ngIf="form.value.useSameMediaId">
				<div class="col-12">
					<input-file formControlName="mediaId" [possibileMediaTypes]="possibileMediaTypes" label="File associato"></input-file>
				</div>
			</div>
		</div>
	</div>
</form>
