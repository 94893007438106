import { Injectable } from "@angular/core";
import { SearchResult } from "app/common/models/models";
import { HttpService } from "./http.service";
import { Observable, of } from 'rxjs';
import { BaseContent, ContentsSearchCriteria, DescriptiveContent, MediaContent, ContentType, WebContent, LinkedContents, LinkedContentsSet, LinkedContent } from "app/models/contents.model";
import { map } from "rxjs/operators";
import { BaseProvider } from "app/common/services/common-provider.service";
import { CultureInfo, CultureService } from "app/common/services/culture.service";
import { environment } from "environments/environment";
import { LinkedContentProvider } from "./LinkedContentsBaseProvider";


@Injectable()
export class ContentsProvider extends BaseProvider implements LinkedContentProvider {
	constructor(private _httpService: HttpService, private _cultureService: CultureService) {
		super();
	}
	
	private _initContent(src: BaseContent): BaseContent {
		let toReturn: BaseContent;
		if (src.type == ContentType.descriptive)
			toReturn = new DescriptiveContent();
		else if (src.type == ContentType.media)
			toReturn = new MediaContent();
		else if (src.type == ContentType.web)
			toReturn = new WebContent();
		if (toReturn) {
			Object.assign(toReturn,src);
			toReturn.defineLanguage(this._cultureService.getCurrentCulture());
			if (toReturn.type == ContentType.media) {
				let lastMediaId = null;
				(<MediaContent>toReturn).mediaContentLocalizations.forEach((content) => {
					console.log(lastMediaId + " " + content.mediaId);
					
					if (lastMediaId == null) {
						lastMediaId = content.mediaId;
						(<MediaContent>toReturn).useSameMediaId = true;
					}
					else {
						(<MediaContent>toReturn).useSameMediaId = (<MediaContent>toReturn).useSameMediaId && (lastMediaId == content.mediaId);
					}
				})
				if ((<MediaContent>toReturn).useSameMediaId) {
					(<MediaContent>toReturn).mediaId = lastMediaId;
				}
			}
			return toReturn;
		}
		return null;
	}

	public search = (searchCriteria: ContentsSearchCriteria): Observable<SearchResult<BaseContent>> => {
		let url = environment.services.contentsServiceBasePath + "?"+this.serializePagination(searchCriteria);
		
		console.log("search searchCriteria", searchCriteria);
		if (searchCriteria.contentTypes)
			url += "&contentTypes="+searchCriteria.contentTypes;
		
		return this._httpService.get<SearchResult<BaseContent>>(url).pipe(
			map((result: SearchResult<BaseContent>) => {
				let cultureInfo: CultureInfo = this._cultureService.getCurrentCulture();
				let toReturn = new SearchResult<BaseContent>();
				toReturn.totalCount = result.totalCount;
				toReturn.items = [];
				result.items.forEach((item) => {
					let bc: BaseContent = this._initContent(item);
					if (bc) {
						toReturn.items.push(bc);
					}
				})
				return toReturn;
			})
		);
    }

	public findAll = (test?: string): Observable<BaseContent[]> => {
		let url = environment.services.contentsServiceBasePath + "/autocomplete"
		
		return this._httpService.get<BaseContent[]>(url).pipe(
			map((result: BaseContent[]) => {
				let cultureInfo: CultureInfo = this._cultureService.getCurrentCulture();
				let toReturn: BaseContent[] = [];
				result.forEach((item) => {
					let bc: BaseContent = this._initContent(item);
					if (bc) {
						toReturn.push(bc);
					}
				})
				return toReturn;
			})
		);
    }

	public getContent = (id: any): Observable<BaseContent> => {
		let url = environment.services.contentsServiceBasePath+"/"+id;
		return this._httpService.get<BaseContent>(url).pipe(
			map((result: BaseContent) => {
				return this._initContent(result);
			}
		))
	}

	public saveOrUpdateContent = (content: BaseContent): Observable<BaseContent> => {
		let observable;
		if (content.id)
			observable = this._httpService.put<BaseContent>(environment.services.contentsServiceBasePath+"/"+content.id, content);
		else observable = this._httpService.post<BaseContent>(environment.services.contentsServiceBasePath, content);

		return observable.pipe(
			map((result: BaseContent) => {
				return this._initContent(result);
			}
		))

	}
	public deleteContent = (content: BaseContent): Observable<BaseContent> => {
		return this._httpService.delete<BaseContent>(environment.services.contentsServiceBasePath+"/"+content.id);
	}



	public getLinkedContentsSets = (contentId): Observable<LinkedContentsSet[]> => {
		let url = environment.services.contentsServiceBasePath+"/"+contentId+"/linkedcontentssets";
		return this._httpService.get<LinkedContentsSet[]>(url);
	}
	public saveLinkedContentsSets = (contentId, set: LinkedContentsSet): Observable<LinkedContentsSet> => {
		let toSave: LinkedContentsSet = {
			id: set.id,
			tag: set.tag
		}
		if (set.id) return this._updateLinkedContentsSets(contentId, toSave);
		return this._createLinkedContentsSets(contentId, toSave);	
	}
	private _createLinkedContentsSets = (contentId, set: LinkedContentsSet): Observable<LinkedContentsSet> => {
		let url = environment.services.contentsServiceBasePath+"/"+contentId+"/linkedcontentssets";
		return this._httpService.post<LinkedContentsSet>(url, set);
	}
	private _updateLinkedContentsSets = (contentId, set: LinkedContentsSet): Observable<LinkedContentsSet> => {
		let url = environment.services.contentsServiceBasePath+"/"+contentId+"/linkedcontentssets/"+set.id;
		return this._httpService.put<LinkedContentsSet>(url, set);
	}
	public deleteLinkedContentsSets = (contentId, set: LinkedContentsSet): Observable<LinkedContentsSet> => {
		let url = environment.services.contentsServiceBasePath+"/"+contentId+"/linkedcontentssets/"+set.id;
		return this._httpService.delete<LinkedContentsSet>(url);
	}


	public getLinkedContents = (contentId, setId): Observable<LinkedContent[]> => {
		let url = environment.services.contentsServiceBasePath+"/"+contentId+"/linkedcontentssets/"+setId+"/linkedcontents";
		return this._httpService.get<LinkedContent[]>(url);
	}
	public saveLinkedContent = (contentId, setId, linkedContent: LinkedContent): Observable<LinkedContent> => {
		console.log("saveLinkedContent", contentId, setId, linkedContent);
		let toSave: LinkedContent = {
			content: linkedContent.content,
			id: linkedContent.id,
			properties: linkedContent.properties
		}
		if (linkedContent.id) return this._updateLinkedContent(contentId, setId, toSave);
		return this._createLinkedContent(contentId, setId, toSave);	
	}
	private _createLinkedContent = (contentId, setId, linkedContent: LinkedContent): Observable<LinkedContent> => {
		let url = environment.services.contentsServiceBasePath+"/"+contentId+"/linkedcontentssets/"+setId+"/linkedcontents";
		return this._httpService.post<LinkedContent>(url, linkedContent);
	}
	private _updateLinkedContent = (contentId, setId, linkedContent: LinkedContent): Observable<LinkedContent> => {
		let url = environment.services.contentsServiceBasePath+"/"+contentId+"/linkedcontentssets/"+setId+"/linkedcontents/"+linkedContent.id;
		return this._httpService.put<LinkedContent>(url, linkedContent);
	}
	public deleteLinkedContent = (contentId, setId, linkedContent: LinkedContent): Observable<any> => {
		let url = environment.services.contentsServiceBasePath+"/"+contentId+"/linkedcontentssets/"+setId+"/linkedcontents/"+linkedContent.id;
		return this._httpService.delete<any>(url);
	}
}