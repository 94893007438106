import { Injectable } from "@angular/core";
import { SearchResult } from "app/common/models/models";
import { BaseProvider } from "app/common/services/common-provider.service";
import { CultureService } from "app/common/services/culture.service";
import { Permission, PermissionSearchCriteria, Role, RoleSearchCriteria } from "app/models/roles";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpService } from "./http.service";

@Injectable()
export class RolesAndPermissionsProvider extends BaseProvider {

	constructor(private _httpService: HttpService, private _cultureService: CultureService) {
		super();
	}
	
	private _initPermission(src: Permission): Permission {
		let toReturn =  new Permission();
		Object.assign(toReturn,src);
		toReturn.defineLanguage(this._cultureService.getCurrentCulture());
		return toReturn;
	}

	public getPermission = (id: any): Observable<Permission> => {
		let url = environment.services.appSettingsBasePath+"/permissions/"+id;
		return this._httpService.get<Permission>(url).pipe(
			map((result: Permission) => {
				return this._initPermission(result);
			}
		))
	}

	public searchPermissions = (searchCriteria: PermissionSearchCriteria): Observable<SearchResult<Permission>> => {
		let url = environment.services.appSettingsBasePath+"/permissions?"+this.serializePagination(searchCriteria);
		return this._httpService.get<SearchResult<Permission>>(url).pipe(
			map((result: SearchResult<Permission>) => {
				let toReturn = new SearchResult<Permission>();
				toReturn.totalCount = result.totalCount;
				toReturn.items = [];
				result.items.forEach((item) => {
					toReturn.items.push(this._initPermission(item));
				})
				return toReturn;
			})
		);
	}

	public getAllPermissionsAutocomplete = (text?: string): Observable<Permission[]> => {
		let url = environment.services.appSettingsBasePath+"/permissions/autocomplete" +((text)?"?text="+text:"");
		return this._httpService.get<Permission[]>(url).pipe(
			map((result: Permission[]) => {
				let toReturn: Permission[] = [];
				result.forEach((item) => {
					if (item.deleted == false)
						toReturn.push(this._initPermission(item));
				})
				return toReturn;
			})
		);
	}

	public getAllPermissionsId = (): Observable<string[]> => {
		let url = environment.services.baseUserServicesBasePath+"/permissions";
		return this._httpService.get<string[]>(url)
	}

	public saveOrUpdatePermission = (entity: Permission): Observable<Permission> => {
		if (entity.id)
			return this._httpService.put<Permission>(environment.services.appSettingsBasePath+"/permissions/"+entity.id, entity);
		return this._httpService.post<Permission>(environment.services.appSettingsBasePath+"/permissions", entity);

	}
	public deletePermission = (entity: Permission): Observable<Permission> => {
		return this._httpService.delete<Permission>(environment.services.appSettingsBasePath+"/permissions/"+entity.id);
	}



	private _initRole(src: Role): Role {
		let toReturn =  new Role();
		let permissions: Permission[] = [];
		let culture = this._cultureService.getCurrentCulture();
		if (src.permissions) {
			src.permissions.forEach((permission) => {
				let p: Permission = this._initPermission(permission);
				p.defineLanguage(culture)
				permissions.push(p)
			})
		}
		
		Object.assign(toReturn,src);
		toReturn.permissions = permissions;
		toReturn.defineLanguage(culture);
		return toReturn;
	}

	public getRole = (id: any): Observable<Role> => {
		let url = environment.services.appSettingsBasePath+"/roles/"+id;
		return this._httpService.get<Role>(url).pipe(
			map((result: Role) => {
				return this._initRole(result);
			}
		))
	}

	public searchRoles = (searchCriteria: RoleSearchCriteria): Observable<SearchResult<Role>> => {
		let url = environment.services.appSettingsBasePath+"/roles?"+this.serializePagination(searchCriteria);
		return this._httpService.get<SearchResult<Role>>(url).pipe(
			map((result: SearchResult<Role>) => {
				let toReturn = new SearchResult<Role>();
				toReturn.totalCount = result.totalCount;
				toReturn.items = [];
				result.items.forEach((item) => {
					toReturn.items.push(this._initRole(item));
				})
				return toReturn;
			})
		);
	}

	public getAllRolesAutocomplete = (text?: string): Observable<Role[]> => {
		let url = environment.services.appSettingsBasePath+"/roles/autocomplete" +((text)?"?text="+text:"");
		return this._httpService.get<Role[]>(url).pipe(
			map((result: Role[]) => {
				let toReturn: Role[] = [];
				result.forEach((item) => {
					toReturn.push(this._initRole(item));
				})
				return toReturn;
			})
		);
	}

	public saveOrUpdateRole = (entity: Role): Observable<Role> => {
		if (entity.id)
			return this._httpService.put<Role>(environment.services.appSettingsBasePath+"/roles/"+entity.id, entity);
		return this._httpService.post<Role>(environment.services.appSettingsBasePath+"/roles", entity);

	}
	public deleteRole = (entity: Role): Observable<Role> => {
		return this._httpService.delete<Role>(environment.services.appSettingsBasePath+"/roles/"+entity.id);
	}
}