import { BaseLocalizedEntity } from "app/common/models/localization"
import { Category } from "./categories.model"
import { SharingPermission } from "./sharing.model"
import { LocalizedName } from "app/common/models/localization";
import { LinkedContents } from "./contents.model";
import { SearchCriteria } from "app/common/models/models";

export enum DomainEntityAttributeType {
	WEBSITE = "WEBSITE",
	PHONE = "PHONE",
	SCHEDULE = "SCHEDULE"
}
export class DomainEntityBaseAttribute extends BaseLocalizedEntity{
	constructor(public type: "localized"| "simple") {
		super();
	}
	id: any;
	attributeType: DomainEntityAttributeType;
	value;
}

export class LocalizedAttribute extends DomainEntityBaseAttribute {
	constructor() {
		super("localized");
		this.mappingLocalizedField.push({fieldSrc:"localizedValues", fieldDest: "name", localizedFieldName: "text"});
		
	}
	localizedValues: LocalizedName[] = []
}
export class SimpleAttribute extends DomainEntityBaseAttribute {
	constructor() {
		super("simple");		
	}
}

export enum DomainEntityType {
	CULTURAL_HERITAGE = "CULTURAL_HERITAGE",
	PLACE = "PLACE"
}
export class DomainEntity extends BaseLocalizedEntity {
	constructor() {
		super();
		this.mappingLocalizedField.push({fieldSrc:"localizedNames", fieldDest: "name", localizedFieldName: "text"});
	}
	id: any;
	entityType: DomainEntityType; 
	createdBy: string;
	createdDate: string;
	
	lastModifiedDate: string;
	lastModifiedBy: string;
	published: boolean = false;

	
	localizedNames: LocalizedName[] = [];
	categories: Category[] = [];
	linkedContents: LinkedContents[] = [];
	attributes: DomainEntityBaseAttribute[] = [];
	name: string;
	permissions: SharingPermission[] = [];
}

export class DomainEntitySearchCriteria extends SearchCriteria {
	public published = "";
	public entityType = "";
}