import { Injectable } from "@angular/core";
import { SharingInfo, SharingResponse } from "app/models/sharing.model";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { HttpService } from "./http.service";


@Injectable()
export class SharingService {

	constructor(private _httpService: HttpService) {
	}

	public getSharingInfo = (entityId: any, entityType: "contents" | "domainentities"): Observable<SharingInfo[]> => {
		let url = `${environment.services.sharingServiceBasePath.replaceAll("{{entityType}}", entityType)}/${entityId}/share/info`;
		return this._httpService.get<SharingInfo[]>(url);
	}

	public updateSharingStatus = (entityId: any, entityType: "contents" | "domainentities", sharingInfo: SharingInfo[]): Observable<SharingResponse> => {
		let url = `${environment.services.sharingServiceBasePath.replaceAll("{{entityType}}", entityType)}/${entityId}/share`;
		return this._httpService.put<SharingResponse>(url, sharingInfo);
	}
}