import { DomainEntityType } from "app/models/domainentities.model";


export const CULTURAL_HERITAGES_LIST_PATH = "entities/"+DomainEntityType.CULTURAL_HERITAGE
export const PLACE_LIST_PATH = "entities/"+DomainEntityType.PLACE
export const EDIT_DOMAIN_ENTITIES_PATH = "entities";

export const CATEGORIES_LIST_PATH = "categories/list";
export const EDIT_CATEGORY_PATH = "categories/edit";
export const MACROAREA_LIST_PATH = "macroareas/list";
export const EDIT_MACROAREA_PATH = "macroareas/edit";

export const CONTENTS_LIST_PATH = "contents/list";
export const EDIT_CONTENTS_PATH = "contents/edit";